import React from "react";
import "../css/dashboard.css";
import CasadaSwitch from "../component/Misc/CasadaSwitch";

export default function RoomLayoutContent() {
  return (
    <div className="right-container">
      <CasadaSwitch title={"Room Layout"} />{" "}
    </div>
  );
}
