import React from "react";
import AddIcon from "@mui/icons-material/Add"; // Import de l'icône Add

function AddButtonRoundSmallMobileV3(props) {
  return (
    <div
      className="add-round-button-small-mobile"
      onClick={props.handleOpen}
      title={props.title}
    >
      <AddIcon /> {/* Remplacement du caractère "+" par l'icône Add */}
    </div>
  );
}

export default AddButtonRoundSmallMobileV3;
