import { Avatar } from "@mui/material";
import React from "react";

export default function SmallClientChip(props) {
  return (
    <div className="small-client-chip-container">
      <Avatar
        style={{
          width: "40px",
          height: "40px",
        }}
        src={props.clientPic}
      />
      <div className="small-client-chip-name">
        {props.firstName} {props.lastName}
      </div>
    </div>
  );
}
