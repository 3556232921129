import React, { useContext } from "react";
import "../css/dashboard.css";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import SupportDashboardContent from "./SupportDashboardContent";
import NavbarV2 from "../component/NavbarV2";
import { LanguageContext } from "../context/LanguageContext";
import ProfileWidgetMobileNoRole from "../component/ProfileWidgetMobileNoRole";
import SupportDashboardContentMobileV2 from "./SupportDashboardContentMobileV2";

export default function SupportDashboard() {
  const { translate } = useContext(LanguageContext);
  const navigate = useNavigate();

  const handleMenuOpen = () => {
    navigate("/menumobile");
  };

  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });

  return (
    <div className="main-container">
      {isMobile ? (
        <>
          <div
            className="menu-button-container-mobile"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingRight: "0%",
            }}
          >
            <div
              style={{
                width: "30%",
                paddingLeft: "5%",
              }}
            >
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
                className="menu-button"
              >
                <MenuIcon />
              </IconButton>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "40%",
                alignItems: "center",
                alignContent: "center",
                alignSelf: "center",
                fontWeight: "700",
                textAlign: "center",
              }}
            >
              {translate("Metrics")}
            </div>
            <div
              style={{
                width: "30%",
                paddingTop: "0px",
                paddingBottom: "0px",
                display: "flex",
                justifyContent: "end",
                alignContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              ></div>
              <ProfileWidgetMobileNoRole />
            </div>
          </div>

          <SupportDashboardContentMobileV2 />
        </>
      ) : (
        <>
          <NavbarV2 linkActive={13} />
          <SupportDashboardContent />
        </>
      )}
    </div>
  );
}
