import React, { useState } from "react";
import { Button } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";

import TopTitleFormComponent from "./TopTitleFormComponent";
import {
  Timestamp,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { showCustomToast } from "../Misc/CustomToast";
import DropDownComponentNoTitle from "../DropDownComponentNoTitle";
import InputComponentNoTitle from "../InputComponentNoTitle";
import PhoneNumberInputNoTitle from "../PhoneNumberInputNoTitle";
import DropDownComponentSortedNoTitle from "../DropDownComponentSortedNoTitle";
import DatePickerComponentNoTitle from "../DatePickerComponentNoTitle";
import dayjs from "dayjs";

function AddClient(props) {
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickname, setNickName] = useState("");
  const [clientStatus, setClientStatus] = useState("Clinical Assessment");
  const [gender] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(0);
  const [country, setCountry] = useState("");
  const [primaryAddiction, setPrimaryAddiction] = useState("");
  const [durationOfStay, setDurationOfStay] = useState("");
  const [eta, setEta] = useState("");
  const [clientSource, setClientSource] = useState("");

  const handlEtaChange = (event) => {
    setEta(event);
  };

  const handleClientSource = (event) => {
    setClientSource(event);
  };

  const handleNicknameChange = (event) => {
    setNickName(event);
  };

  const handleDurationOfStayChange = (value) => {
    setDurationOfStay(value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event);
  };

  const handleLastNameChange = (event) => {
    setLastName(event);
  };

  const handlePersonalEmailChange = (event) => {
    setPersonalEmail(event);
  };

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
  };

  const handleCountryChange = (value) => {
    setCountry(value);
  };

  const handleClientStatus = (value) => {
    setClientStatus(value);
  };

  const handlePrimaryAddictionChange = (event) => {
    setPrimaryAddiction(event);
  };

  const handleSave = async () => {
    // showCustomToast(
    //   "This is a test version. No client can be added.",
    //   "info",
    //   "blue"
    // );
    if (
      clientStatus &&
      firstName &&
      lastName &&
      personalEmail &&
      phoneNumber &&
      country &&
      primaryAddiction &&
      durationOfStay &&
      clientSource &&
      eta
    ) {
      setIsLoading(true);
      try {
        // Calcul de projectedResidentialDischargeDate basé sur eta et durationOfStay
        const etaDate = eta.toDate();
        const durationDays = parseInt(durationOfStay.match(/\d+/)[0]); // Extrait le nombre de jours depuis la chaîne
        console.log("Duration Days :", durationDays);
        const projectedDischargeDate = dayjs(etaDate)
          .add(durationDays, "day")
          .toDate();

        // Ajout de 60 jours à projectedResidentialDischargeDate pour obtenir projectedProgramCompletionDate
        const projectedProgramCompletionDate = dayjs(projectedDischargeDate)
          .add(60, "day")
          .toDate();

        const newClientRef = await addDoc(collection(db, "clients"), {
          creatorName: currentUser.displayName,
          dateOfCreation: serverTimestamp(),
          firstName,
          lastName,
          gender,
          personalEmail,
          phoneNumber,
          country,
          clientStatus,
          primaryAddiction,
          durationOfStay,
          eta,
          nickname,
          clientSource,
          staffId: currentUser.uid,
          staffEmail: currentUser.email,
          staffName: currentUser.displayName,
          projectedResidentialDischargeDate: Timestamp.fromDate(
            projectedDischargeDate
          ), // Convertit la Date en Timestamp Firestore
          projectedProgramCompletionDate: Timestamp.fromDate(
            projectedProgramCompletionDate
          ),
        });

        console.log(`Client added with ID: ${newClientRef.id}`);
        showCustomToast("Client successfully added!", "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.error("Error adding client: ", error);
        setIsLoading(false);
        showCustomToast(`Error adding client: ${error}`, "error");
      }
    } else {
      showCustomToast(
        "Some mandatory fields are empty. Please check.",
        "error"
      );
    }
  };

  return (
    <>
      <div style={{ width: "auto", height: "100%" }}>
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title="ADD CLIENT"
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "3px",
            height: "80%",
            overflowY: "scroll",
            // overflowX: "hidden",
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingBottom: "20px",
          }}
        >
          <div className="alert-message3">Fields with * are mandatory</div>
          {/* <div className="tab-form-container-addclient">
          <div style={{ flexDirection: "column", width: "100%" }}> */}
          <div className="addclient-items-wrap">
            <div className="addclient-item-wrap-left">
              Status
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </div>
            <div className="addclient-item-wrap-right">
              <DropDownComponentSortedNoTitle
                api="clientStatus"
                // title="Client status"
                isRequired={true}
                value={clientStatus}
                onChange={handleClientStatus}
              />
            </div>
          </div>

          <div className="addclient-items-wrap">
            <div className="addclient-item-wrap-left">
              First Name
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </div>

            <div className="addclient-item-wrap-right">
              <InputComponentNoTitle
                width="300"
                isRequired={true}
                value={firstName}
                onChange={handleFirstNameChange}
              />
            </div>
          </div>

          <div className="addclient-items-wrap">
            <div className="addclient-item-wrap-left">
              Last Name
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </div>

            <div className="addclient-item-wrap-right">
              <InputComponentNoTitle
                width="300"
                isRequired={true}
                value={lastName}
                onChange={handleLastNameChange}
              />
            </div>
          </div>

          <div className="addclient-items-wrap">
            <div className="addclient-item-wrap-left">
              Preferred Name
              {/* <span style={{ color: "red", paddingLeft: "5px" }}>*</span> */}
            </div>

            <div className="addclient-item-wrap-right">
              <InputComponentNoTitle
                width="300"
                // isRequired={true}
                value={nickname}
                onChange={handleNicknameChange}
              />
            </div>
          </div>

          <div className="addclient-items-wrap">
            <div className="addclient-item-wrap-left">
              Personnal Email
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </div>

            <div className="addclient-item-wrap-right">
              <InputComponentNoTitle
                width="300"
                // isRequired={true}
                value={personalEmail}
                onChange={handlePersonalEmailChange}
              />
            </div>
          </div>

          <div className="addclient-items-wrap">
            <div className="addclient-item-wrap-left">
              Personnal Phone Number
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </div>

            <div className="addclient-item-wrap-right">
              <PhoneNumberInputNoTitle
                // isRequired={true}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
              />
            </div>
          </div>

          <div className="addclient-items-wrap">
            <div className="addclient-item-wrap-left">
              Country
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </div>

            <div className="addclient-item-wrap-right">
              <DropDownComponentSortedNoTitle
                api="countries"
                title="Country"
                value={country}
                onChange={handleCountryChange}
              />
            </div>
          </div>

          <div className="addclient-items-wrap">
            <div className="addclient-item-wrap-left">
              Primary Addiction
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </div>

            <div className="addclient-item-wrap-right">
              <DropDownComponentNoTitle
                api="addictions"
                title="Primary Addiction (either substance or process above)"
                value={primaryAddiction}
                onChange={handlePrimaryAddictionChange}
              />
            </div>
          </div>

          <div className="addclient-items-wrap">
            <div className="addclient-item-wrap-left">
              Intended Length of Stay
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </div>

            <div className="addclient-item-wrap-right">
              <DropDownComponentSortedNoTitle
                api="durationOfStay"
                title="Intended duration of stay"
                width="250px"
                value={durationOfStay}
                onChange={handleDurationOfStayChange}
              />
            </div>
          </div>

          <div className="addclient-items-wrap">
            <div className="addclient-item-wrap-left">
              Estimate Arrival Date
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
              {/* <span style={{ color: "red", paddingLeft: "5px" }}>*</span> */}
            </div>

            <div className="addclient-item-wrap-right">
              <DatePickerComponentNoTitle
                title="Estimate Date of Arrival"
                width="150px"
                value={eta}
                onChange={handlEtaChange}
              />
            </div>
          </div>

          <div className="addclient-items-wrap">
            <div className="addclient-item-wrap-left">
              Source
              <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
            </div>

            <div className="addclient-item-wrap-right">
              <DropDownComponentSortedNoTitle
                api="clientSource"
                title="Source"
                value={clientSource}
                onChange={handleClientSource}
              />
            </div>
          </div>
          {/* </div>
        </div> */}
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading}
              //   onClick={handlePostRecord}
            >
              {isLoading ? "ADDING CLIENT..." : "ADD CLIENT"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddClient;
