import React, { useContext, useEffect, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import {
  collection,
  addDoc,
  query,
  where,
  doc,
  updateDoc,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "./CustomToast";
import { LanguageContext } from "../../context/LanguageContext";
import { useAuth } from "../../contexts/AuthContext";

export default function InvoicingMain() {
  const { translate } = useContext(LanguageContext);
  const [openAddClientModal, setOpenAddClientModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [, setLoading] = useState(false);
  const [, setLoadingData] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);
  const [notes, setNotes] = useState([]);
  const [, setRun] = useState(false);
  const [showHelpButton, setShowHelpButton] = useState(true);
  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "HELP" après le clic
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(db, "clients"),
        where("clientStatus", "in", [
          "NewLead",
          "Contacted",
          "Qualified",
          "ProposalSent",
          "Incoming",
        ])
      ),
      (querySnapshot) => {
        const clientsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Séparation des clients par statut
        const newLeads = clientsData.filter(
          (client) => client.clientStatus === "NewLead"
        );
        const contacted = clientsData.filter(
          (client) => client.clientStatus === "Contacted"
        );
        const qualified = clientsData.filter(
          (client) => client.clientStatus === "Qualified"
        );
        const proposalSent = clientsData.filter(
          (client) => client.clientStatus === "ProposalSent"
        );
        const incoming = clientsData.filter(
          (client) => client.clientStatus === "Incoming"
        );

        setNewLeads(newLeads);
        setContacted(contacted);
        setQualified(qualified);
        setProposalSent(proposalSent);
        setIncoming(incoming);
        setLoadingData(false);
      },
      (error) => {
        console.error("Error fetching clients: ", error);
        setLoadingData(false);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (selectedCard && openAddClientModal) {
      setFormValues({
        personalInfo: {
          firstName: selectedCard.firstName || "",
          lastName: selectedCard.lastName || "",
          gender: selectedCard.gender || "",
          personalEmail: selectedCard.personalEmail || "",
          phoneNumber: selectedCard.phoneNumber || "",
          country: selectedCard.country || "",
        },
        addictionInfo: {
          primaryAddiction: selectedCard.addiction || "",
          durationOfStay: selectedCard.durationOfStay || "",
          eta: selectedCard.eta || null,
          clientSource: selectedCard.clientSource || "",
        },
        additionalInfo: {
          notes: selectedCard.notes || "",
        },
      });
      setNotes(selectedCard.notes || []); // Charger les notes existantes
    }
  }, [selectedCard, openAddClientModal]); // Ajoutez openAddClientModal ici pour recharger les données lors de la réouverture

  const validateForm = () => {
    const newFormErrors = { ...formErrors };
    let isValid = true;

    switch (activeStep) {
      case 0:
        newFormErrors.personalInfo.firstName =
          formValues.personalInfo.firstName === "";
        newFormErrors.personalInfo.lastName =
          formValues.personalInfo.lastName === "";
        newFormErrors.personalInfo.gender =
          formValues.personalInfo.gender === "";
        newFormErrors.personalInfo.personalEmail =
          formValues.personalInfo.personalEmail === "";
        newFormErrors.personalInfo.phoneNumber =
          formValues.personalInfo.phoneNumber === "";
        newFormErrors.personalInfo.country =
          formValues.personalInfo.country === "";
        newFormErrors.personalInfo.dob =
          formValues.personalInfo.dob === null ||
          formValues.personalInfo.dob === "";
        isValid = Object.values(newFormErrors.personalInfo).every(
          (field) => field === false
        );
        break;
      case 1:
        newFormErrors.addictionInfo.primaryAddiction =
          formValues.addictionInfo.primaryAddiction === "";
        newFormErrors.addictionInfo.durationOfStay =
          formValues.addictionInfo.durationOfStay === "";
        newFormErrors.addictionInfo.eta = formValues.addictionInfo.eta === null;
        newFormErrors.addictionInfo.clientSource =
          formValues.addictionInfo.clientSource === "";
        isValid = Object.values(newFormErrors.addictionInfo).every(
          (field) => field === false
        );
        break;
      default:
        break;
    }

    setFormErrors(newFormErrors);
    return isValid;
  };

  const resetForm = () => {
    setFormValues({
      personalInfo: {
        firstName: "",
        lastName: "",
        gender: "",
        personalEmail: "",
        phoneNumber: "",
        country: "",
      },
      addictionInfo: {
        primaryAddiction: "",
        durationOfStay: "",
        eta: null,
        nickname: "",
        clientSource: "",
      },
      additionalInfo: {
        projectedResidentialDischargeDate: null,
        projectedProgramCompletionDate: null,
        notes: "",
      },
    });
    setFormErrors({
      personalInfo: {
        firstName: false,
        lastName: false,
        gender: false,
        personalEmail: false,
        phoneNumber: false,
        country: false,
      },
      addictionInfo: {
        primaryAddiction: false,
        durationOfStay: false,
        eta: false,
        clientSource: false,
      },
      additionalInfo: {
        projectedResidentialDischargeDate: false,
        projectedProgramCompletionDate: false,
      },
    });
    setActiveStep(0);
    setNotes([]); // Réinitialiser les notes
  };

  // const addictions = [
  //   { id: 1, textDropDownEn: "Alcohol" },
  //   { id: 2, textDropDownEn: "Drugs" },
  //   { id: 3, textDropDownEn: "Gambling" },
  //   // Ajoutez plus d'options si nécessaire
  // ];

  const isStepCompleted = (step) => {
    switch (step) {
      case 0:
        return Object.values(formValues.personalInfo).every(
          (field) => field !== ""
        );
      case 1:
        return Object.values(formValues.addictionInfo).every(
          (field) => field !== "" && field !== null
        );
      default:
        return false;
    }
  };

  const [formErrors, setFormErrors] = useState({
    personalInfo: {
      firstName: false,
      lastName: false,
      gender: false,
      personalEmail: false,
      phoneNumber: false,
      country: false,
      dob: false, // Ajoutez dob ici
    },
    addictionInfo: {
      primaryAddiction: false,
      durationOfStay: false,
      eta: false,
      clientSource: false,
    },
  });

  const [formValues, setFormValues] = useState({
    personalInfo: {
      firstName: "",
      lastName: "",
      gender: "",
      personalEmail: "",
      phoneNumber: "",
      country: "",
      dob: null, // Ajout du champ dob ici
    },
    addictionInfo: {
      primaryAddiction: "",
      durationOfStay: "",
      eta: null,
      nickname: "",
      clientSource: "",
    },
  });

  const [newLeads, setNewLeads] = useState([]);
  const [contacted, setContacted] = useState([]);
  const [qualified, setQualified] = useState([]);
  const [proposalSent, setProposalSent] = useState([]);
  const [incoming, setIncoming] = useState([]);

  // const handleOpenAddClientModal = () => {
  //   setOpenAddClientModal(true);
  // };

  const handleOpenAddClientModal = () => {
    setSelectedCard(null); // Réinitialise selectedCard
    resetForm(); // Réinitialise le formulaire pour s'assurer qu'il est vide
    setOpenAddClientModal(true); // Ouvre le modal
  };

  // const handleCloseAddClientModal = () => {
  //   resetForm();
  //   setOpenAddClientModal(false);
  // };

  const handleCloseAddClientModal = () => {
    if (!selectedCard) {
      resetForm(); // Réinitialise le formulaire uniquement si ce n'est pas en mode édition
    }
    setOpenAddClientModal(false);
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true); // Démarrer le chargement

      // Définir l'objet newLead
      const newLead = {
        firstName: formValues.personalInfo.firstName,
        lastName: formValues.personalInfo.lastName,
        gender: formValues.personalInfo.gender,
        personalEmail: formValues.personalInfo.personalEmail,
        phoneNumber: formValues.personalInfo.phoneNumber,
        country: formValues.personalInfo.country,
        dob: formValues.personalInfo.dob
          ? Timestamp.fromDate(new Date(formValues.personalInfo.dob))
          : null,
        addiction: formValues.addictionInfo.primaryAddiction,
        durationOfStay: formValues.addictionInfo.durationOfStay,
        eta: formValues.addictionInfo.eta,
        clientSource: formValues.addictionInfo.clientSource,
        notes: notes, // Inclure les notes ici
        dateOfAdmission: null,
      };

      try {
        if (selectedCard) {
          // Mettre à jour le lead existant
          const clientRef = doc(db, "clients", selectedCard.id);
          await updateDoc(clientRef, newLead);
          showCustomToast(translate("LeadUpdatedSuccessfully"), "success");
        } else {
          // Créer un nouveau lead
          const docRef = await addDoc(collection(db, "clients"), {
            ...newLead,
            clientStatus: "NewLead", // Définir le statut
          });
          setNewLeads([{ id: docRef.id, ...newLead }, ...newLeads]);
          showCustomToast(translate("LeadCreatedSuccessfully"), "success");
        }
        resetForm(); // Réinitialiser le formulaire après ajout ou mise à jour
        setOpenAddClientModal(false); // Fermer le modal
      } catch (e) {
        showCustomToast(translate("FailedToCreateOrUpdateLead"), "error");
        console.error("Error updating document: ", e);
      } finally {
        setLoading(false); // Arrêter le chargement
      }
    } else {
      showCustomToast(translate("PleaseFillRequiredFields"), "error");
    }
  };

  const handleDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination || source.droppableId === destination.droppableId) return;

    const task = findItemById(draggableId, [
      ...newLeads,
      ...contacted,
      ...qualified,
      ...proposalSent,
      ...incoming,
    ]);

    if (!task) {
      console.error("Task not found");
      return;
    }

    deletePreviousState(source.droppableId, draggableId);
    setNewState(destination.droppableId, task);

    // Mettre à jour le statut du client dans Firestore
    try {
      const clientRef = doc(db, "clients", task.id); // Utilisez l'id réel du document Firestore ici
      await updateDoc(clientRef, {
        clientStatus: getStatusFromDroppableId(destination.droppableId),
      });
      console.log("Client status updated successfully");
    } catch (e) {
      console.error("Error updating client status: ", e);
    }
  };

  function getStatusFromDroppableId(droppableId) {
    switch (droppableId) {
      case "1":
        return "NewLead";
      case "2":
        return "Contacted";
      case "3":
        return "Qualified";
      case "4":
        return "ProposalSent";
      case "5":
        return "Incoming";
      default:
        return "";
    }
  }

  function deletePreviousState(sourceDroppableId, taskId) {
    switch (sourceDroppableId) {
      case "1":
        setNewLeads(removeItemById(taskId, newLeads));
        break;
      case "2":
        setContacted(removeItemById(taskId, contacted));
        break;
      case "3":
        setQualified(removeItemById(taskId, qualified));
        break;
      case "4":
        setProposalSent(removeItemById(taskId, proposalSent));
        break;
      case "5":
        setIncoming(removeItemById(taskId, incoming));
        break;
      default:
        break;
    }
  }

  function setNewState(destinationDroppableId, task) {
    let updatedTask;
    switch (destinationDroppableId) {
      case "1": // New Leads
        updatedTask = { ...task };
        setNewLeads([updatedTask, ...newLeads]);
        break;
      case "2": // Contacted
        updatedTask = { ...task };
        setContacted([updatedTask, ...contacted]);
        break;
      case "3": // Qualified
        updatedTask = { ...task };
        setQualified([updatedTask, ...qualified]);
        break;
      case "4": // Proposal Sent
        updatedTask = { ...task };
        setProposalSent([updatedTask, ...proposalSent]);
        break;
      case "5": // Incoming
        updatedTask = { ...task };
        setIncoming([updatedTask, ...incoming]);
        break;
      default:
        break;
    }
  }

  function findItemById(id, array) {
    return array.find((item) => item.id === id);
  }

  function removeItemById(id, array) {
    return array.filter((item) => item.id !== id);
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {/* <h2 style={{ textAlign: "center" }}>CRM Board</h2> */}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: "60px",
          paddingBottom: "20px",
          marginTop: "20px",
          paddingRight: "30px",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleOpenAddClientModal}
          startIcon={<Add />}
        >
          {translate("AddNewInvoice")}
        </Button>
        {showHelpButton && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleHelpClick}
            sx={{ fontSize: "10px" }}
          >
            {translate("Help")}
          </Button>
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          width: "100%",
          height: "80%",
          paddingLeft: "60px",
          paddingRight: "20px",
          margin: "0 auto",
          backgroundColor: "lightblue",
        }}
      >
        lkjlkj
      </div>
    </DragDropContext>
  );
}
