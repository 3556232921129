import { Box, Modal, Slide } from "@mui/material";
import React, { useContext } from "react";
import { useState } from "react";
import { db } from "../../firebase";
import { collection, doc, onSnapshot, setDoc } from "firebase/firestore";
import { useEffect } from "react";
import UpdloaderPsychosocial from "../Updloader/UpdloaderPsychosocial";
import { format } from "date-fns";
import AddGeneralLiabilityForm from "../forms/AddGeneralLiabilityForm";
import AddInformedConsentForm from "../forms/AddInformedConsentForm";
import AddPsychiatristServicesForm from "../forms/AddPsychiatristServicesForm";
import AddConfidentialityPolicy from "../forms/AddConfidentialityPolicy";
import AddClientRights from "../forms/AddClientRights";
import AddAgressionControl from "../forms/AddAgressionControl";
import UpdloaderTreatmentPlan from "../Updloader/UpdloaderTreatmentPlan";
import AddBioPsycho3 from "../forms/AddBioPsycho3";
import AddContinuatedTherapySupport from "../forms/AddContinuatedTherapySupport";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import { LanguageContext } from "../../context/LanguageContext";
import FormLineTitle3MobileV2 from "../Lines/FormLineTitle3MobileV2";
import TitleMediumTreatmentFormMobileV2 from "../Titles/TitleMediumTreatmentFormMobileV2";
import FormLineMediumTreatmentPlanMobileV2 from "../FormLineMediumTreatmentPlanMobileV2";
import UploaderTreatmentPlanEncapsulatedV2Mobile from "../Updloader/UploaderTreatmentPlanEncapsulatedV2Mobile";
import FormLineAcaMobileV2 from "../Lines/FormLineAcaMobileV2";
import FormLineEDMobileV2 from "../Lines/FormLineEDMobileV2";
import AddTreatmentPlanMobileV2 from "../forms/AddTreatmentPlanMobileV2";
import FormLine80MobileV2Mobile from "../Lines/FormLine80MobileV2Mobile";
import AddDischargeReportMobileV2 from "../forms/AddDischargeReportMobileV2";
import AddAcaFormMobileV2 from "../forms/AddAcaFormMobileV2";
import AddEmergencyDischargeFormMobileV2 from "../forms/AddEmergencyDischargeFormMobileV2";

function ClinicalInformationsMobileV2(props) {
  const styleModalUploadPdf = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
    borderRadius: 5,
    // overflowY: "scroll",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleModalGeneralDischarge = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
    borderRadius: 5,
    // overflowY: "scroll",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleModalGeneral2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
    borderRadius: 5,
    // overflowY: "scroll",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  // USESTATE

  const customStyles = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50,
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    beaconInner: {
      backgroundColor: "#1565c0", // Couleur du cercle intérieur
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)", // Couleur de l'animation concentrique
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const steps = [
    {
      target: "#clinical-information",
      content:
        "This is the Clinical Information section where you can manage and view client's clinical details.",
    },
    {
      target: "#bio-psychosocial-assessment",
      content:
        "This section contains the Bio-psychosocial assessment details of the client.",
    },
    {
      target: "#add-bio-psychosocial-form",
      content:
        "Click this button to add a new Bio-psychosocial assessment form.",
    },
    {
      target: "#treatment-plan",
      content: "Here you can view and manage the client's treatment plan.",
    },
    {
      target: "#add-treatment-plan-form",
      content: "Click this button to add a new treatment plan form.",
    },
    {
      target: "#discharge-forms",
      content: "This section contains all the discharge forms for the client.",
    },
    {
      target: "#add-discharge-summary-form",
      content:
        "Click this button to add a new Discharge Summary / Report form.",
    },
    {
      target: "#add-aca-form",
      content:
        "Click this button to add a new Leave Against Clinical Advice (ACA) form.",
    },
    {
      target: "#add-emergency-discharge-form",
      content: "Click this button to add a new Emergency Discharge form.",
    },
  ];

  const [run, setRun] = useState(false);
  const [, setShowHelpButton] = useState(true);
  const { translate } = useContext(LanguageContext);

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  const [isModified] = useState(false);

  const [, setPrimaryAddiction] = useState(props.primaryAddiction || "");
  const [, setSecondaryAddiction] = useState(props.secondaryAddiction || "");
  const [, setAllergy] = useState(props.allergy || "");
  const [, setDrugAllergy] = useState(props.drugAllergy || "");
  const [, setCurrentLegalInvolvement] = useState(
    props.currentLegalInvolvement || ""
  );
  const [, setOtherMajorHealthIssues] = useState(
    props.otherMajorHealthIssues || ""
  );
  const [, setPrimaryAddictionOther] = useState(props.addictionOther || "");
  const [, setSecondaryAddictionOther] = useState(
    props.secondaryAddictionOther || ""
  );
  const [, setAllergyOther] = useState(props.allergyOther || "");

  const [, setDrugAllergyOther] = useState(props.drugAllergyOther || "");
  const [openUploadBioPsycho2, setOpenUploadBioPsycho2] = useState(false);
  const [openBioPsycho3, setOpenBioPsycho3] = useState(false);
  const [openUploadTreatmentPlan, setOpenUploadTreatmentPlan] = useState(false);
  const [openTreatmentPlanForm, setOpenTreatmentPlanForm] = useState(false);
  const [treatmentPlanCreationDate, setTreatmentPlanCreationDate] =
    useState(null);
  const [treatmentPlanCreationBy, setTreatmentPlanCreationBy] = useState("");
  const [presentingIssues, setPresentingIssues] = useState("");
  const [longTermGoals, setLongTermGoals] = useState("");
  const [objectives, setObjectives] = useState([]);
  const [reviewDone, setReviewDone] = useState(false);
  const [reviewDoneAt, setReviewDoneAt] = useState(null);
  const [reviewDoneBy, setReviewDoneBy] = useState("");
  const [openGeneralLiabilityForm, setOpenGeneralLiabilityForm] =
    useState(false);
  const [openInformedConsentForm, setOpenInformedConsentForm] = useState(false);
  const [openPsychiatricServicesForm, setOpenPsychiatricServicesForm] =
    useState(false);
  const [openClientRightsForm, setOpenClientRightsForm] = useState(false);
  const [openAgressionPolicyForm, setOpenAgressionPolicyForm] = useState(false);
  const [openConfidentialityPolicyForm, setOpenConfidentialityPolicyForm] =
    useState(false);
  const [openDischargeSummaryForm, setOpenDischargeSummaryForm] =
    useState(false);
  const [openAcaForm, setOpenAcaForm] = useState(false);
  const [openEmergencyDischargeForm, setOpenEmergencyDischargeForm] =
    useState(false);

  const handleCloseBioPsychoForm2 = () => setOpenUploadBioPsycho2(false);
  const handleCloseTreatmentPlan2 = () => setOpenUploadTreatmentPlan(false);
  const [
    openContinuatedTherapySupportForm,
    setOpenContinuatedTherapySupportForm,
  ] = useState(false);

  //HANDLE FUNCTIONS

  const handleOpenTreatmentPlanForm = () => setOpenTreatmentPlanForm(true);
  const handleCloseTreatmentPlanForm = () => setOpenTreatmentPlanForm(false);
  const handleCloseGeneralLiabilityForm = () =>
    setOpenGeneralLiabilityForm(false);
  const handleCloseInformedConsentForm = () =>
    setOpenInformedConsentForm(false);
  const handleClosePsychiatricServicesForm = () =>
    setOpenPsychiatricServicesForm(false);
  const handleCloseConfidentialityPolicyForm = () =>
    setOpenConfidentialityPolicyForm(false);
  const handleCloseClientRightsForm = () => setOpenClientRightsForm(false);
  const handleCloseAgressionPolicyForm = () =>
    setOpenAgressionPolicyForm(false);
  const handleCloseDischargeSummaryForm = () =>
    setOpenDischargeSummaryForm(false);
  const handleCloseAcaForm = () => setOpenAcaForm(false);
  const handleCloseEmergencyDischargeForm = () =>
    setOpenEmergencyDischargeForm(false);
  const handleOpenDischargeSummaryForm = () =>
    setOpenDischargeSummaryForm(true);
  const handleOpenAcaForm = () => setOpenAcaForm(true);
  const handleOpenEmergencyDischargeForm = () =>
    setOpenEmergencyDischargeForm(true);

  const handleCloseBioPsycho3 = () => setOpenBioPsycho3(false);

  const handleCloseContinuatedTherapySupportForm = () =>
    setOpenContinuatedTherapySupportForm(false);

  //USEEFFECT

  useEffect(() => {
    props.onModificationChange(isModified);
  }, [isModified]);

  //FUNCTIONS

  const fetchTreatmentPlan = (clientId) => {
    const treatmentPlanRef = doc(db, "treatmentPlans", clientId);

    // Écouter les modifications du traitement
    const unsubscribeTreatmentPlan = onSnapshot(
      treatmentPlanRef,
      (treatmentPlanSnap) => {
        if (treatmentPlanSnap.exists()) {
          const treatmentPlanData = treatmentPlanSnap.data();
          setPresentingIssues(treatmentPlanData.presentingIssues);
          setLongTermGoals(treatmentPlanData.longTermGoals);
          setTreatmentPlanCreationDate(treatmentPlanData.createdAt);
          setTreatmentPlanCreationBy(treatmentPlanData.createdBy);
          setReviewDone(treatmentPlanData.reviewDone);
          setReviewDoneAt(treatmentPlanData.reviewDoneAt);
          setReviewDoneBy(treatmentPlanData.reviewDoneBy);
        } else {
          console.error("Treatment plan not found");
          setPresentingIssues("");
          setLongTermGoals("");
          setTreatmentPlanCreationDate(null);
          setTreatmentPlanCreationBy("");
          setReviewDone(false);
          setReviewDoneAt("");
          setReviewDoneBy("");
        }
      },
      (error) => {
        console.error("Error fetching treatment plan:", error);
      }
    );

    // Écouter les modifications des objectifs
    const objectivesRef = collection(treatmentPlanRef, "objectives");
    const unsubscribeObjectives = onSnapshot(
      objectivesRef,
      (objectivesSnapshot) => {
        const objectives = [];
        objectivesSnapshot.forEach((objectiveDoc) => {
          objectives.push({ id: objectiveDoc.id, ...objectiveDoc.data() });
        });

        setObjectives(objectives);
      },
      (error) => {
        console.error("Error fetching objectives:", error);
      }
    );

    // Retourner une fonction de nettoyage pour se désabonner des listeners
    return () => {
      unsubscribeTreatmentPlan();
      unsubscribeObjectives();
    };
  };

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  //USE EFFECT

  useEffect(() => {
    // Remplacez 'clientId' par l'ID du client pour lequel vous souhaitez récupérer le treatmentPlan
    const unsubscribe = fetchTreatmentPlan(props.clientId);

    // Se désabonner lors du démontage du composant
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    setPrimaryAddiction(props.primaryAddiction || "");
    setPrimaryAddictionOther(props.addictionOther || "");
    setSecondaryAddiction(props.secondaryAddiction || "");
    setAllergy(props.allergy || "");
    setDrugAllergy(props.drugAllergy || "");
    setCurrentLegalInvolvement(props.currentLegalInvolvement || "");
    setOtherMajorHealthIssues(props.otherMajorHealthIssues || "");
    setSecondaryAddictionOther(props.secondaryAddictionOther || "");
    setDrugAllergyOther(props.drugAllergyOther || "");
    setAllergyOther(props.allergyOther || "");
  }, [
    props.primaryAddiction,
    props.addictionOther,
    props.secondaryAddiction,
    props.allergy,
    props.drugAllergy,
    props.currentLegalInvolvement,
    props.otherMajorHealthIssues,
    props.primaryAddictionOther,
    props.secondaryAddictionOther,
    props.allergyOther,
    props.drugAllergyOther,
  ]);

  return (
    <div
      // className="tab-container-white"
      style={{ marginBottom: "90px" }}
    >
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />
      <Modal
        open={openUploadBioPsycho2}
        onClose={handleCloseBioPsychoForm2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalUploadPdf}>
          <UpdloaderPsychosocial
            {...props}
            closemodal={handleCloseBioPsychoForm2}
          />
        </Box>
      </Modal>

      <Modal
        open={openUploadTreatmentPlan}
        onClose={handleCloseTreatmentPlan2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalUploadPdf}>
          <UpdloaderTreatmentPlan
            {...props}
            closemodal={handleCloseTreatmentPlan2}
          />
        </Box>
      </Modal>

      <Modal
        open={openTreatmentPlanForm}
        onClose={handleCloseTreatmentPlanForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openTreatmentPlanForm}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AddTreatmentPlanMobileV2
              {...props}
              clientId={props.clientId}
              closemodal={handleCloseTreatmentPlanForm}
            />
          </Box>
        </Slide>
      </Modal>
      <Modal
        open={openGeneralLiabilityForm}
        onClose={handleCloseGeneralLiabilityForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          <AddGeneralLiabilityForm
            closemodal={handleCloseGeneralLiabilityForm}
            clientId={props.clientId}
          />
        </Box>
      </Modal>

      <Modal
        open={openInformedConsentForm}
        onClose={handleCloseInformedConsentForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          <AddInformedConsentForm
            clientId={props.clientId}
            closemodal={handleCloseInformedConsentForm}
          />
        </Box>
      </Modal>

      <Modal
        open={openPsychiatricServicesForm}
        onClose={handleClosePsychiatricServicesForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          <AddPsychiatristServicesForm
            closemodal={handleClosePsychiatricServicesForm}
            clientId={props.clientId}
          />
        </Box>
      </Modal>

      <Modal
        open={openConfidentialityPolicyForm}
        onClose={handleCloseConfidentialityPolicyForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          <AddConfidentialityPolicy
            closemodal={handleCloseConfidentialityPolicyForm}
            clientId={props.clientId}
          />
        </Box>
      </Modal>

      <Modal
        open={openClientRightsForm}
        onClose={handleCloseClientRightsForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          <AddClientRights
            closemodal={handleCloseClientRightsForm}
            clientId={props.clientId}
          />
        </Box>
      </Modal>

      <Modal
        open={openAgressionPolicyForm}
        onClose={handleCloseAgressionPolicyForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          <AddAgressionControl
            closemodal={handleCloseAgressionPolicyForm}
            clientId={props.clientId}
          />
        </Box>
      </Modal>

      <Modal
        open={openDischargeSummaryForm}
        onClose={handleCloseDischargeSummaryForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openDischargeSummaryForm}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AddDischargeReportMobileV2
              clientId={props.clientId}
              closemodal={handleCloseDischargeSummaryForm}
              dateOfAdmission={props.dateOfAdmission}
            />
          </Box>
        </Slide>
      </Modal>

      <Modal
        open={openBioPsycho3}
        onClose={handleCloseBioPsycho3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneralDischarge}>
          <AddBioPsycho3
            clientId={props.clientId}
            closemodal={handleCloseBioPsycho3}
            dateOfAdmission={props.dateOfAdmission}
          />
        </Box>
      </Modal>

      <Modal
        open={openAcaForm}
        onClose={handleCloseAcaForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openAcaForm}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AddAcaFormMobileV2
              closemodal={handleCloseAcaForm}
              clientId={props.clientId}
              {...props}
            />
          </Box>
        </Slide>
      </Modal>

      <Modal
        open={openEmergencyDischargeForm}
        onClose={handleCloseEmergencyDischargeForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openEmergencyDischargeForm}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AddEmergencyDischargeFormMobileV2
              closemodal={handleCloseEmergencyDischargeForm}
              clientId={props.clientId}
              {...props}
            />
          </Box>
        </Slide>
      </Modal>

      <Modal
        open={openContinuatedTherapySupportForm}
        onClose={handleCloseContinuatedTherapySupportForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral2}>
          <AddContinuatedTherapySupport
            closemodal={handleCloseContinuatedTherapySupportForm}
            clientId={props.clientId}
          />
        </Box>
      </Modal>

      <div style={{ paddingLeft: "0px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: "20px",
          }}
        >
          <span
            id="clinical-information"
            style={{ fontSize: "16px", fontWeight: "600", paddingLeft: "10px" }}
          >
            {" "}
            {translate("ClinicalInformations")}
          </span>
          {/* {showHelpButton && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleHelpClick}
              sx={{ fontSize: "10px" }}
            >
              {translate("Help")}
            </Button>
          )} */}
        </div>
      </div>

      <div>
        <div>
          <div
            style={{
              paddingLeft: "10px",
              paddingBlock: "0px",
              borderTop: "1px solid #cccc",
              paddingTop: "10px",
              marginTop: "20px",
            }}
            className="tab-container-title"
          >
            <span
              id="treatment-plan"
              style={{
                paddingLeft: "0px",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              {" "}
              {translate("TreatmentPlan")}
            </span>
          </div>
          <div className="form-line-button" style={{ paddingTop: "10px" }}>
            <FormLineTitle3MobileV2
              title={translate("AddTreatmentPlan")}
              color="2"
              {...props}
              handleOpen={handleOpenTreatmentPlanForm}
            />

            {/* {presentingIssues ? (
              ""
            ) : (
              <div id="add-treatment-plan-form">
                <AddButtonRound handleOpen={handleOpenTreatmentPlanForm} />
              </div>
            )} */}
          </div>
          {!presentingIssues ? (
            <div className="error-discharge-message">
              {translate("NoTreatmentPlanAdded")}{" "}
            </div>
          ) : (
            <div
              style={{
                paddingLeft: "0px",
                paddingTop: "10px",
                paddingBottom: "20px",
              }}
            >
              <TitleMediumTreatmentFormMobileV2 />

              <FormLineMediumTreatmentPlanMobileV2
                title={translate("TreatmentPlan")}
                signed={presentingIssues}
                reviewDone={reviewDone}
                reviewDoneBy={reviewDoneBy}
                reviewDoneAt={reviewDoneAt}
                date={treatmentPlanCreationDate}
                createdBy={treatmentPlanCreationBy}
                objectives={objectives}
                presentingIssues={presentingIssues}
                longTermGoals={longTermGoals}
                {...props}
              />
            </div>
          )}

          <div
            style={{
              width: "100%",
              marginLeft: 0,
              marginBottom: 30,
            }}
          >
            {/* <UploaderTreatmentFormEncapsulated {...props} /> */}
            <UploaderTreatmentPlanEncapsulatedV2Mobile {...props} />
          </div>

          <div></div>
        </div>
      </div>

      <div
        style={{
          paddingLeft: "0px",
          paddingBlock: "0px",
          borderTop: "1px solid #cccc",
          paddingTop: "10px",
          marginTop: "0px",
        }}
      >
        {/* <div className="tab-container-title margin-title">Discharge forms</div> */}
        <div
          style={{ paddingLeft: "0px", paddingBlock: "0px" }}
          className="tab-container-title"
        >
          <span
            id="discharge-forms"
            style={{
              paddingLeft: "10px",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            {translate("DischargeForms")}
          </span>
        </div>
        {props.dateOfAdmission ? (
          <div className="tab-form-container-bottom">
            {/* <Title /> */}
            <div className="form-line-button">
              <FormLine80MobileV2Mobile
                title={translate("DischargeSummary")}
                color="1"
                signed={props.DischargeReportOk}
                date={formatDate(props.DischargeReportDateSigned)}
                {...props}
                viewButton={props.DischargeReportOk}
                handleOpen={handleOpenDischargeSummaryForm}
              />
            </div>
            <div className="form-line-button">
              <FormLineAcaMobileV2
                title={translate("LeaveAgainstClinicalAdvice")}
                viewButton={props.acaReportOk}
                {...props}
                date={formatDate(props.acaReportDateSigned)}
                signed={props.acaReportOk}
                handleOpen={handleOpenAcaForm}
              />
            </div>
            <div className="form-line-button">
              <FormLineEDMobileV2
                title={translate("EmergencyDischarge")}
                color="1"
                viewButton={props.EmergencyDischargeOk}
                signed={props.EmergencyDischargeOk}
                date={formatDate(props.EmergencyDischargeDateSigned)}
                {...props}
                handleOpen={handleOpenEmergencyDischargeForm}
              />
            </div>
          </div>
        ) : (
          <div className="error-discharge-message">
            Unable to sign Discharge form if the client is not admitted.
          </div>
        )}
      </div>
    </div>
  );
}

export default ClinicalInformationsMobileV2;
