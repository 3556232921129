import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { showCustomToast } from "../Misc/CustomToast";

function EditQuickNote(props) {
  const { currentUser } = useAuth();
  const [quickNoteText, setQuickNoteText] = useState(props.note);
  const [effectiveDateTime, setEffectiveDateTime] = useState(
    props.effectiveDate
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const handleDateTimeChange = (event) => {
    setHasChanged(true);
    setEffectiveDateTime(event);
  };

  const handleUpdateQuickNote = async (note, noteId, effectiveDateTime) => {
    const noteData = {
      // noteType: "quickNote",
      noteContent: note,
      // clientId: clientID,
      updateAt: serverTimestamp(),
      effectiveDateTime: effectiveDateTime,
      doneBy: currentUser.displayName,
    };

    if (quickNoteText && effectiveDateTime) {
      setIsLoading(true);
      try {
        const noteRef = doc(db, "notes", noteId);
        await updateDoc(noteRef, noteData);
        showCustomToast(`Note successfully updated!`, "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.log("error updating note :", error);
        showCustomToast(`Problem when updating note`, "error");
      }
    } else {
      showCustomToast(
        `Some mandatory fields are empty. Please check.`,
        "error"
      );
    }
  };

  const handleSubmit = () => {
    handleUpdateQuickNote(quickNoteText, props.noteId, effectiveDateTime);
  };

  const handleChange = (event) => {
    setHasChanged(true);
    setQuickNoteText(event.target.value);
  };

  return (
    <>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <div className="modal-title">
            Edit Note{" "}
            {/* <span style={{ fontSize: "10px" }}>(note Id: {props.noteId})</span> */}
          </div>

          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "0px",
            height: "75%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div className="line2-column-modal">
            <div className="label-textfield">Note content</div>
            <TextField
              name="quickNote"
              value={quickNoteText}
              onChange={handleChange}
              style={{
                width: "95%",
                borderRadius: "5px",
                backgroundColor: "#f2eeef",
              }}
              placeholder="Type quick session note here"
              multiline
              rows={14}
            />
          </div>
          <div
            className="line3-modal-nopad"
            style={{
              // marginLeft: "20px",
              paddingLeft: "10px",
              marginBottom: "20px",
            }}
          >
            <DatePickerWithTimeComponent
              title="Effective date and time"
              value={effectiveDateTime}
              onChange={handleDateTimeChange}
            />
          </div>
        </div>
        <div className="line4-modal-percent">
          <Button
            variant="contained"
            style={{ backgroundColor: "white", color: "black" }}
            // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
            onClick={props.closemodal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={
              !hasChanged
                ? {
                    backgroundColor: "lightgray",
                    color: "black",
                    marginLeft: "15px",
                  }
                : isLoading
                ? {
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "15px",
                  }
                : {
                    backgroundColor: "#1565C0",
                    color: "white",
                    marginLeft: "15px",
                  }
            }
            disabled={!hasChanged || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? "UPDATING NOTE..." : "UPDATE NOTE"}
          </Button>
        </div>
      </div>
    </>
  );
}

export default EditQuickNote;
