import React, { useState } from "react";
import ViewButton from "../Buttons/ViewButton";
import CheckWithDate from "../CheckWithDate";
import AddButtonRound from "../Buttons/AddButtonRound"; // Import AddButtonRound
import { Box, Modal, Slide } from "@mui/material";
import ViewGeneralLiability from "../forms/ViewGeneralLiability";

function FormLine3NoMarginV2(props) {
  const [showViewModal3, setShowViewModal3] = useState(false);

  const openViewModal3 = () => {
    setShowViewModal3(true);
  };

  const closeViewModal3 = () => {
    setShowViewModal3(false);
  };

  console.log("showViewmodal3:", showViewModal3);

  return (
    <div
      style={{
        marginLeft: "0px",
        display: "flex",
        flexDirection: "row",
        height: "50px",
        borderRadius: "30px",
        border: "1px solid lightgrey",
        width: "40%",
        marginTop: "5px",
        marginBottom: "5px",
        paddingLeft: "15px",
        paddingRight: "5px",
      }}
    >
      <Modal open={showViewModal3} onClose={closeViewModal3}>
        <Slide direction="left" in={showViewModal3}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "30%",
              height: "90%",
              minHeight: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
              zIndex: "1",
            }}
          >
            <ViewGeneralLiability {...props} closemodal={closeViewModal3} />
          </Box>
        </Slide>
      </Modal>
      <div
        style={{
          width: "60%",
          fontSize: "18px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props.title}
      </div>
      <div
        style={{
          width: "30%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.signed ? (
          <CheckWithDate checked={true} content={props.date} />
        ) : (
          <CheckWithDate checked={false} content="Not signed" />
        )}
      </div>
      <div
        style={{
          width: "10%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props.signed ? (
          <ViewButton handleOpen2={openViewModal3} {...props} />
        ) : (
          <AddButtonRound handleOpen={props.handleOpen} />
        )}
      </div>
    </div>
  );
}

export default FormLine3NoMarginV2;
