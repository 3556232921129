import { Input } from "@mui/material";
import React from "react";

export default function InputComponentNoTitleNomargin(props) {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };
  return (
    <div className="input-label-container-newdesign-nomargin">
      <Input
        variant="outlined"
        // placeholder="Search client…"
        onChange={handleChange}
        value={props.value}
        style={{
          width: props.width,
          height: "40px",
          borderRadius: "5px",
          background: "#FFFFFF",
          border: "1px solid #BEBCBC",
          marginTop: "2px",
          paddingLeft: "8px",
        }}
        placeholder={props.placeholder}
      />
    </div>
  );
}
