// src/components/PromptInput.js
import React from "react";
import { TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const PromptInputMobile = ({
  prompt,
  handleInputChange,
  handleSendClick,
  handleInputKeyPress,
  selectedClient,
  handleClientChange,
  clientsNotAnonymized,

  disabledSend,
}) => {
  return (
    <div
      style={{
        width: "90%",
        paddingLeft: "0px",
        paddingRight: "0px",
        marginBottom: "100px",
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <TextField
        variant="outlined"
        rows={prompt.length > 0 ? 4 : 1}
        multiline
        onChange={handleInputChange}
        value={prompt}
        fullWidth
        onKeyPress={handleInputKeyPress}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "0px",
          alignContent: "center",
          alignItems: "center",
          paddingTop: "5px",
        }}
      >
        {/* Client Selection */}
        <div
          style={{
            minWidth: 200,
            marginRight: "20px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <select
            id="client-select"
            value={selectedClient}
            onChange={handleClientChange}
            style={{
              width: "100%",
              // padding: "10px",
              padding: "5px 10px", // Réduire le padding vertical
              borderRadius: "5px",
              border: "1px solid #ccc",
              backgroundColor: "#fff",
              fontSize: "14px",
              height: "30px",
              overflow: "auto",
            }}
          >
            <option value="All Clients">All Clients</option>
            {clientsNotAnonymized.map((client) => (
              <option key={client.id} value={client.id}>
                {client.firstName} {client.lastName}
              </option>
            ))}
          </select>
        </div>

        <div
          style={{
            backgroundColor: disabledSend
              ? "lightgray"
              : prompt.length > 0
              ? "#1565C0"
              : "white",
            paddingLeft: "12px",
            paddingRight: "8px",
            paddingTop: "8px",
            paddingBottom: "5px",
            borderRadius: "5px",
          }}
        >
          <SendIcon
            style={{
              cursor: disabledSend ? "not-allowed" : "pointer",
              color: disabledSend
                ? "gray"
                : prompt.length > 0
                ? "white"
                : "gray",
            }}
            onClick={disabledSend ? null : handleSendClick}
          />
        </div>

        {/* Log Prompt Button */}
      </div>
    </div>
  );
};

export default PromptInputMobile;
