import React, { useContext, useEffect, useState } from "react";
import "../css/dashboard.css";
import "../css/client.css";
import {
  Box,
  Button,
  createTheme,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slide,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import AddClient from "../component/forms/AddClient";
import {
  collection,
  documentId,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { roles } from "../firebase.collection";
import AddQuickNoteWithClients from "../component/Notes/AddQuickNoteWithClients";
import { db } from "../firebase";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import NotesContainerCompact from "../component/Notes/NotesContainerCompact";
import { LanguageContext } from "../context/LanguageContext";
import { showCustomToast } from "../component/Misc/CustomToast";
import PhoneNumberInputNoTitleV2 from "../component/PhoneNumberInputNoTitleV2";
import CloseIcon from "@mui/icons-material/Close";
import gender from "../api/gender";
import clientSource from "../api/clientSource";
import countries from "../api/countries";
import addictions from "../api/addictions";
import durationOfStay from "../api/durationOfStay";
import SearchBarMobileV3 from "../component/SearchBarMobileV3";
import AddButtonRoundSmallMobileV3 from "../component/Buttons/AddButtonRoundSmallMobileV3";
import MetricsWidgetV3Mobile from "../component/MetricsWidgetV3Mobile";
import MetricsWidgetV2Mobile from "../component/MetricsWidgetV2Mobile";
import TitleNoteCompactMobile from "../component/Notes/TitleNoteCompactMobile";
import NotesContainerCompactMobile from "../component/Notes/NotesContainerCompactMobile";
import Joyride, { ACTIONS, STATUS } from "react-joyride";

export default function HomepageContent() {
  const { translate } = useContext(LanguageContext);
  const [notes, setNotes] = useState([]);
  const [, setReadCount] = useState(0);
  const { currentUser } = useAuth();
  const userIdState = currentUser ? currentUser.uid : "";
  const [, setUserRole] = useState("");
  const [, setNoteTypes] = useState([]);
  const [selectedClientName] = useState("");
  const [, setClientNames] = useState([]);
  const [startDate] = useState(null);
  const [endDate] = useState(null);
  const [selectedNoteType] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientsIncoming, setClientsIncoming] = useState([]);
  const [nextDischargeClient, setNextDischargeClient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [, setIsLoadingIncoming] = useState(true);
  const [firestoreReadCount, setFirestoreReadCount] = useState(0);
  const [incomingClients, setIncomingClients] = useState(0);
  const [, setPotentialClients] = useState(0);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [isLoadingNotes, setIsLoadingNotes] = useState(true);
  const [notesLimit] = useState(30); // Commence par requêter 10 notes
  const [visibleNotesCount, setVisibleNotesCount] = useState(7); // Initialement afficher 7 notes
  const [run, setRun] = useState(false); // Pour démarrer ou arrêter Joyride
  const incrementFirestoreReadCount = (incrementBy = 1) => {
    setFirestoreReadCount((prevCount) => prevCount + incrementBy);
  };

  const [, setShowHelpButton] = useState(true);

  const handleClientClick = (clientId) => {
    window.open(`/client/${clientId}`, "_blank");
  };

  const navigate = useNavigate();

  const handleWidgetClick = (selectedTab) => {
    navigate("/clients", { state: { selectedTab } });
  };

  // const handleHelpClick = () => {
  //   setRun(true);
  //   setShowHelpButton(false); // Cache le bouton "Help" après le clic
  // };

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  // console.log("isLoadingNotes: ", isLoadingNotes);

  const handleSort = (order) => {
    let sortedNotes = [...notes];
    switch (order) {
      case "dateAsc":
        sortedNotes.sort(
          (a, b) => a.effectiveDateTime.toDate() - b.effectiveDateTime.toDate()
        );
        break;
      case "dateDesc":
        sortedNotes.sort(
          (a, b) => b.effectiveDateTime.toDate() - a.effectiveDateTime.toDate()
        );
        break;
      case "clientAsc":
        sortedNotes.sort((a, b) =>
          (a.clientFirstName + " " + a.clientLastName).localeCompare(
            b.clientFirstName + " " + b.clientLastName
          )
        );
        break;
      case "clientDesc":
        sortedNotes.sort((a, b) =>
          (b.clientFirstName + " " + b.clientLastName).localeCompare(
            a.clientFirstName + " " + a.clientLastName
          )
        );
        break;
      case "typeAsc":
        sortedNotes.sort((a, b) => a.noteType.localeCompare(b.noteType));
        break;
      case "typeDesc":
        sortedNotes.sort((a, b) => b.noteType.localeCompare(a.noteType));
        break;
      default:
        break;
    }
    setNotes(sortedNotes);
  };

  useEffect(() => {
    // setIsLoading({
    //   ...isLoading,
    //   inHouse: true,
    //   incoming: true,
    //   potential: true,
    // });

    setIsLoading((prevIsLoading) => ({
      ...prevIsLoading,
      inHouse: true,
      incoming: true,
      potential: true,
    }));

    const queryRef = query(
      collection(db, "clients"),
      where("clientStatus", "==", "In House")
    );

    const unsubscribe = onSnapshot(
      queryRef,
      async (snapshot) => {
        await sleep(500);
        const clientsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClients(clientsData);

        // Fetch incoming clients count
        const incomingQueryRef = query(
          collection(db, "clients"),
          where("clientStatus", "==", "Incoming")
        );

        const potentialQueryRef = query(
          collection(db, "clients"),
          where("clientStatus", "==", "Potential")
        );

        const [incomingSnapshot, potentialSnapshot] = await Promise.all([
          getDocs(incomingQueryRef),
          getDocs(potentialQueryRef),
        ]);

        setIncomingClients(incomingSnapshot.size);
        setPotentialClients(potentialSnapshot.size);

        setIsLoading({
          inHouse: false,
          incoming: false,
          potential: false,
        });
        incrementFirestoreReadCount(snapshot.docs.length);
      },
      (error) => {
        console.error("Failed to fetch clients:", error);
        setIsLoading({
          inHouse: false,
          incoming: false,
          potential: false,
        });
      }
    );

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  useEffect(() => {
    setIsLoadingIncoming(true);

    const clientQuery = query(
      collection(db, "clients"),
      where("clientStatus", "==", "Incoming")
    );

    const unsubscribe = onSnapshot(
      clientQuery,
      async (snapshot) => {
        const clientsIncomingData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setClientsIncoming(clientsIncomingData);
        setIsLoadingIncoming(false);
        incrementFirestoreReadCount(snapshot.docs.length);
      },
      (error) => {
        console.error("Failed to fetch Incoming clients:", error);
        setIsLoadingIncoming(false);
      }
    );

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  useEffect(() => {
    if (clients.length > 0) {
      const now = new Date();
      const closestClient = clients.reduce((closest, client) => {
        const dischargeDate = new Date(
          client.projectedResidentialDischargeDate?.seconds * 1000
        );

        if (isNaN(dischargeDate.getTime()) || dischargeDate <= now) {
          return closest; // Ignore invalid or past dates
        }
        if (
          closest === null ||
          dischargeDate <
            new Date(closest.projectedResidentialDischargeDate?.seconds * 1000)
        ) {
          return client;
        }
        return closest;
      }, null);

      setNextDischargeClient(closestClient);
    }
  }, [clients]);

  function formatDate2(date) {
    if (!date || isNaN(date.getTime())) {
      console.log("Invalid date provided to formatDate");
      return "Invalid date"; // Retourner une chaîne indiquant que la date est invalide
    }
    try {
      return format(date, "dd MMM yyyy"); // Format correct avec date-fns
    } catch (error) {
      console.error("Error formatting date:", error);
      return "Formatting error";
    }
  }

  useEffect(() => {
    const notesRef = collection(db, "notes");

    const getSortingDate = (note) => {
      const sortingDate =
        note.noteType === "endOfShift" ? note.shiftEnd : note.effectiveDateTime;

      if (!sortingDate || typeof sortingDate.toDate !== "function") {
        console.error(`Invalid date for note ID: ${note.id}`);
        return new Date(0); // Return a default date or handle appropriately
      }

      return sortingDate.toDate();
    };

    const q = query(notesRef, limit(notesLimit));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let notesData = [];
      const types = new Set();
      // console.log("Snapshot received:", querySnapshot.size, "documents");

      querySnapshot.forEach((doc) => {
        const noteData = { id: doc.id, ...doc.data() };
        // console.log("Note data:", noteData);

        notesData.push(noteData);
        types.add(noteData.noteType);
      });

      // const aftercareNotes = notesData.filter(
      //   (note) => note.noteType === "aftercareNote"
      // );

      notesData = notesData.sort((a, b) => {
        const dateA = getSortingDate(a);
        const dateB = getSortingDate(b);
        return dateB - dateA; // Tri décroissant
      });

      console.log("All notes sorted by effectiveDateTime:");
      notesData.forEach((note) => {});

      setNotes(notesData);
      setNoteTypes(Array.from(types));
      setReadCount((prevCount) => prevCount + querySnapshot.size);
      incrementFirestoreReadCount(querySnapshot.size);
      setIsLoadingNotes(false);
    });

    return () => {
      unsubscribe();
    };
  }, [notesLimit]);

  // const handleShowMoreNotes = () => {
  //   setNotesLimit((prevLimit) => prevLimit + 10); // Requêter 10 notes supplémentaires
  // };
  const handleShowMoreNotes = () => {
    setVisibleNotesCount((prevCount) => prevCount + 10); // Ajouter 10 notes supplémentaires
  };

  useEffect(() => {
    console.log(`Total Firestore reads: ${firestoreReadCount}`);
  }, [firestoreReadCount]);

  useEffect(() => {
    if (selectedNoteType.length > 0) {
      const filteredNotesByType = notes.filter((note) =>
        selectedNoteType.includes(note.noteType)
      );
      const uniqueClientNames = Array.from(
        new Set(
          filteredNotesByType.map(
            (note) => note.clientFirstName + " " + note.clientLastName
          )
        )
      );
      setClientNames(uniqueClientNames);
    } else {
      // Si aucun type de note n'est sélectionné, afficher tous les clients
      const allClientNames = Array.from(
        new Set(
          notes.map((note) => note.clientFirstName + " " + note.clientLastName)
        )
      );
      setClientNames(allClientNames);
      // console.log("clientNames:", clientNames);
    }
  }, [selectedNoteType, notes]);

  const filteredNotes = notes.filter((note) => {
    const matchesType = selectedNoteType.length
      ? selectedNoteType.includes(note.noteType)
      : true;
    const matchesClient = selectedClientName
      ? note.clientFirstName + " " + note.clientLastName === selectedClientName
      : true;
    const matchesDate =
      startDate && endDate
        ? note.effectiveDateTime &&
          note.effectiveDateTime.toDate() >= startDate &&
          note.effectiveDateTime.toDate() <= endDate
        : true;
    return matchesType && matchesClient && matchesDate;
  });

  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "80%",
    borderRadius: 5,
    // overflowY: "scroll",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleModalQuickNote = {
    ...styleModalGeneral, // Vous pouvez réutiliser le style de la modal générale
  };

  const [openAddClient, setOpenAddClient] = useState(false);
  const [openAddQuickNote, setOpenAddQuickNote] = useState(false);

  const handleCloseAddClient = () => setOpenAddClient(false);

  const handleCloseAddQuickNote = () => setOpenAddQuickNote(false);

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;
          setUserRole(role);
        } else {
          console.log("No role for this user");
        }
      }
    );
  }

  const resetForm = () => {
    setFormValues({
      personalInfo: {
        firstName: "",
        lastName: "",
        gender: "",
        personalEmail: "",
        phoneNumber: "",
        country: "",
      },
      addictionInfo: {
        primaryAddiction: "",
        durationOfStay: "",
        eta: null,
        nickname: "",
        clientSource: "",
      },
      additionalInfo: {
        projectedResidentialDischargeDate: null,
        projectedProgramCompletionDate: null,
        notes: "",
      },
    });
    setFormErrors({
      personalInfo: {
        firstName: false,
        lastName: false,
        gender: false,
        personalEmail: false,
        phoneNumber: false,
        country: false,
      },
      addictionInfo: {
        primaryAddiction: false,
        durationOfStay: false,
        eta: false,
        nickname: false,
        clientSource: false,
      },
      additionalInfo: {
        projectedResidentialDischargeDate: false,
        projectedProgramCompletionDate: false,
        notes: false,
      },
    });
    setActiveStep(0);
  };

  useEffect(() => {
    if (currentUser) {
      queryRole();
      // recordMetrics("Clients", "pageLoad", currentUser.email, "read", "bodhi");
    } else {
      console.log("No user id");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const formatDate = (date, options) => {
    return date
      ? new Intl.DateTimeFormat("en-EN", options).format(date.toDate())
      : "";
  };

  const handleShowLessNotes = () => {
    setVisibleNotesCount((prevCount) => Math.max(prevCount - 10, 7)); // Réduire de 10 notes, mais ne pas descendre en dessous de 7
  };

  // console.log("notes:", notes);

  const renderMetricsWidgets = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div
          id="metrics-inhouse"
          style={{
            marginRight: "0px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            cursor: "pointer",
            paddingLeft: "3%",
            paddingRight: "3%",
          }}
          onClick={() => handleWidgetClick("In house")}
        >
          <MetricsWidgetV2Mobile
            title="# INHOUSE "
            value={clients.length.toString()}
            title2={
              clients.length > 1 ? translate("Clients") : translate("Client")
            }
            textColor="black"
            textColor2="#7a8199"
            iconName="People"
            isLoading={isLoading.inHouse}
          />
        </div>

        {/* <div
          style={{ borderRight: "1px solid #cbcbcd", marginRight: "0px" }}
        ></div> */}

        <div
          id="metrics-incoming"
          style={{
            marginRight: "0px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            cursor: "pointer",
            paddingLeft: "3%",
            paddingRight: "3%",
            marginTop: "10px",
          }}
          onClick={() => handleWidgetClick("Incoming")}
        >
          <MetricsWidgetV2Mobile
            title={translate("Incoming")}
            value={incomingClients.toString()}
            title2={
              incomingClients > 1 ? translate("Clients") : translate("Client")
            }
            textColor="black"
            textColor2="#7a8199"
            iconName="People"
            isLoading={isLoading.incoming}
          />
        </div>

        <div
          id="metrics-next-departure"
          style={{
            marginRight: "0px",
            width: "100%",
            paddingLeft: "3%",
            paddingRight: "3%",
            marginTop: "10px",
          }}
        >
          <MetricsWidgetV3Mobile
            title={translate("NextDeparture")}
            value={
              nextDischargeClient ? (
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    // fontSize: "22px",
                  }}
                  onClick={() => handleClientClick(nextDischargeClient.id)}
                >
                  {`${nextDischargeClient.firstName} ${nextDischargeClient.lastName}`}
                </span>
              ) : (
                <div style={{ fontSize: "14px" }}>
                  {translate("NoDischarges")}
                </div>
              )
            }
            date={
              nextDischargeClient
                ? formatDate2(
                    new Date(
                      nextDischargeClient.projectedResidentialDischargeDate
                        ?.seconds * 1000
                    )
                  )
                : ""
            }
            textColor="black"
            textColor2="#7a8199"
            iconName="Flight"
            isLoading={isLoading.inHouse}
          />
        </div>
        <div
          style={{ borderRight: "1px solid #cbcbcd", marginRight: "40px" }}
        ></div>
        <div
          id="metrics-next-incoming"
          style={{
            marginRight: "0px",
            width: "100%",
            paddingLeft: "3%",
            paddingRight: "3%",
            marginTop: "10px",
          }}
        >
          <MetricsWidgetV3Mobile
            title={translate("NextIncoming")}
            value={
              clientsIncoming.length > 0 ? (
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  onClick={() => handleClientClick(clientsIncoming[0].id)}
                >
                  {`${clientsIncoming[0].firstName} ${clientsIncoming[0].lastName}`}
                </span>
              ) : (
                translate("NoUpcomingClient")
              )
            }
            date={
              clientsIncoming.length > 0
                ? formatDate2(new Date(clientsIncoming[0].eta?.seconds * 1000))
                : ""
            }
            textColor="black"
            textColor2="#7a8199"
            iconName="Event"
            isLoading={isLoading.incoming}
          />
        </div>
        <div
          style={{ borderRight: "1px solid #cbcbcd", marginRight: "40px" }}
        ></div>
      </div>
    );
  };

  const [openAddClientModal, setOpenAddClientModal] = useState(false);
  const handleOpenAddClientModal = () => {
    resetForm();
    setOpenAddClientModal(true);
  };
  const handleCloseAddClientModal = () => {
    resetForm();
    setOpenAddClientModal(false);
  };
  const [activeStep, setActiveStep] = useState(0); // Set the current step to 4th (0-indexed)

  // const theme = useTheme();

  const theme = createTheme({
    palette: {
      primary: {
        main: "#556cd6",
      },
      secondary: {
        main: "#19857b",
      },
      success: {
        main: "#4caf50", // Ajoute ceci si 'success' n'est pas défini
      },
    },
  });

  const [formValues, setFormValues] = useState({
    personalInfo: {
      firstName: "",
      lastName: "",
      gender: "",
      personalEmail: "",
      phoneNumber: "",
      country: "",
    },
    addictionInfo: {
      primaryAddiction: "",
      durationOfStay: "",
      eta: null,
      nickname: "",
      clientSource: "",
    },
    additionalInfo: {
      projectedResidentialDischargeDate: null,
      projectedProgramCompletionDate: null,
      notes: "",
    },
  });

  const [formErrors, setFormErrors] = useState({
    personalInfo: {
      firstName: false,
      lastName: false,
      gender: false,
      personalEmail: false,
      phoneNumber: false,
      country: false,
    },
    addictionInfo: {
      primaryAddiction: false,
      durationOfStay: false,
      eta: false,
      nickname: false,
      clientSource: false,
    },
    additionalInfo: {
      projectedResidentialDischargeDate: false,
      projectedProgramCompletionDate: false,
      notes: false,
    },
  });

  // const steps = [
  //   translate("PersonalInfos"),
  //   translate("AddictionInfos"),
  //   translate("DischargeInfos"),
  // ];

  const steps = [
    {
      target: "#metrics-inhouse2", // Assurez-vous d'ajouter cette classe au widget In-House
      content:
        "This widget displays the total number of clients currently residing in the facility. Click on it to view the detailed list of all in-house clients.",
    },
    {
      target: "#metrics-inhouse", // Assurez-vous d'ajouter cette classe au widget In-House
      content: translate("InHouseClientsInfo"),
    },
    {
      target: "#metrics-incoming", // Assurez-vous d'ajouter cette classe au widget Incoming
      content: translate("UpcomingClientsInfo"),
    },
    {
      target: "#metrics-next-departure", // Assurez-vous d'ajouter cette classe au widget Next Departure
      content: translate("NextClientDepartureInfo"),
    },
    {
      target: "#metrics-next-incoming", // Assurez-vous d'ajouter cette classe au widget Next Incoming
      content: translate("NextIncomingClientInfo"),
    },
  ];

  const customStyles = {
    formControl: {
      marginTop: "16px",
      marginBottom: "8px",
      "& .MuiInputLabel-root": {
        transform: "translate(14px, 12px) scale(1)",
        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      },
      "& .MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
      "& .MuiInputBase-root": {
        "&.Mui-focused .MuiInputLabel-root": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
    },
  };

  const customStyles2 = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50,
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    beaconInner: {
      backgroundColor: "#1565c0",
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)",
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const handleNext = async () => {
    if (validateForm()) {
      if (activeStep === steps.length - 1) {
        await saveClient();
        handleCloseAddClientModal();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      console.log("Form is not valid:", formErrors); // Add this line
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (step, field) => (event) => {
    const value = event && event.target ? event.target.value : event; // handle both event.target.value and direct value from PhoneInput
    setFormValues({
      ...formValues,
      [step]: { ...formValues[step], [field]: value },
    });
  };

  const validateForm = () => {
    const newFormErrors = { ...formErrors };
    let isValid = true;

    switch (activeStep) {
      case 0:
        newFormErrors.personalInfo.firstName =
          formValues.personalInfo.firstName === "";
        newFormErrors.personalInfo.lastName =
          formValues.personalInfo.lastName === "";
        newFormErrors.personalInfo.gender =
          formValues.personalInfo.gender === "";
        newFormErrors.personalInfo.personalEmail =
          formValues.personalInfo.personalEmail === "";
        newFormErrors.personalInfo.phoneNumber =
          formValues.personalInfo.phoneNumber === "";
        newFormErrors.personalInfo.country =
          formValues.personalInfo.country === "";
        isValid =
          !newFormErrors.personalInfo.firstName &&
          !newFormErrors.personalInfo.lastName &&
          !newFormErrors.personalInfo.gender &&
          !newFormErrors.personalInfo.personalEmail &&
          !newFormErrors.personalInfo.phoneNumber &&
          !newFormErrors.personalInfo.country;
        break;
      case 1:
        newFormErrors.addictionInfo.primaryAddiction =
          formValues.addictionInfo.primaryAddiction === "";
        newFormErrors.addictionInfo.durationOfStay =
          formValues.addictionInfo.durationOfStay === "";
        newFormErrors.addictionInfo.eta = formValues.addictionInfo.eta === null;
        newFormErrors.addictionInfo.clientSource =
          formValues.addictionInfo.clientSource === "";
        isValid =
          !newFormErrors.addictionInfo.primaryAddiction &&
          !newFormErrors.addictionInfo.durationOfStay &&
          !newFormErrors.addictionInfo.eta &&
          !newFormErrors.addictionInfo.clientSource;
        break;
      case 2:
        newFormErrors.additionalInfo.projectedResidentialDischargeDate =
          formValues.additionalInfo.projectedResidentialDischargeDate === null;
        newFormErrors.additionalInfo.projectedProgramCompletionDate =
          formValues.additionalInfo.projectedProgramCompletionDate === null;
        isValid =
          !newFormErrors.additionalInfo.projectedResidentialDischargeDate &&
          !newFormErrors.additionalInfo.projectedProgramCompletionDate;
        break;
      default:
        break;
    }

    setFormErrors(newFormErrors);

    if (!isValid) {
      showCustomToast("Please fill all required fields", "error");
    }

    return isValid;
  };

  const isStepCompleted = (step) => {
    switch (step) {
      case 0:
        return (
          formValues.personalInfo.firstName !== "" &&
          formValues.personalInfo.lastName !== "" &&
          formValues.personalInfo.gender !== "" &&
          formValues.personalInfo.personalEmail !== "" &&
          formValues.personalInfo.phoneNumber !== "" &&
          formValues.personalInfo.country !== ""
        );
      case 1:
        return (
          formValues.addictionInfo.primaryAddiction !== "" &&
          formValues.addictionInfo.durationOfStay !== "" &&
          formValues.addictionInfo.eta !== null &&
          formValues.addictionInfo.clientSource !== ""
        );
      case 2:
        return (
          formValues.additionalInfo.projectedResidentialDischargeDate !==
            null &&
          formValues.additionalInfo.projectedProgramCompletionDate !== null
        );
      default:
        return false;
    }
  };

  const saveClient = async () => {
    showCustomToast(translate("DemoTestNoClient"), "info");
  };

  return (
    <div className="right-container-mobile">
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress={false} // Empêche d'afficher le numéro d'aide en cours
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles2}
      />
      <Modal
        open={openAddClientModal}
        onClose={handleCloseAddClientModal}
        aria-labelledby="add-client-modal-title"
        aria-describedby="add-client-modal-description"
        closeAfterTransition
      >
        <Slide direction="left" in={openAddClientModal}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: "2",
              pr: "2",
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 4,
                borderBottom: "1px solid lightgray",
              }}
            >
              <div
                id="add-client-modal-title"
                style={{
                  margin: 0,
                  paddingLeft: "30px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "18px",
                  fontWeight: "700",
                }}
              >
                {translate("AddNewClient")}
              </div>
              <div style={{ paddingRight: "15px" }}>
                <IconButton onClick={handleCloseAddClientModal}>
                  <CloseIcon />
                </IconButton>
              </div>
            </Box>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={label} completed={isStepCompleted(index)}>
                  <StepLabel
                    StepIconProps={{
                      style: {
                        color: isStepCompleted(index)
                          ? theme.palette.success.main
                          : "#d3d3d3",
                        // theme.palette.primary.main,
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <Box
              id="add-client-modal-description"
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                mt: 0,
                pl: "30px",
                pr: "30px",
              }}
            >
              {activeStep === 0 && (
                <div
                  style={{
                    height: "60%",
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      label={translate("FirstName")}
                      variant="outlined"
                      margin="normal"
                      value={formValues.personalInfo.firstName}
                      onChange={handleChange("personalInfo", "firstName")}
                      error={formErrors.personalInfo.firstName}
                      helperText={
                        formErrors.personalInfo.firstName
                          ? translate("FirstNameRequired")
                          : ""
                      }
                    />
                  </div>

                  <div style={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      label={translate("LastName")}
                      variant="outlined"
                      margin="normal"
                      value={formValues.personalInfo.lastName}
                      onChange={handleChange("personalInfo", "lastName")}
                      error={formErrors.personalInfo.lastName}
                      helperText={
                        formErrors.personalInfo.lastName
                          ? translate("LastNameRequired")
                          : ""
                      }
                    />
                  </div>

                  {/* <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  > */}
                  <div style={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      label={translate("Nickname")}
                      variant="outlined"
                      margin="normal"
                      value={formValues.addictionInfo.nickname}
                      onChange={handleChange("addictionInfo", "nickname")}
                      error={formErrors.addictionInfo.nickname}
                      helperText={
                        formErrors.addictionInfo.nickname
                          ? translate("NicknameRequired ")
                          : ""
                      }
                    />
                  </div>

                  <div style={{ width: "100%" }}>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Gender</InputLabel>
                      <Select
                        value={formValues.personalInfo.gender}
                        onChange={handleChange("personalInfo", "gender")}
                        error={formErrors.personalInfo.gender}
                      >
                        {gender.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.textDropDownEn}
                          >
                            {option.textDropDownEn}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {/* </div> */}

                  <div style={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      label={translate("Email")}
                      variant="outlined"
                      margin="normal"
                      value={formValues.personalInfo.personalEmail}
                      onChange={handleChange("personalInfo", "personalEmail")}
                      error={formErrors.personalInfo.personalEmail}
                      helperText={
                        formErrors.personalInfo.personalEmail
                          ? translate("EmailRequired")
                          : ""
                      }
                    />
                  </div>

                  <div style={{ width: "100%", paddingTop: "0px" }}>
                    <PhoneNumberInputNoTitleV2
                      value={formValues.personalInfo.phoneNumber}
                      onChange={handleChange("personalInfo", "phoneNumber")}
                      placeholder={translate("PhoneNumber")}
                      error={formErrors.personalInfo.phoneNumber}
                    />
                  </div>

                  <FormControl
                    fullWidth
                    margin="normal"
                    style={customStyles.formControl}
                  >
                    <InputLabel>{translate("Country")}</InputLabel>
                    <Select
                      value={formValues.personalInfo.country}
                      onChange={handleChange("personalInfo", "country")}
                      error={formErrors.personalInfo.country}
                    >
                      {countries.map((option) => (
                        <MenuItem key={option.id} value={option.textDropDownEn}>
                          {option.textDropDownEn}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              {activeStep === 1 && (
                <>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>{translate("PrimaryAddiction")}</InputLabel>
                        <Select
                          value={formValues.addictionInfo.primaryAddiction}
                          onChange={handleChange(
                            "addictionInfo",
                            "primaryAddiction"
                          )}
                          error={formErrors.addictionInfo.primaryAddiction}
                        >
                          {addictions.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.textDropDownEn}
                            >
                              {option.textDropDownEn}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div style={{ width: "100%" }}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>{translate("DurationOfStay")}</InputLabel>
                        <Select
                          value={formValues.addictionInfo.durationOfStay}
                          onChange={handleChange(
                            "addictionInfo",
                            "durationOfStay"
                          )}
                          error={formErrors.addictionInfo.durationOfStay}
                        >
                          {durationOfStay.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.textDropDownEn}
                            >
                              {option.textDropDownEn}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <TextField
                        fullWidth
                        label={translate("ETA")}
                        type="date"
                        variant="outlined"
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={formValues.addictionInfo.eta}
                        onChange={handleChange("addictionInfo", "eta")}
                        error={formErrors.addictionInfo.eta}
                        helperText={
                          formErrors.addictionInfo.eta
                            ? translate("ETARequired")
                            : ""
                        }
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>{translate("ClientSource")}</InputLabel>
                        <Select
                          value={formValues.addictionInfo.clientSource}
                          onChange={handleChange(
                            "addictionInfo",
                            "clientSource"
                          )}
                          error={formErrors.addictionInfo.clientSource}
                        >
                          {clientSource.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.textDropDownEn}
                            >
                              {option.textDropDownEn}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 2 && (
                <div style={{ marginTop: "20px" }}>
                  <TextField
                    fullWidth
                    label={translate("ProjectedResidentialDischargeDate")}
                    type="date"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      formValues.additionalInfo
                        .projectedResidentialDischargeDate
                    }
                    onChange={handleChange(
                      "additionalInfo",
                      "projectedResidentialDischargeDate"
                    )}
                    error={
                      formErrors.additionalInfo
                        .projectedResidentialDischargeDate
                    }
                    helperText={
                      formErrors.additionalInfo
                        .projectedResidentialDischargeDate
                        ? translate("ProjectedResidentialDischargeDateRequired")
                        : ""
                    }
                  />
                  <TextField
                    fullWidth
                    label={translate("ProjectedProgramCompletionDate")}
                    type="date"
                    variant="outlined"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={
                      formValues.additionalInfo.projectedProgramCompletionDate
                    }
                    onChange={handleChange(
                      "additionalInfo",
                      "projectedProgramCompletionDate"
                    )}
                    error={
                      formErrors.additionalInfo.projectedProgramCompletionDate
                    }
                    helperText={
                      formErrors.additionalInfo.projectedProgramCompletionDate
                        ? translate("ProjectedProgramCompletionDateRequired")
                        : ""
                    }
                  />
                  {/* <TextField
                      fullWidth
                      label="Additional Notes"
                      variant="outlined"
                      margin="normal"
                      value={formValues.additionalInfo.notes}
                      onChange={handleChange("additionalInfo", "notes")}
                      error={formErrors.additionalInfo.notes}
                      helperText={
                        formErrors.additionalInfo.notes
                          ? "Notes are required"
                          : ""
                      }
                    /> */}
                </div>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingBottom: "20px",
                paddingTop: "20px",
                borderTop: "1px solid lightgray",
              }}
            >
              <Button
                onClick={handleBack}
                variant="outlined"
                color="primary"
                disabled={activeStep === 0}
              >
                {translate("Back")}
              </Button>
              <Button onClick={handleNext} variant="contained" color="primary">
                {activeStep === steps.length - 1
                  ? translate("AddTheClient")
                  : translate("Next")}
              </Button>
            </Box>
          </Box>
        </Slide>
      </Modal>
      <Modal
        open={openAddClient}
        onClose={handleCloseAddClient}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          <AddClient closemodal={handleCloseAddClient} />
        </Box>
      </Modal>
      <Modal
        open={openAddQuickNote}
        onClose={handleCloseAddQuickNote}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalQuickNote}>
          <AddQuickNoteWithClients closemodal={handleCloseAddQuickNote} />
        </Box>
      </Modal>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          alignSelf: "center",
          marginTop: "15px",
          paddingLeft: "10px",
        }}
      >
        <div
          style={{
            width: "80%",
            marginTop: "0px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <SearchBarMobileV3 />
        </div>
        <div
          style={{
            marginLeft: "0px",
            width: "20%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <div style={{ width: "auto" }} onClick={handleOpenAddClientModal}>
            <AddButtonRoundSmallMobileV3 />
          </div>
        </div>
      </div>

      {/* <TopTitleBannerV2 title={"Dashboard"} /> */}

      {/* <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "10px",
          paddingRight: "30px",
        }}
      >
        <HelpOutlineIcon
          style={{
            cursor: "pointer",
            marginRight: "10px",
            color: "#a9a9a9",
          }}
          onClick={handleHelpClick}
        />
      </div> */}

      <div
        className="top-component-list-clients-mobile"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          {renderMetricsWidgets()}
        </div>

        <div
          style={{
            fontSize: "18px",
            fontWeight: "600",
            marginBottom: "10px",
            marginTop: "20px",
            paddingLeft: "15px",
          }}
        >
          {translate("LastClientNotes")}
        </div>
      </div>
      <div
        style={{
          // backgroundColor: "yellow",
          width: "100%",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "15px",
          overflow: "hidden",
          marginTop: "10px",
        }}
      >
        <div style={{ width: "100%", paddingRight: "20px" }}>
          <TitleNoteCompactMobile handleSort={handleSort} />
        </div>

        <div
          style={{
            width: "100%",
            maxHeight: "250px",
            paddingRight: "20px",
            // maxHeight: "50%",
            overflow: isLoadingNotes ? "hidden" : "auto",
            // backgroundColor: "blue"
          }}
        >
          {isLoadingNotes
            ? Array.from({ length: 5 }).map((_, index) => (
                <NotesContainerCompact key={index} isSkeleton={true} />
              ))
            : // : filteredNotes.map((note, index) => {
              filteredNotes.slice(0, visibleNotesCount).map((note, index) => {
                const dateOptions = {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                };

                const dateOptions2 = {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                };

                const timeOptions = {
                  hour: "2-digit",
                  minute: "2-digit",
                };

                const formattedTimestamp = note.createdAt
                  ? new Intl.DateTimeFormat("en-EN", dateOptions2).format(
                      note.createdAt.toDate()
                    )
                  : "";

                const formattedLastModification = note.updateAt
                  ? new Intl.DateTimeFormat("en-EN", dateOptions2).format(
                      note.updateAt.toDate()
                    )
                  : "";

                const formattedDate = note.effectiveDateTime
                  ? new Intl.DateTimeFormat("en-EN", dateOptions).format(
                      note.effectiveDateTime.toDate()
                    )
                  : "";

                const formattedTime = note.effectiveDateTime
                  ? new Intl.DateTimeFormat("en-EN", timeOptions).format(
                      note.effectiveDateTime.toDate()
                    )
                  : "";

                const noteDate = formatDate(note.shiftStart, dateOptions);
                const noteTime = formatDate(note.shiftStart, timeOptions); // Assurez-vous que c'est bien le champ que vous voulez utiliser
                const noteDateEnd = formatDate(note.shiftEnd, dateOptions);
                const noteDateEndTime = formatDate(note.shiftEnd, timeOptions);

                switch (note.noteType) {
                  case "quickNote":
                  case "nonTherapyNote":
                  case "therapyNote":
                  case "supportStaffNote":
                  case "aftercareNote":
                    return (
                      <NotesContainerCompactMobile
                        key={note.id}
                        noteContent={note.noteContent}
                        effectiveDate={note.effectiveDateTime}
                        noteDate={formattedDate}
                        noteTime={formattedTime}
                        doneBy={note.doneBy}
                        timeStamp={formattedTimestamp}
                        lastModification={formattedLastModification}
                        clientPic={note.clientProfilePicture}
                        clientFirstName={note.clientFirstName}
                        clientLastName={note.clientLastName}
                        noteId={note.id}
                        {...note}
                      />
                    );

                  case "endOfShift":
                    return (
                      <NotesContainerCompactMobile
                        key={note.id}
                        noteContent={note.noteContent}
                        shiftStartRaw={note.shiftStart}
                        shiftEndRaw={note.shiftEnd}
                        shiftStart={noteDate}
                        shiftEnd={noteDateEnd}
                        staff={note.staff}
                        doneBy={note.doneBy}
                        noteId={note.id}
                        noteType={note.noteType}
                        noteDate={noteDate} // Date formatée
                        noteTime={noteTime} // Heure formatée
                        noteDateEndTime={noteDateEndTime}
                      />
                    );

                  case "familyNote":
                    return (
                      <NotesContainerCompactMobile
                        key={note.id}
                        noteContent={note.noteContent}
                        effectiveDate={note.effectiveDateTime}
                        noteDate={formattedDate}
                        noteTime={formattedTime}
                        doneBy={note.doneBy}
                        timeStamp={formattedTimestamp}
                        lastModification={formattedLastModification}
                        clientPic={note.clientProfilePicture}
                        clientFirstName={note.clientFirstName}
                        clientLastName={note.clientLastName}
                        noteId={note.id}
                        relationship={note.relationShipFamilyNote}
                        fileUrls={note.fileUrls}
                        {...note}
                      />
                    );

                  case "medicalNote":
                    return (
                      <NotesContainerCompactMobile
                        key={note.id}
                        noteContent={note.noteContent}
                        effectiveDate={note.effectiveDateTime}
                        noteDate={formattedDate}
                        noteTime={formattedTime}
                        doneBy={note.doneBy}
                        timeStamp={formattedTimestamp}
                        lastModification={formattedLastModification}
                        clientPic={note.clientProfilePicture}
                        clientFirstName={note.clientFirstName}
                        clientLastName={note.clientLastName}
                        noteId={note.id}
                        fileUrls={note.fileUrls}
                        {...note}
                      />
                    );
                  case "handoverNote":
                    return (
                      <NotesContainerCompactMobile
                        key={note.id}
                        // noteContent={note.noteContent}
                        effectiveDateTime={note.effectiveDateTime}
                        noteDate={formattedDate}
                        noteTime={formattedTime}
                        doneBy={note.doneBy}
                        timeStamp={formattedTimestamp}
                        lastModification={formattedLastModification}
                        noteId={note.id}
                        immmigrationAppointment={note.immmigrationAppointment}
                        dischargesSwot={note.dischargesSwot}
                        newClients={note.newClients}
                        medicalInfos={note.medicalInfos}
                        hospitalAppointment={note.hospitalAppointment}
                        residentialClientMovements={
                          note.residentialClientMovements
                        }
                        zoomSessionAssistance={note.zoomSessionAssistance}
                        updateAt={note.updateAt}
                        createdAt={note.createdAt}
                        staffToday={note.staffToday}
                        generalAnnouncement={note.generalAnnouncement}
                        noteContent={note.generalAnnouncement}
                        {...note}
                      />
                    );

                  case "groupNote":
                    return (
                      <NotesContainerCompactMobile
                        key={note.id}
                        noteContent={note.noteContent}
                        effectiveDateTime={note.effectiveDateTime}
                        noteDate={formattedDate}
                        noteTime={formattedTime}
                        doneBy={note.doneBy}
                        timeStamp={formattedTimestamp}
                        lastModification={formattedLastModification}
                        clientPic={note.clientProfilePicture}
                        clientFirstName={note.clientFirstName}
                        clientLastName={note.clientLastName}
                        noteId={note.id}
                        groupTopic={note.groupTopic}
                        groupType={note.typeGroupNote}
                        notInAttendance={note.notInAttendance}
                        updateAt={note.updateAt}
                        createdAt={note.createdAt}
                        groupStartDateTime={note.groupStartDateTime}
                        {...note}
                      />
                    );

                  default:
                    return null;
                }
              })}
        </div>

        {/* <div
          style={{
            width: "20%",
            // backgroundColor: "red",
            display: "flex",
            justifyContent: "flex-start",
            alignContent: "center",
            paddingTop: "30px",
            alignItems: "center",
            flexDirection: "column",
          }}
        ></div> */}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingLeft: "14px",
          paddingRight: "20px",
          justifyContent: "space-between",
        }}
      >
        {!isLoadingNotes && notes.length > visibleNotesCount && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
              paddingLeft: "0px",
            }}
          >
            <Button
              onClick={handleShowMoreNotes}
              variant="outlined"
              style={{
                padding: "5px 10px",
                fontSize: "10px",
                cursor: "pointer",
              }}
            >
              {translate("ShowMoreNotes")}
            </Button>
          </div>
        )}

        {!isLoadingNotes && visibleNotesCount > 7 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "10px",
              paddingLeft: "0px",
            }}
          >
            <Button
              onClick={handleShowLessNotes}
              variant="outlined"
              style={{
                padding: "5px 10px",
                fontSize: "10px",
                cursor: "pointer",
              }}
            >
              {translate("ShowLessNotes")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
