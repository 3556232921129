import React from "react";
import SmallClientChip from "../SmallClientChip";
import { format, fromUnixTime } from "date-fns";
import { Box, Modal } from "@mui/material";
import { useState } from "react";
import CheckWithDateMobile from "../CheckWithDateMobile";
import EditSwotLineMobile from "../forms/EditSwotLineMobile";

export default function LineSwotMobile(props) {
  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: 500,
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const [openSwotLine, setOpenSwotLine] = useState(false);

  const handleOpenSwotLine = () => {
    setOpenSwotLine(true);
  };

  const handleCloseSwotLine = () => {
    setOpenSwotLine(false);
  };

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) {
      return "Not filled";
    }

    const date = fromUnixTime(timestamp.seconds);
    return format(date, "dd MMM yyyy");
  }

  return (
    <div
      className={`line-swot line ${
        props.background === "white"
          ? "line-swot-bckgrd-white"
          : "line-swot-bckgrd-grey"
      }`}
    >
      <div
        onClick={handleOpenSwotLine}
        style={{ display: "flex", width: "100%", cursor: "pointer" }}
        title="Edit Swot"
      >
        <div
          className="text-item-swot-table title7-mobile-swot"
          id="title1-swot"
        >
          <SmallClientChip
            clientName={`${props.firstName} ${props.lastName}`}
            firstName={props.firstName}
            lastName={props.lastName}
            clientPic={props.avatar}
          />
        </div>
        <div
          className="text-item-swot-table title8-mobile-swot"
          id="title4-swot"
        >
          {formatDate(props.dischargeDate)}
        </div>
        <div
          className="text-item-swot-table title9-mobile-swot"
          id="title5-swot"
        >
          <CheckWithDateMobile
            checked={props.swotChecked}
            content={formatDate(props.dateSwot)}
          />
        </div>
      </div>

      <Modal
        open={openSwotLine}
        onClose={handleCloseSwotLine}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          {/* Le composant EditRole sera créé à l'étape suivante */}
          <EditSwotLineMobile
            intakeDate={props.admissionDate}
            dos={props.dos}
            closemodal={handleCloseSwotLine}
            name={`${props.firstName} ${props.lastName}`}
            dischargeDate={props.dischargeDate}
            swotDone={props.swotDone}
            swot={props.dateSwot}
            flightDate={props.flightDate}
            nameStaff={props.nameStaff}
            nameCTS={props.nameCTS}
            mah={props.mAtHome}
            clientId={props.clientId}
            nameRecoveryCoach={props.nameRecoveryCoach}
          />
        </Box>
      </Modal>
    </div>
  );
}
