import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import DropDownComponent from "../DropDownComponent";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { showCustomToast } from "../Misc/CustomToast";
import UploadedFilesComponent from "../Updloader/UploadedFilesComponent";
import UploaderDocForNote from "../Updloader/UploaderDocForNote";

function EditFamilyNote(props) {
  const { currentUser } = useAuth();
  const [quickNoteText, setQuickNoteText] = useState(props.note);
  const [effectiveDateTime, setEffectiveDateTime] = useState(
    props.effectiveDate
  );
  const [isLoading, setIsLoading] = useState(false);
  const [fileLinks, setFileLinks] = useState(props.fileLinks || []);
  const [relationshipFamilyNote, setRelationshipFamilyNote] = useState(
    props.relationshipFamilyNote || ""
  );
  const [files, setFiles] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);

  const handleDateTimeChange = (event) => {
    setEffectiveDateTime(event);
    setHasChanged(true);
  };

  const handleRelationshipFamilyNote = (event) => {
    setRelationshipFamilyNote(event);
    setHasChanged(true);
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length <= 10) {
      setFiles(event.target.files);
      setHasChanged(true);
    } else {
      showCustomToast("You can only upload up to 10 files.", "error");
    }
  };

  const deleteFile = async (fileUrl, fileName, noteId) => {
    const storage = getStorage();
    const filePath = `Documents/familyNotes/${noteId}/${fileName}`;
    const storageRef = ref(storage, filePath);

    try {
      await deleteObject(storageRef);
      const newFileLinks = fileLinks.filter((file) => file.url !== fileUrl);
      setFileLinks(newFileLinks);
      const noteRef = doc(db, "notes", noteId);

      // Modifier cette ligne pour inclure les noms des fichiers
      await updateDoc(noteRef, {
        fileUrls: newFileLinks.map((file) => ({
          url: file.url,
          name: file.name,
        })),
      });

      showCustomToast("File successfully deleted!", "success");
    } catch (error) {
      console.log("Error deleting file:", error);
      showCustomToast("Error deleting file", "error");
    }
  };

  const handleUpdateQuickNote = async (
    note,
    noteId,
    effectiveDateTime,
    relationship,
    newFiles
  ) => {
    if (quickNoteText && effectiveDateTime && relationshipFamilyNote) {
      setIsLoading(true);
      try {
        const noteRef = doc(db, "notes", noteId);
        const newFileUrls = await uploadFilesAndGetUrls(noteId, newFiles);
        const updatedFileLinks = [...fileLinks, ...newFileUrls];

        const noteData = {
          noteContent: note,
          updateAt: serverTimestamp(),
          doneBy: currentUser.displayName,
          effectiveDateTime: effectiveDateTime,
          relationShipFamilyNote: relationship,
          fileUrls: updatedFileLinks,
        };

        await updateDoc(noteRef, noteData);

        showCustomToast(`Note successfully updated!`, "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.log("error updating note :", error);
        showCustomToast(`Problem when updating note`, "error");

        setIsLoading(false);
      }
    } else {
      showCustomToast(
        `Some mandatory fields are empty. Please check.`,
        "error"
      );
    }
  };

  const uploadFilesAndGetUrls = async (noteId, files) => {
    const storage = getStorage();
    const uploadedFileUrls = [];

    for (const file of files) {
      const filePath = `Documents/familyNotes/${noteId}/${file.name}`;
      const storageRef = ref(storage, filePath);

      await uploadBytes(storageRef, file);
      const fileUrl = await getDownloadURL(storageRef);
      uploadedFileUrls.push({ url: fileUrl, name: file.name });
    }

    return uploadedFileUrls;
  };

  const handleChange = (event) => {
    setQuickNoteText(event.target.value);
    setHasChanged(true);
  };

  return (
    <>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <div className="modal-title">
            Edit Family Note{" "}
            {/* <span style={{ fontSize: "10px" }}>(note Id: {props.noteId})</span> */}
          </div>

          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "0px",
            height: "75%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div className="line2-column-modal">
            <div className="label-textfield">Note content</div>
            <TextField
              name="quickNote"
              value={quickNoteText}
              onChange={handleChange}
              style={{
                width: "95%",
                borderRadius: "5px",
                backgroundColor: "#f2eeef",
              }}
              placeholder="Type quick session note here"
              multiline
              rows={14}
            />
          </div>

          <div className="line3-modal-mobile">
            <DatePickerWithTimeComponent
              title="Effective date and time"
              value={effectiveDateTime}
              onChange={handleDateTimeChange}
            />
            <DropDownComponent
              title="Family Note Relationship"
              api="livingWith"
              value={relationshipFamilyNote}
              onChange={handleRelationshipFamilyNote}
            />
          </div>

          <div className="main-upload-container ">
            <UploaderDocForNote
              handleFileChange={handleFileChange}
              files={files}
            />

            <UploadedFilesComponent
              fileLinks={fileLinks}
              deleteFile={deleteFile}
              noteId={props.noteId}
            />
          </div>
        </div>
        <div className="line4-modal-percent">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={
                !hasChanged // si aucune modification n'a été effectuée
                  ? {
                      backgroundColor: "lightgray", // bouton est gris clair
                      color: "black",
                      marginLeft: "15px",
                    }
                  : isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={!hasChanged || isLoading}
              onClick={() =>
                handleUpdateQuickNote(
                  quickNoteText,
                  props.noteId,
                  effectiveDateTime,
                  relationshipFamilyNote,
                  files
                )
              }
            >
              {isLoading ? "UPDATING NOTE..." : "UPDATE NOTE"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default EditFamilyNote;
