import React, { useRef, useState } from "react";
import { Button, Checkbox, CircularProgress } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { getDoc, doc, collection } from "firebase/firestore";
import { useEffect } from "react";
import { db } from "../../firebase";
import DatePickerComponent from "../DatePickerComponent";
import ReactToPrint from "react-to-print";
import TopTitleFormComponentMobileV2 from "./TopTitleFormComponentMobileV2";

function ViewAcaReportMobileV2(props) {
  //Tous les states des données récupérées par client

  const clientIdState = props.clientId;
  const [isLoading, setIsLoading] = useState(false);
  const [, setIsImageLoaded] = useState(false);
  const [consentCheckbox, setConsentCheckbox] = useState(false);
  const [nonRefundableCheckbox, setNonRefundableCheckbox] = useState(false);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [text1, setText1] = useState("");
  const [text2A, setText2A] = useState("");
  const [text2B, setText2B] = useState("");
  const [text2C, setText2C] = useState("");
  const [text2D, setText2D] = useState("");
  const [text2E, setText2E] = useState("");
  const [text3, setText3] = useState("");

  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);
  const [date3, setDate3] = useState(null);
  const [date4, setDate4] = useState(null);
  const printRef = useRef();
  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  function formatDate(timestamp) {
    const date = timestamp.toDate();
    const day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    // Ajout du fuseau horaire
    const timeZone = date
      .toLocaleString("en", { timeZoneName: "short" })
      .split(" ")
      .pop();

    return `${day} ${month} ${year} ${hours}:${minutes} ${timeZone}`;
  }

  useEffect(() => {
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDatas = async () => {
    setIsLoading(true);

    try {
      const dischargeFormsRef = collection(db, "acaReport");
      const dischargeFormDocRef = doc(dischargeFormsRef, clientIdState);
      const dischargeDataSnapshot = await getDoc(dischargeFormDocRef);

      if (dischargeDataSnapshot.exists()) {
        const dischargeData = dischargeDataSnapshot.data();
        setDate1(dischargeData.date1);
        setDate2(dischargeData.date2);
        setDate3(dischargeData.date3);
        setDate4(dischargeData.date4);
        setConsentCheckbox(dischargeData.consentCheckbox);
        setNonRefundableCheckbox(dischargeData.nonRefundableCheckbox);
        setText1(dischargeData.text1);
        setText2A(dischargeData.text2A);
        setText2B(dischargeData.text2B);
        setText2C(dischargeData.text2C);
        setText2D(dischargeData.text2D);
        setText2E(dischargeData.text2E);
        setText3(dischargeData.text3);

        // Fetch the signature URL and update the state
        const signatureUrl = dischargeData.signatureData;
        setSignatureDataURL(signatureUrl);
      } else {
        console.error("No data found for the specified client ID.");
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error retrieving data:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <style>
        {`
          @media print {
            #printableContent {
              height: auto !important;
              overflow-y: visible !important;
            }
          }
        `}
      </style>

      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div
          className="line1-modal-percent"
          style={{ borderBottom: "1px solid lightgrey" }}
        >
          <TopTitleFormComponentMobileV2
            title="Leave against clinical advice (ACA) form"
            closemodal={props.closemodal}
          />
        </div>

        <div
          id="printableContent"
          style={{
            width: "100%",
            marginTop: "10px",
            height: "75%",
            overflowY: "scroll",
            // overflowX: "hidden",
          }}
          ref={printRef}
        >
          {isLoading ? (
            <div className="circular-progress-container">
              <CircularProgress />
              <div className="loading-text">Loading, please wait...</div>
            </div>
          ) : (
            <>
              <div className="tab-form-container">
                <div
                  className="client-name-container"
                  style={{ fontSize: "16px" }}
                >
                  Leave against clinical advice form for: {props.firstName}{" "}
                  {props.lastName}
                </div>
                <div
                  className="checkbox-container-margin"
                  style={{ fontSize: "14px", paddingRight: "20px" }}
                >
                  <Checkbox checked={consentCheckbox} />
                  <div className="block-text-check">{text1}</div>
                </div>
              </div>
              <div
                className="tab-form-container"
                style={{ flexDirection: "column", width: "100%" }}
              >
                <div style={{ marginLeft: 20, flexDirection: "column" }}>
                  <div
                    className="block-text-check-mobile-v2"
                    style={{
                      textAlign: "left",
                      width: "100%",
                      paddingRight: "10px",
                      fontSize: "14px",
                      paddingLeft: "25px",
                    }}
                  >
                    {text2A}
                  </div>
                  <div style={{ width: "100%", paddingRight: "20px" }}>
                    <DatePickerComponent
                      // title="Date of client’s admission"
                      width="100%"
                      isRequired={true}
                      value={date1}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: 20, flexDirection: "column" }}>
                  <div
                    className="block-text-check-mobile-v2"
                    style={{
                      textAlign: "left",
                      width: "100%",
                      paddingRight: "10px",
                      fontSize: "14px",
                      paddingLeft: "25px",
                    }}
                  >
                    {text2B}
                  </div>
                  <div style={{ width: "100%" }}>
                    <DatePickerComponent
                      // title="Date of client’s admission"
                      width="150px"
                      isRequired={true}
                      value={date2}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: 20, flexDirection: "column" }}>
                  <div
                    className="block-text-check-mobile-v2"
                    style={{
                      textAlign: "left",
                      width: "100%",
                      paddingRight: "10px",
                      fontSize: "14px",
                      paddingLeft: "25px",
                    }}
                  >
                    {text2C}
                  </div>
                  <div style={{ width: "100%" }}>
                    <DatePickerComponent
                      // title="Date of client’s admission"
                      width="150px"
                      isRequired={true}
                      value={date3}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: 20, flexDirection: "column" }}>
                  <div
                    className="block-text-check-mobile-v2"
                    style={{
                      textAlign: "left",
                      width: "100%",
                      paddingRight: "10px",
                      fontSize: "14px",
                      paddingLeft: "25px",
                    }}
                  >
                    {text2D}
                  </div>
                  <div style={{ width: "100%" }}>
                    <DatePickerComponent
                      // title="Date of client’s admission"
                      width="150px"
                      isRequired={true}
                      value={date4}
                    />
                  </div>
                </div>
                <div style={{ marginLeft: 20, flexDirection: "column" }}>
                  <div
                    className="block-text-check-mobile-v2"
                    style={{
                      textAlign: "left",
                      width: "100%",
                      paddingRight: "10px",
                      fontSize: "14px",
                      paddingLeft: "25px",
                    }}
                  >
                    {text2E}
                  </div>
                </div>
              </div>

              <div className="tab-form-container">
                <div className="checkbox-container-margin">
                  <Checkbox checked={nonRefundableCheckbox} />
                  <div className="block-text-check-mobile-v2">{text3}</div>
                </div>
              </div>
              <div className="signature-date-container">
                <div className="text-block-2">
                  Timestamp signature : {formatDate(props.acaReportDateSigned)}{" "}
                </div>
                <div className="signature-title">
                  Client signature (
                  <span style={{ fontWeight: "bold" }}>
                    {props.firstName} {props.lastName}
                  </span>
                  ):
                </div>
                <img
                  className="signature-pic-roi"
                  src={signatureDataURL}
                  onLoad={handleImageLoad}
                  alt="Signature"
                />
              </div>
            </>
          )}
        </div>

        <div className="line4-modal-percent">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              onClick={props.closemodal}
            >
              Cancel
            </Button>

            <ReactToPrint
              trigger={() => (
                <Button variant="contained" style={{ marginLeft: "20px" }}>
                  Print
                </Button>
              )}
              content={() => printRef.current}
              onAfterPrint={props.closemodal}
            />
          </>
        </div>
      </div>
    </>
  );
}

export default ViewAcaReportMobileV2;
