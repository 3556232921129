import React from "react";

function FormLineMedium6Mobile(props) {
  return (
    <div
      className={
        props.color === "1"
          ? "formLineMedium6 light-grey-form"
          : "formLineMedium6 grey-form"
      }
    >
      <div className="role-line-block1-bis">{props.userName}</div>
      <div className="role-line-block1-tris">{props.userPhone}</div>
    </div>
  );
}

export default FormLineMedium6Mobile;
