import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";

function TitleNoteCompactClientMobile(props) {
  const { translate } = useContext(LanguageContext);

  return (
    <div style={{ paddingLeft: "0px" }}>
      <div
        style={{
          width: "100%",
          height: "30px",
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid lightgrey",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "40%",
              height: "15px",
              display: "flex",
              color: "#a3a3a4",
              textAlign: "center",
              fontWeight: "500",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
              fontSize: "10px",
            }}
          >
            {translate("ClientC")}
          </div>

          <div
            style={{
              width: "20%",
              height: "15px",
              display: "flex",
              color: "#a3a3a4",
              textAlign: "center",
              fontWeight: "500",
              justifyContent: "center",
              fontSize: "10px",

              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {translate("ADDICTION")}
          </div>

          <div
            style={{
              width: "20%",
              height: "15px",
              display: "flex",
              color: "#a3a3a4",
              alignItems: "center",
              fontWeight: "500",
              fontSize: "10px",
              justifyContent: "center",

              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {translate("ROOM")}
          </div>
          <div
            style={{
              // backgroundColor: "lightcoral",
              width: "20%",
              height: "15px",
              display: "flex",
              color: "#a3a3a4",
              alignItems: "center",
              fontWeight: "500",

              fontSize: "10px",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            {translate("Alerts")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TitleNoteCompactClientMobile;
