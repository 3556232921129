import React, { useContext, useRef, useState } from "react";
import { Button } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import TopTitleFormComponent from "./TopTitleFormComponent";
import { getDoc, doc } from "firebase/firestore";
import { useEffect } from "react";
import { db } from "../../firebase";
import ReactToPrint from "react-to-print";
import { LanguageContext } from "../../context/LanguageContext";
import TextFieldComponentV2 from "../TextFieldComponentV2";

function ViewTreatmentPlan(props) {
  const { translate } = useContext(LanguageContext);
  const [, setSignaturePic] = useState(null);
  const printRef = useRef();

  const presentingIssues = props.presentingIssues || "";
  const longTermGoals = props.longTermGoals || "";

  const objectives = [props.objectives] || [];

  const fetchSignatureUrl = async () => {
    const docRef = doc(db, "AgressionControlPolicy", props.clientId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const signatureUrl = docSnap.data().signatureURL;
      setSignaturePic(signatureUrl);
    } else {
      console.log("No such document (signaturePic ref)!");
    }
  };

  useEffect(() => {
    fetchSignatureUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <style>
        {`
          @media print {
            #printableContent {
              height: auto !important;
              overflow-y: visible !important;
            }
          }
        `}
      </style>

      <div style={{ width: "auto", height: "100%" }}>
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title={translate("TreatmentPlan")}
            closemodal={props.closemodal}
          />
        </div>

        <div
          id="printableContent"
          style={{
            width: "100%",
            marginTop: "4px",
            height: "80%",
            overflowY: "scroll",
            // overflowX: "hidden",
          }}
          ref={printRef}
          // style={{ marginTop: "60px", height: "500px", overflowY: "scroll" }}
          // style={{ marginTop: "60px", height: "auto", overflowY: "scroll" }}
        >
          <div className="client-name-container">
            <span
              style={{
                fontWeight: "600",
                paddingLeft: "10px",
                fontSize: "16px",
              }}
            >
              {translate("TreatmentPlanFor")}
            </span>{" "}
            {props.firstName} {props.lastName}
          </div>
          <br />

          <div style={{ marginLeft: 35 }}>
            <TextFieldComponentV2
              title={translate("PresentingIssues")}
              isRequired={true}
              placeholder="type here"
              value={presentingIssues}
              width="500px"
            />

            <TextFieldComponentV2
              title={translate("LongTermGoals")}
              isRequired={true}
              value={longTermGoals}
              placeholder="Format:
          Goal 1:
          Goal 2:
          Goal 3:
          Etc."
              width="500px"
            />
          </div>

          <div className="SubTitleBold">
            <span
              style={{
                fontWeight: "600",
                paddingLeft: "20px",
                paddingTop: "10px",
                fontSize: "16px",
              }}
            >
              {translate("ObjectivesAssociatedInterventions")}
            </span>
          </div>

          <div className="objectives-list" style={{ paddingLeft: "35px" }}>
            {objectives[0].map((objective, index) => (
              <div key={index} className="objective-item">
                <div className="title-objective-treatment">
                  {translate("Objective")} {index + 1}:
                </div>
                <div
                  className="text-container"
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    backgroundColor: "rgb(237, 237, 237)",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    borderRadius: "5px",
                    width: "100%",
                  }}
                >
                  {objective.objective}
                </div>
                <div className="title-objective-treatment">
                  {translate("Intervention")} {index + 1}:
                </div>
                <div
                  className="text-container"
                  style={{
                    marginTop: "0px",
                    marginBottom: "10px",
                    backgroundColor: "rgb(237, 237, 237)",
                    paddingLeft: "4px",
                    paddingRight: "4px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                    borderRadius: "5px",
                    width: "100%",
                  }}
                >
                  {objective.intervention}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              onClick={props.closemodal}
            >
              {translate("Cancel")}
            </Button>

            <ReactToPrint
              trigger={() => (
                <Button variant="contained" style={{ marginLeft: "20px" }}>
                  {translate("Print")}
                </Button>
              )}
              content={() => printRef.current}
              onAfterPrint={props.closemodal}
            />
          </>
        </div>
      </div>
    </>
  );
}

export default ViewTreatmentPlan;
