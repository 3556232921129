import React, { useState } from "react";
import {
  Box,
  Button,
  createTheme,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Slide,
  Step,
  StepLabel,
  Stepper,
  TextField,
  ThemeProvider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import gender from "../../api/gender";
import countries from "../../api/countries";
import clientSource from "../../api/clientSource";
import addictions from "../../api/addictions";
import durationOfStay from "../../api/durationOfStay";
import PhoneNumberInputNoTitleV2 from "../PhoneNumberInputNoTitleV2";
import SearchBarMobileV3 from "../SearchBarMobileV3";
import AddButtonRoundSmallMobileV3 from "../Buttons/AddButtonRoundSmallMobileV3";
import { showCustomToast } from "./CustomToast";

const SearchAndAddClientModal = ({ translate }) => {
  const [openAddClientModal, setOpenAddClientModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0); // Slider steps
  const [formValues, setFormValues] = useState({
    personalInfo: {
      firstName: "",
      lastName: "",
      gender: "",
      personalEmail: "",
      phoneNumber: "",
      country: "",
    },
    addictionInfo: {
      primaryAddiction: "",
      durationOfStay: "",
      eta: null,
      nickname: "",
      clientSource: "",
    },
    additionalInfo: {
      projectedResidentialDischargeDate: null,
      projectedProgramCompletionDate: null,
      notes: "",
    },
  });

  const [formErrors, setFormErrors] = useState({
    personalInfo: {
      firstName: false,
      lastName: false,
      gender: false,
      personalEmail: false,
      phoneNumber: false,
      country: false,
    },
    addictionInfo: {
      primaryAddiction: false,
      durationOfStay: false,
      eta: false,
      nickname: false,
      clientSource: false,
    },
    additionalInfo: {
      projectedResidentialDischargeDate: false,
      projectedProgramCompletionDate: false,
      notes: false,
    },
  });

  const handleOpenAddClientModal = () => {
    resetForm();
    setOpenAddClientModal(true);
  };
  const handleCloseAddClientModal = () => {
    resetForm();
    setOpenAddClientModal(false);
  };

  const steps = [
    translate("PersonalInfos"),
    translate("AddictionInfos"),
    translate("DischargeInfos"),
  ];

  const theme = createTheme();

  const resetForm = () => {
    setFormValues({
      personalInfo: {
        firstName: "",
        lastName: "",
        gender: "",
        personalEmail: "",
        phoneNumber: "",
        country: "",
      },
      addictionInfo: {
        primaryAddiction: "",
        durationOfStay: "",
        eta: null,
        nickname: "",
        clientSource: "",
      },
      additionalInfo: {
        projectedResidentialDischargeDate: null,
        projectedProgramCompletionDate: null,
        notes: "",
      },
    });
    setActiveStep(0);
  };

  const handleChange = (step, field) => (event) => {
    const value = event && event.target ? event.target.value : event; // handle both event.target.value and direct value from PhoneInput
    setFormValues({
      ...formValues,
      [step]: { ...formValues[step], [field]: value },
    });
  };

  const validateForm = () => {
    const newFormErrors = { ...formErrors };
    let isValid = true;

    switch (activeStep) {
      case 0:
        // Validation des champs de l'étape des informations personnelles
        newFormErrors.personalInfo.firstName =
          formValues.personalInfo.firstName === "";
        newFormErrors.personalInfo.lastName =
          formValues.personalInfo.lastName === "";
        newFormErrors.personalInfo.gender =
          formValues.personalInfo.gender === "";
        newFormErrors.personalInfo.personalEmail =
          formValues.personalInfo.personalEmail === "";
        newFormErrors.personalInfo.phoneNumber =
          formValues.personalInfo.phoneNumber === "";
        newFormErrors.personalInfo.country =
          formValues.personalInfo.country === "";

        // Vérifier si tous les champs sont valides
        isValid =
          !newFormErrors.personalInfo.firstName &&
          !newFormErrors.personalInfo.lastName &&
          !newFormErrors.personalInfo.gender &&
          !newFormErrors.personalInfo.personalEmail &&
          !newFormErrors.personalInfo.phoneNumber &&
          !newFormErrors.personalInfo.country;
        break;

      case 1:
        // Validation des champs de l'étape des informations d'addiction
        newFormErrors.addictionInfo.primaryAddiction =
          formValues.addictionInfo.primaryAddiction === "";
        newFormErrors.addictionInfo.durationOfStay =
          formValues.addictionInfo.durationOfStay === "";
        newFormErrors.addictionInfo.eta = formValues.addictionInfo.eta === null;
        newFormErrors.addictionInfo.clientSource =
          formValues.addictionInfo.clientSource === "";

        // Vérifier si tous les champs sont valides
        isValid =
          !newFormErrors.addictionInfo.primaryAddiction &&
          !newFormErrors.addictionInfo.durationOfStay &&
          !newFormErrors.addictionInfo.eta &&
          !newFormErrors.addictionInfo.clientSource;
        break;

      case 2:
        // Validation des champs de l'étape des informations de sortie
        newFormErrors.additionalInfo.projectedResidentialDischargeDate =
          formValues.additionalInfo.projectedResidentialDischargeDate === null;
        newFormErrors.additionalInfo.projectedProgramCompletionDate =
          formValues.additionalInfo.projectedProgramCompletionDate === null;

        // Vérifier si tous les champs sont valides
        isValid =
          !newFormErrors.additionalInfo.projectedResidentialDischargeDate &&
          !newFormErrors.additionalInfo.projectedProgramCompletionDate;
        break;

      default:
        break;
    }

    setFormErrors(newFormErrors);

    if (!isValid) {
      showCustomToast("Please fill all required fields", "error");
    }

    return isValid;
  };

  const handleNext = () => {
    if (validateForm()) {
      if (activeStep === steps.length - 1) {
        saveClient();
        handleCloseAddClientModal();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      showCustomToast("Please fill all required fields", "error");
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const saveClient = () => {
    showCustomToast(translate("DemoTestNoClient"), "info");
  };

  const isStepCompleted = (step) => {
    switch (step) {
      case 0:
        return (
          formValues.personalInfo.firstName !== "" &&
          formValues.personalInfo.lastName !== "" &&
          formValues.personalInfo.gender !== "" &&
          formValues.personalInfo.personalEmail !== "" &&
          formValues.personalInfo.phoneNumber !== "" &&
          formValues.personalInfo.country !== ""
        );
      case 1:
        return (
          formValues.addictionInfo.primaryAddiction !== "" &&
          formValues.addictionInfo.durationOfStay !== "" &&
          formValues.addictionInfo.eta !== null &&
          formValues.addictionInfo.clientSource !== ""
        );
      case 2:
        return (
          formValues.additionalInfo.projectedResidentialDischargeDate !==
            null &&
          formValues.additionalInfo.projectedProgramCompletionDate !== null
        );
      default:
        return false;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* Search bar and Add Button */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            alignSelf: "center",
            marginTop: "15px",
            paddingLeft: "10px",
          }}
        >
          <div
            style={{
              width: "80%",
              marginTop: "0px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <SearchBarMobileV3 />
          </div>
          <div
            style={{
              marginLeft: "0px",
              width: "20%",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <div style={{ width: "auto" }} onClick={handleOpenAddClientModal}>
              <AddButtonRoundSmallMobileV3 />
            </div>
          </div>
        </div>

        {/* Add Client Modal */}
        <Modal
          open={openAddClientModal}
          onClose={handleCloseAddClientModal}
          aria-labelledby="add-client-modal-title"
          aria-describedby="add-client-modal-description"
          closeAfterTransition
        >
          <Slide direction="left" in={openAddClientModal}>
            <Box
              sx={{
                position: "fixed",
                top: "5%",
                right: 0,
                width: "90%",
                height: "90%",
                bgcolor: "background.paper",
                boxShadow: 24,
                marginRight: "15px",
                pl: "2",
                pr: "2",
                borderRadius: "15px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 4,
                  borderBottom: "1px solid lightgray",
                }}
              >
                <div
                  id="add-client-modal-title"
                  style={{
                    margin: 0,
                    paddingLeft: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontSize: "18px",
                    fontWeight: "700",
                  }}
                >
                  {translate("AddNewClient")}
                </div>
                <div style={{ paddingRight: "15px" }}>
                  <IconButton onClick={handleCloseAddClientModal}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </Box>

              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} completed={isStepCompleted(index)}>
                    <StepLabel
                      StepIconProps={{
                        style: {
                          color: isStepCompleted(index)
                            ? theme.palette.success.main
                            : "#d3d3d3",
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>

              <Box
                id="add-client-modal-description"
                sx={{
                  flexGrow: 1,
                  overflowY: "auto",
                  mt: 0,
                  pl: "30px",
                  pr: "30px",
                }}
              >
                {activeStep === 0 && (
                  <>
                    <TextField
                      fullWidth
                      label={translate("FirstName")}
                      variant="outlined"
                      margin="normal"
                      value={formValues.personalInfo.firstName}
                      onChange={handleChange("personalInfo", "firstName")}
                      error={formErrors.personalInfo.firstName}
                      helperText={
                        formErrors.personalInfo.firstName
                          ? translate("FirstNameRequired")
                          : ""
                      }
                    />
                    <TextField
                      fullWidth
                      label={translate("LastName")}
                      variant="outlined"
                      margin="normal"
                      value={formValues.personalInfo.lastName}
                      onChange={handleChange("personalInfo", "lastName")}
                      error={formErrors.personalInfo.lastName}
                      helperText={
                        formErrors.personalInfo.lastName
                          ? translate("LastNameRequired")
                          : ""
                      }
                    />
                    <TextField
                      fullWidth
                      label={translate("Nickname")}
                      variant="outlined"
                      margin="normal"
                      value={formValues.addictionInfo.nickname}
                      onChange={handleChange("addictionInfo", "nickname")}
                      error={formErrors.addictionInfo.nickname}
                      helperText={
                        formErrors.addictionInfo.nickname
                          ? translate("NicknameRequired ")
                          : ""
                      }
                    />
                    <FormControl fullWidth margin="normal">
                      <InputLabel>Gender</InputLabel>
                      <Select
                        value={formValues.personalInfo.gender}
                        onChange={handleChange("personalInfo", "gender")}
                        error={formErrors.personalInfo.gender}
                      >
                        {gender.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.textDropDownEn}
                          >
                            {option.textDropDownEn}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      label={translate("Email")}
                      variant="outlined"
                      margin="normal"
                      value={formValues.personalInfo.personalEmail}
                      onChange={handleChange("personalInfo", "personalEmail")}
                      error={formErrors.personalInfo.personalEmail}
                      helperText={
                        formErrors.personalInfo.personalEmail
                          ? translate("EmailRequired")
                          : ""
                      }
                    />
                    <PhoneNumberInputNoTitleV2
                      value={formValues.personalInfo.phoneNumber}
                      onChange={handleChange("personalInfo", "phoneNumber")}
                      placeholder={translate("PhoneNumber")}
                      error={formErrors.personalInfo.phoneNumber}
                    />
                    <FormControl fullWidth margin="normal">
                      <InputLabel>{translate("Country")}</InputLabel>
                      <Select
                        value={formValues.personalInfo.country}
                        onChange={handleChange("personalInfo", "country")}
                        error={formErrors.personalInfo.country}
                      >
                        {countries.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.textDropDownEn}
                          >
                            {option.textDropDownEn}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
                {activeStep === 1 && (
                  <>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>{translate("PrimaryAddiction")}</InputLabel>
                      <Select
                        value={formValues.addictionInfo.primaryAddiction}
                        onChange={handleChange(
                          "addictionInfo",
                          "primaryAddiction"
                        )}
                        error={formErrors.addictionInfo.primaryAddiction}
                      >
                        {addictions.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.textDropDownEn}
                          >
                            {option.textDropDownEn}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <InputLabel>{translate("DurationOfStay")}</InputLabel>
                      <Select
                        value={formValues.addictionInfo.durationOfStay}
                        onChange={handleChange(
                          "addictionInfo",
                          "durationOfStay"
                        )}
                        error={formErrors.addictionInfo.durationOfStay}
                      >
                        {durationOfStay.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.textDropDownEn}
                          >
                            {option.textDropDownEn}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      label={translate("ETA")}
                      type="date"
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={formValues.addictionInfo.eta}
                      onChange={handleChange("addictionInfo", "eta")}
                      error={formErrors.addictionInfo.eta}
                      helperText={
                        formErrors.addictionInfo.eta
                          ? translate("ETARequired")
                          : ""
                      }
                    />
                    <FormControl fullWidth margin="normal">
                      <InputLabel>{translate("ClientSource")}</InputLabel>
                      <Select
                        value={formValues.addictionInfo.clientSource}
                        onChange={handleChange("addictionInfo", "clientSource")}
                        error={formErrors.addictionInfo.clientSource}
                      >
                        {clientSource.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.textDropDownEn}
                          >
                            {option.textDropDownEn}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
                {activeStep === 2 && (
                  <>
                    <TextField
                      fullWidth
                      label={translate("ProjectedResidentialDischargeDate")}
                      type="date"
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        formValues.additionalInfo
                          .projectedResidentialDischargeDate
                      }
                      onChange={handleChange(
                        "additionalInfo",
                        "projectedResidentialDischargeDate"
                      )}
                      error={
                        formErrors.additionalInfo
                          .projectedResidentialDischargeDate
                      }
                      helperText={
                        formErrors.additionalInfo
                          .projectedResidentialDischargeDate
                          ? translate(
                              "ProjectedResidentialDischargeDateRequired"
                            )
                          : ""
                      }
                    />
                    <TextField
                      fullWidth
                      label={translate("ProjectedProgramCompletionDate")}
                      type="date"
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        formValues.additionalInfo.projectedProgramCompletionDate
                      }
                      onChange={handleChange(
                        "additionalInfo",
                        "projectedProgramCompletionDate"
                      )}
                      error={
                        formErrors.additionalInfo.projectedProgramCompletionDate
                      }
                      helperText={
                        formErrors.additionalInfo.projectedProgramCompletionDate
                          ? translate("ProjectedProgramCompletionDateRequired")
                          : ""
                      }
                    />
                  </>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  paddingBottom: "20px",
                  paddingTop: "20px",
                  borderTop: "1px solid lightgray",
                }}
              >
                <Button
                  onClick={handleBack}
                  variant="outlined"
                  color="primary"
                  disabled={activeStep === 0}
                >
                  {translate("Back")}
                </Button>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  color="primary"
                >
                  {activeStep === steps.length - 1
                    ? translate("AddTheClient")
                    : translate("Next")}
                </Button>
              </Box>
            </Box>
          </Slide>
        </Modal>
      </div>
    </ThemeProvider>
  );
};

export default SearchAndAddClientModal;
