import React, { useContext, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import RatingComponent from "../RatingComponent";
import { useAuth } from "../../contexts/AuthContext";
import { Button } from "@mui/material";
import { db } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { updateDoc, doc as firestoreDoc } from "firebase/firestore";
import { showCustomToast } from "../Misc/CustomToast";
import { useEffect } from "react";
import DropDownComponentV2 from "../DropDownComponentV2";
import DatePickerComponentV2 from "../DatePickerComponentV2";
import InputComponentV2 from "../InputComponentV2";
import { LanguageContext } from "../../context/LanguageContext";
import DropDownAllStaffsV2 from "../Dropdowns/DropDownAllStaffsV2";
import TopTitleFormComponentMobileV2 from "./TopTitleFormComponentMobileV2";
import TextFieldComponentV2Mobile from "../TextFieldComponentV2Mobile";

function AddDischargeReportMobileV2(props) {
  //STATES
  const { translate } = useContext(LanguageContext);
  const { currentUser } = useAuth();
  const [clientIdState, setClientIdState] = useState(props.clientId);
  const [isLoading, setIsLoading] = useState(false);
  const [dischargeDate, setDischargeDate] = useState(null);
  const [dischargeType, setDischargeType] = useState("");
  const [dischargeTo, setDischargeTo] = useState("");
  const [medication, setMedication] = useState("");
  const [issuesUponAdmission, setIssuesUponAdmission] = useState("");
  const [emergingIssues, setEmergingIssues] = useState("");
  const [progress, setProgress] = useState("");
  const [familyInvolvement, setFamilyInvolvement] = useState("");
  const [roadblocks, setRoadblocks] = useState("");
  const [clientPrognosis, setClientPrognosis] = useState("");
  const [dischargePlan, setDischargePlan] = useState("");
  const [staffMemberName, setStaffMemberName] = useState(
    currentUser.displayName
  );
  // const [signatureData, setSignatureData] = useState(null);
  const [rating, setRating] = useState(0);
  const [, setSignatureDataURL] = useState(null);
  // const [isSignatureStarted, setIsSignatureStarted] = useState(false);
  const [dischargeTypeOther, setDischargeTypeOther] = useState("");
  const [dischargeToOther, setDischargeToOther] = useState("");
  // const [isSignatureValidated, setIsSignatureValidated] = useState(false);
  const [selectedTherapist, setSelectedTherapist] = useState("");
  const [dateOfAdmission, setDateOfAdmission] = useState(
    "" || props.dateOfAdmission
  );
  const [lengthOfStay, setLengthOfStay] = useState("");

  //FUNCTIONS

  const handleDischargeTypeOther = (event) => {
    setDischargeTypeOther(event);
  };
  const handleDischargeToOther = (event) => {
    setDischargeToOther(event);
  };

  const handleSelectedTherapistChange = (value) => {
    setSelectedTherapist(value);
  };

  const handleDischargeDateChange = (event) => {
    setDischargeDate(event);
  };

  const handleDischargeTypeChange = (event) => {
    setDischargeType(event);
  };

  const handleDischargeToChange = (event) => {
    setDischargeTo(event);
  };

  const handleMedicationChange = (event) => {
    setMedication(event);
  };

  const handleIssuesUponAdmissionChange = (event) => {
    setIssuesUponAdmission(event);
  };

  const handleEmergingIssuesChange = (event) => {
    setEmergingIssues(event);
  };

  const handleProgressChange = (event) => {
    setProgress(event);
  };

  const handleFamilyInvolvementChange = (event) => {
    setFamilyInvolvement(event);
  };

  const handleRoadblocksChange = (event) => {
    setRoadblocks(event);
  };

  const handleClientPrognosisChange = (event) => {
    setClientPrognosis(event);
  };

  const handleDischargePlanChange = (event) => {
    setDischargePlan(event);
  };

  const handleLengthOfStay = (event) => {
    setLengthOfStay(event);
  };

  const handleRatingChange = (event) => {
    setRating(event);
  };

  useEffect(() => {
    const loadData = async () => {
      const dischargeFormsRef = collection(db, "dischargeForms");
      const dischargeFormDocRef = doc(dischargeFormsRef, clientIdState);
      const docData = await getDoc(dischargeFormDocRef);

      if (docData.exists()) {
        const data = docData.data();

        setClientIdState(data.clientIdState || props.clientId);
        setDischargeDate(data.dischargeDate || null);
        setDischargeType(data.dischargeType || "");
        setDischargeTo(data.dischargeTo || "");
        setMedication(data.medication || "");
        setIssuesUponAdmission(data.issuesUponAdmission || "");
        setEmergingIssues(data.emergingIssues || "");
        setProgress(data.progress || "");
        setFamilyInvolvement(data.familyInvolvement || "");
        setRoadblocks(data.roadblocks || "");
        setClientPrognosis(data.clientPrognosis || "");
        setDischargePlan(data.dischargePlan || "");
        setStaffMemberName(data.staffMemberName || currentUser.displayName);
        setRating(data.rating || 0);
        setSignatureDataURL(data.signatureDataURL || null);
        setDischargeTypeOther(data.dischargeTypeOther || "");
        setDischargeToOther(data.dischargeToOther || "");
        setSelectedTherapist(data.selectedTherapist || "");
        setDateOfAdmission(data.dateOfAdmission || props.dateOfAdmission);
        setLengthOfStay(data.lengthOfStay || "");
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIdState]);

  const [isSavingModifications, setIsSavingModifications] = useState(false);

  const handleSaveModifications = async () => {
    setIsSavingModifications(true);

    try {
      // const signatureURL = signatureDataURL ? await uploadSignature() : null;

      const dischargeData = {
        dischargeDate,
        dischargeTypeOther,
        dischargeType,
        dischargeTo,
        dischargeToOther,
        medication,
        issuesUponAdmission,
        emergingIssues,
        progress,
        familyInvolvement,
        roadblocks,
        clientPrognosis,
        dischargePlan,
        staffMemberName,
        selectedTherapist,
        // signatureData: signatureURL,
        rating,
        lengthOfStay,
        dateOfAdmission,
      };

      const dischargeFormsRef = collection(db, "dischargeForms");
      const dischargeFormDocRef = doc(dischargeFormsRef, clientIdState);

      await setDoc(dischargeFormDocRef, dischargeData);

      showCustomToast("Modifications saved successfully!", "success");

      setIsLoading(false);
    } catch (error) {
      showCustomToast("Error saving modifications", "error");
      console.error("Error saving modifications:", error);
      setIsLoading(false);
    }
    setIsSavingModifications(false);
    props.closemodal();
  };

  const handleFormSubmit = async () => {
    if (dischargeDate && dischargeType && dischargeTo) {
      setIsLoading(true);

      try {
        // const signatureURL = await uploadSignature();

        const dischargeData = {
          dischargeDate,
          dischargeTypeOther,
          dischargeType,
          dischargeTo,
          dischargeToOther,
          medication,
          issuesUponAdmission,
          emergingIssues,
          progress,
          familyInvolvement,
          roadblocks,
          clientPrognosis,
          dischargePlan,
          staffMemberName,
          selectedTherapist,
          // signatureData: signatureURL,
          rating,
          lengthOfStay,
          dateOfAdmission,
          signedOn: serverTimestamp(),
        };

        const dischargeFormsRef = collection(db, "dischargeForms");
        const dischargeFormDocRef = doc(dischargeFormsRef, clientIdState);

        await setDoc(dischargeFormDocRef, dischargeData);

        const clientDocRef = firestoreDoc(db, "clients", clientIdState);
        await updateDoc(clientDocRef, {
          DischargeReportOk: true,
          DischargeReportDateSigned: serverTimestamp(),
        });

        showCustomToast("Discharge form correctly submitted!", "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        showCustomToast("Error submitting form", "error");

        console.error("Error submitting form:", error);
        setIsLoading(false);
      }
    } else {
      showCustomToast(
        "Some mandatory fields are missing, please check",
        "error"
      );

      console.error("All required fields must be filled out.");
    }
  };

  const isAddButtonDisabled = !dischargeDate || !dischargeType || !dischargeTo;
  // || !signatureDataURL;
  // ||
  // !isSignatureValidated;

  return (
    <
      // style={{ width: "100%" }}
    >
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "100%", height: "100%" }}
      >
        <div
          className="line1-modal-percent"
          style={{ borderBottom: "1px solid lightgrey" }}
        >
          <TopTitleFormComponentMobileV2
            title={translate("DischargeSummary")}
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "3px",
            height: "80%",
            overflowY: "scroll",
            paddingLeft: "20px",
            paddingBottom: "30px",

            // overflowX: "hidden",
          }}
        >
          <div
            className="tab-form-container"
            style={{ display: "flex", gap: "10px", paddingLeft: "10px" }}
          >
            <DatePickerComponentV2
              title={translate("DischargeDate")}
              width="250px"
              isRequired={true}
              value={dischargeDate}
              onChange={handleDischargeDateChange}
            />
          </div>
          <div
            className="tab-form-container"
            style={{ display: "flex", gap: "10px", paddingLeft: "10px" }}
          >
            <DropDownComponentV2
              api="dischargeType"
              title={translate("DischargeType")}
              isRequired={true}
              value={dischargeType}
              onChange={handleDischargeTypeChange}
              width="250px"
            />

            {dischargeType === "Other" ? (
              <InputComponentV2
                title="If other, describe"
                width="250px"
                value={dischargeTypeOther}
                onChange={handleDischargeTypeOther}
              />
            ) : (
              ""
            )}

            <DropDownComponentV2
              api="dischargeTo"
              title={translate("DischargeTo")}
              value={dischargeTo}
              isRequired={true}
              onChange={handleDischargeToChange}
              width="250px"
            />

            {dischargeTo === "Other" ? (
              <InputComponentV2
                title="If other, describe"
                width="250px"
                value={dischargeToOther}
                onChange={handleDischargeToOther}
              />
            ) : (
              ""
            )}
          </div>

          <div
            className="tab-form-container"
            style={{ display: "flex", gap: "10px" }}
          >
            <DropDownAllStaffsV2
              title={translate("AssignedTherapist")}
              value={selectedTherapist}
              width="auto"
              onChange={handleSelectedTherapistChange}
            />

            <div style={{ paddingLeft: "10px" }}>
              <DatePickerComponentV2
                title={translate("DateOfAdmission")}
                width="250px"
                value={dateOfAdmission}
                // onChange={handleDateOfAdmission}
              />
            </div>

            <div style={{ paddingLeft: "10px" }}>
              <InputComponentV2
                title={translate("TotalLengthOfStay")}
                width="250px"
                value={lengthOfStay}
                onChange={handleLengthOfStay}
              />
            </div>
          </div>

          <div
            className="tab-form-container"
            style={{
              display: "flex",
              paddingLeft: "10px",
              gap: "10px",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                paddingLeft: "0px",
                paddingRight: "20px",
              }}
            >
              <TextFieldComponentV2Mobile
                title={translate("MedicationPrescribedUponDischarge")}
                value={medication}
                onChange={handleMedicationChange}
                width="100%"
              />
            </div>
            <div
              style={{
                width: "100%",
                paddingLeft: "0px",
                paddingRight: "20px",
              }}
            >
              <TextFieldComponentV2Mobile
                title={translate("IdentifiedIssuesUponAdmission")}
                value={issuesUponAdmission}
                onChange={handleIssuesUponAdmissionChange}
                width="100%"
              />
            </div>
            <div
              style={{
                width: "100%",
                paddingLeft: "0px",
                paddingRight: "20px",
              }}
            >
              <TextFieldComponentV2Mobile
                title={translate("EmergingIssuesDuringTreatment")}
                value={emergingIssues}
                onChange={handleEmergingIssuesChange}
                width="100%"
              />
            </div>

            <RatingComponent value={rating} onChange={handleRatingChange} />
            <div
              style={{
                width: "100%",
                paddingLeft: "0px",
                paddingRight: "20px",
              }}
            >
              <TextFieldComponentV2Mobile
                title={translate("Progress")}
                value={progress}
                onChange={handleProgressChange}
                width="100%"
              />
            </div>

            <div
              style={{
                width: "100%",
                paddingLeft: "0px",
                paddingRight: "20px",
              }}
            >
              <TextFieldComponentV2Mobile
                title={translate("FamilyInvolvement")}
                value={familyInvolvement}
                onChange={handleFamilyInvolvementChange}
                width="100%"
              />
            </div>
            <div
              style={{
                width: "100%",
                paddingLeft: "0px",
                paddingRight: "20px",
              }}
            >
              <TextFieldComponentV2Mobile
                title={translate("RoadblocksConcerns")}
                value={roadblocks}
                onChange={handleRoadblocksChange}
                width="100%"
              />
            </div>
            <div
              style={{
                width: "100%",
                paddingLeft: "0px",
                paddingRight: "20px",
              }}
            >
              <TextFieldComponentV2Mobile
                title={translate("ClientPrognosis")}
                value={clientPrognosis}
                onChange={handleClientPrognosisChange}
                width="100%"
              />
            </div>
            <div
              style={{
                width: "100%",
                paddingLeft: "0px",
                paddingRight: "20px",
              }}
            >
              <TextFieldComponentV2Mobile
                title={translate("RecommendedDischargePlan")}
                value={dischargePlan}
                onChange={handleDischargePlanChange}
                width="100%"
              />
            </div>
            <InputComponentV2
              title={translate("StaffMemberName")}
              value={staffMemberName}
              width="100%"
              // onChange={handleStaffMemberNameChange}
            />
            {/* <SignaturePadComponent
              onSignatureSave={handleSignatureSave}
              signatureDataURL={signatureDataURL}
              onSignatureStart={() => setIsSignatureStarted(true)}
              onSignatureValidate={setIsSignatureValidated}
            /> */}
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              onClick={props.closemodal}
            >
              {translate("Cancel")}
            </Button>

            <Button
              variant="contained"
              onClick={handleSaveModifications}
              style={{
                backgroundColor: isSavingModifications
                  ? "lightgrey"
                  : "#FFC107",
                color: "black",
                marginLeft: "15px",
              }}
              disabled={isSavingModifications}
            >
              {isSavingModifications ? translate("Saving") : translate("Save")}
            </Button>

            <Button
              variant="contained"
              onClick={handleFormSubmit}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : isAddButtonDisabled
                  ? {
                      backgroundColor: "lightgrey",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading || isAddButtonDisabled}
            >
              {isLoading ? translate("Submitting") : translate("Submit")}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddDischargeReportMobileV2;
