import React, { useContext, useEffect, useState } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { format } from "date-fns";
import { Button } from "@mui/material";
import TitleSearchComponent from "../Misc/TitleSearchComponent";
import ClientRow from "../Misc/ClientRow";
import Papa from "papaparse";
import TitleSearchComponentMaleFemale from "../Misc/TitleSearchComponentMaleFemale";
import TitleSearchComponentStatus from "../Misc/TitleSearchComponentStatus";
import TitleSearchComponentAddiction from "../Misc/TitleSearchComponentAddiction";
import TitleSearchComponentYesNo from "../Misc/TitleSearchComponentYesNo";
import TitleSearchComponentLenghtOfStay from "../Misc/TitleSearchComponentLenghtOfStay";
import ButtonDeleteRed from "../Misc/ButtonDeleteRed";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import { LanguageContext } from "../../context/LanguageContext";

function AllClientExport() {
  const { translate } = useContext(LanguageContext);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientDisplayLimit, setClientDisplayLimit] = useState(10);
  const [, setTotalRecords] = useState(0);
  const [filteredClients, setFilteredClients] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [progress, setProgress] = useState(0);
  const [run, setRun] = useState(false);
  const [, setShowHelpButton] = useState(true);

  const [filters, setFilters] = useState({
    status: "",
    firstName: "",
    lastName: "",
    gender: "",
    email: "",
    nationality: "",
    country: "",
    primaryAddiction: "",
    secondaryAddiction: "",
    recoveryRadio: "",
    googleReview: "",
    durationOfStay: "",
  });

  const customStyles = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50, // Ajustez cette valeur pour positionner le beacon au-dessus
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed", // Utilisez 'fixed' pour que l'overlay couvre toute la page
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Ajustez l'opacité de l'overlay
      zIndex: 1000, // Assurez-vous que l'overlay est au-dessus de tout le reste
    },
    spotlight: {
      // backgroundColor: "rgba(0, 0, 0, 0.7)",
      position: "absolute",
      borderRadius: 0,
    },
    // beacon: {
    //   placement: "top",
    // },
    beaconInner: {
      backgroundColor: "#1565c0", // Couleur du cercle intérieur
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)", // Couleur de l'animation concentrique
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const steps = [
    {
      target: "#export-button",
      content:
        "Click here to export the filtered client data to a CSV file. The export process will begin immediately, and you will see a progress indicator within the button showing the export status.",
    },
    {
      target: "#filter-area",
      content:
        "Use this area to apply filters and refine the list of clients displayed. Adjust the filters to narrow down the list based on your criteria.",
    },
    {
      target: "#delete-button",
      content:
        "Click this button to reset all applied filters and show the complete list of clients again.",
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  function convertToCSV(data) {
    const csv = Papa.unparse(data, {
      header: true,
    });
    return csv;
  }

  useEffect(() => {
    const clientCollection = collection(db, "clients");

    getDocs(clientCollection).then((snapshot) => {
      setTotalRecords(snapshot.size);

      const clientData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setClients(clientData);
      setIsLoading(false);
    });
  }, []);

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy");
  }

  useEffect(() => {
    const newFilteredClients = clients.filter((client) => {
      return (
        (filters.status === "" ||
          filters.status === "All" ||
          (client.clientStatus &&
            client.clientStatus
              .toLowerCase()
              .includes(filters.status.toLowerCase()))) &&
        (filters.firstName === "" ||
          (client.firstName &&
            client.firstName
              .toLowerCase()
              .includes(filters.firstName.toLowerCase()))) &&
        (filters.lastName === "" ||
          (client.lastName &&
            client.lastName
              .toLowerCase()
              .includes(filters.lastName.toLowerCase()))) &&
        (filters.email === "" ||
          (client.personalEmail &&
            client.personalEmail
              .toLowerCase()
              .includes(filters.email.toLowerCase()))) &&
        (filters.nationality === "" ||
          (client.nationality &&
            client.nationality
              .toLowerCase()
              .includes(filters.nationality.toLowerCase()))) &&
        (filters.country === "" ||
          (client.country &&
            client.country
              .toLowerCase()
              .includes(filters.country.toLowerCase()))) &&
        (filters.primaryAddiction === "(empty)"
          ? !client.primaryAddiction || client.primaryAddiction === ""
          : filters.primaryAddiction === "" ||
            (client.primaryAddiction &&
              client.primaryAddiction
                .toLowerCase()
                .includes(filters.primaryAddiction.toLowerCase()))) &&
        (filters.secondaryAddiction === "(empty)"
          ? !client.secondaryAddiction || client.secondaryAddiction === ""
          : filters.secondaryAddiction === "" ||
            (client.secondaryAddiction &&
              client.secondaryAddiction
                .toLowerCase()
                .includes(filters.secondaryAddiction.toLowerCase()))) &&
        (filters.durationOfStay === "(empty)"
          ? !client.durationOfStay || client.durationOfStay === ""
          : filters.durationOfStay === "" ||
            (client.durationOfStay &&
              client.durationOfStay
                .toLowerCase()
                .includes(filters.durationOfStay.toLowerCase()))) &&
        (filters.recoveryRadio === "(empty)"
          ? !client.recoveryRadio || client.recoveryRadio === ""
          : filters.recoveryRadio === "" ||
            (client.recoveryRadio &&
              client.recoveryRadio.toLowerCase() ===
                filters.recoveryRadio.toLowerCase())) &&
        (filters.googleReview === "(empty)"
          ? !client.googleReview || client.googleReview === ""
          : filters.googleReview === "" ||
            (client.googleReview &&
              client.googleReview.toLowerCase() ===
                filters.googleReview.toLowerCase())) &&
        (filters.gender === "(empty)"
          ? !client.gender || client.gender === ""
          : filters.gender === "" ||
            (client.gender &&
              client.gender.toLowerCase() === filters.gender.toLowerCase()))
      );
    });

    setFilteredClients(newFilteredClients);
  }, [clients, filters]);

  async function fetchClientData() {
    let combinedData = [];
    const totalClients = filteredClients.length;

    const timestampFields = [
      "actualDischargeDate",
      "dateOfAdmission",
      "recoveryEndDate",
      "idd",
      "startDateMAtHome",
      "ccEndDate",
      "projectedResidentialDischargeDate",
      "dateOfCreation",
      "recoveryStartDate",
      "projectedProgramCompletionDate",
      "dob",
      "ccStartDate",
      "endDateMAtHome",
      "swot",
      "lastModification",
      "eta",
      "endDateAftercare",
      "recordedAt",
      "startDateAftercare",
      "DischargeReportDateSigned",
    ];

    const keyMap = {
      emergencyCheckbox0: "Acknowledgment Of Presence",
      emergencyCheckbox1: "Clinical Information",
      emergencyCheckbox2: "Psych Evaluation",
      emergencyCheckbox3: "Referral Letter",
      emergencyCheckbox4: "Lab Data",
      emergencyCheckbox5: "Progress Notes",
      emergencyCheckbox6: "Medical Records",
      emergencyCheckbox7: "Progress In Treatment",
      emergencyCheckbox8: "Psychosocial Assessment",
      emergencyCheckbox9: "Medication Info",
      emergencyCheckbox10: "Treatment Plan",
      emergencyCheckbox11: "Physical Assessment",
      emergencyCheckbox12: "Discharge Summary",
      emergencyCheckbox13: "Family History",
      emergencyCheckbox14: "Discharge Plan",
      emergencyCheckbox15: "Admission Discharge Dates",
      emergencyCheckboxContent15: "Other Info",
      emergencyCheckboxContent15Input: "Other Info Input",
    };

    for (let i = 0; i < totalClients; i++) {
      const client = filteredClients[i];
      const clientDoc = await getDoc(doc(db, "clients", client.id));
      let clientData = clientDoc.exists() ? clientDoc.data() : {};

      clientData = Object.keys(clientData).reduce((acc, key) => {
        const newKey = keyMap[key] || key;
        acc[newKey] = timestampFields.includes(key)
          ? formatDate(clientData[key])
          : clientData[key];
        return acc;
      }, {});

      const assessmentDoc = await getDoc(
        doc(db, "preAdmissionAssessments", client.id)
      );
      let assessmentData = assessmentDoc.exists() ? assessmentDoc.data() : {};

      assessmentData = Object.keys(assessmentData).reduce((acc, key) => {
        const newKey = keyMap[key] || key;
        acc[newKey] = timestampFields.includes(key)
          ? formatDate(assessmentData[key])
          : assessmentData[key];
        return acc;
      }, {});

      const combinedClientData = {
        ...clientData,
        ...assessmentData,
      };

      combinedData.push(combinedClientData);

      setProgress(((i + 1) / totalClients) * 100);
    }

    return combinedData;
  }

  const handleExport = async () => {
    setIsExporting(true);
    setProgress(0);
    const data = await fetchClientData();
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "exported_data.csv";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    setIsExporting(false);
  };

  return (
    <div>
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "20px",
          paddingLeft: "50px",
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "1200px",
        }}
      >
        {/* {showHelpButton && (
          <div
            style={{ height: "30px", paddingTop: "0px", paddingRight: "40px" }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={handleHelpClick}
              sx={{ fontSize: "10px" }}
            >
              HELP
            </Button>
          </div>
        )} */}
        <div id="export-button">
          <Button
            variant="outlined"
            onClick={handleExport}
            disabled={isExporting}
            style={{ position: "relative" }}
          >
            {isExporting ? (
              <>
                <CircularProgress
                  variant="determinate"
                  value={progress}
                  size={24}
                  thickness={4}
                  sx={{
                    color: "white",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                />
                <span style={{ marginLeft: "35px" }}>
                  Exporting... {Math.round(progress)}%
                </span>
              </>
            ) : (
              `${translate("ExportData2")} : ${filteredClients?.length} item(s)`
            )}
          </Button>
        </div>
      </div>

      <div
        style={{
          paddingLeft: "50px",
          width: "1200px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          id="filter-area"
          style={{
            display: "flex",
            flexDirection: "row",
            paddingBottom: "20px",
            // overflowX: "auto",
            justifyContent: "flex-start",
            alignContent: "flex-end",
            alignItems: "flex-end",
            borderBottom: "3px solid lightgray",
            // backgroundColor: "yellow",
          }}
        >
          <TitleSearchComponent
            width="95px"
            title={translate("FirstName")}
            value={filters.firstName}
            onChange={(e) =>
              setFilters({ ...filters, firstName: e.target.value })
            }
          />

          <TitleSearchComponent
            width="95px"
            title={translate("LastName2")}
            value={filters.lastName}
            onChange={(e) =>
              setFilters({ ...filters, lastName: e.target.value })
            }
          />

          <TitleSearchComponentMaleFemale
            width="95px" // Largeur du composant
            title={translate("Gender")} // Titre du composant
            value={filters.gender} // Valeur du filtre de genre
            onChange={(e) => setFilters({ ...filters, gender: e.target.value })}
          />

          <TitleSearchComponentStatus
            width="95px"
            title={translate("Status")}
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
          />

          <TitleSearchComponent
            width="95px"
            title={translate("Nationality")}
            value={filters.nationality}
            onChange={(e) =>
              setFilters({ ...filters, nationality: e.target.value })
            }
          />

          <TitleSearchComponent
            width="95px"
            title={translate("Country")}
            value={filters.country}
            onChange={(e) =>
              setFilters({ ...filters, country: e.target.value })
            }
          />

          <TitleSearchComponentAddiction
            width="95px"
            title={translate("PrimaryAddiction")}
            value={filters.primaryAddiction}
            onChange={(e) =>
              setFilters({ ...filters, primaryAddiction: e.target.value })
            }
          />

          <TitleSearchComponentAddiction
            width="95px"
            title={translate("SecondaryAddiction")}
            value={filters.secondaryAddiction}
            onChange={(e) =>
              setFilters({ ...filters, secondaryAddiction: e.target.value })
            }
          />

          <TitleSearchComponentLenghtOfStay
            width="95px"
            title={translate("LengthOfStay")}
            value={filters.durationOfStay}
            onChange={(e) =>
              setFilters({ ...filters, durationOfStay: e.target.value })
            }
          />

          <TitleSearchComponentYesNo
            width="95px"
            title={translate("InActiveRecovery")}
            value={filters.recoveryRadio}
            onChange={(e) =>
              setFilters({ ...filters, recoveryRadio: e.target.value })
            }
          />

          <TitleSearchComponentYesNo
            width="95px"
            title={translate("GoogleReviewDone")}
            value={filters.googleReview}
            onChange={(e) =>
              setFilters({ ...filters, googleReview: e.target.value })
            }
          />
        </div>
        <div
          id="delete-button"
          style={{
            marginLeft: "10px",
            paddingTop: "0px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingBottom: "23px",
          }}
        >
          <ButtonDeleteRed
            variant="contained"
            onClick={() =>
              setFilters({
                status: "",
                firstName: "",
                lastName: "",
                gender: "",
                email: "",
                nationality: "",
                country: "",
                primaryAddiction: "",
                secondaryAddiction: "",
                recoveryRadio: "",
                googleReview: "",
                durationOfStay: "",
              })
            }
          >
            {translate("ResetFilters")}
          </ButtonDeleteRed>
        </div>
      </div>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "500px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div
          className="client-widgets-global-container"
          style={{
            paddingLeft: "30px",
            paddingTop: "5px",
            marginBottom: "50px",
          }}
        >
          {filteredClients.slice(0, clientDisplayLimit).map((client) => (
            <ClientRow
              key={client.id}
              clientStatus={client.clientStatus}
              firstName={client.firstName}
              lastName={client.lastName}
              gender={client.gender}
              email={client.email}
              nationality={client.nationality}
              country={client.country}
              primaryAddiction={client.primaryAddiction}
              secondaryAddiction={client.secondaryAddiction}
              inActiveRecovery={client.recoveryRadio}
              googleReviewDone={client.googleReview}
              // inActiveRecovery={client.recoveryRadio ? "Yes" : "No"}
              // googleReviewDone={client.googleReview ? "Yes" : "No"}
              personalEmail={client.personalEmail}
              durationOfStay={client.durationOfStay}
              idClient={client.id}
              clientData={client}
            />
          ))}
        </div>
      )}

      {!isLoading && filteredClients.length > clientDisplayLimit && (
        <div className="seemore-container">
          <Button
            onClick={() => setClientDisplayLimit(clientDisplayLimit + 10)}
          >
            See more
          </Button>
        </div>
      )}
    </div>
  );
}

export default AllClientExport;
