import React, { useContext } from "react";
import "../css/dashboard.css";
import { useAuth } from "../contexts/AuthContext";
import { useState } from "react";
import { documentId, onSnapshot, query, where } from "firebase/firestore";
import { useEffect } from "react";
import { roles } from "../firebase.collection";
import ClientNotesMainNotTherapist from "../component/ClientsCategory/ClientNotesMainNotTherapist";
import ClientNotesMainMedicalStaff from "../component/ClientsCategory/ClientNotesMainMedicalStaff";
import ClientNotesMainHospitalityStaff from "../component/ClientsCategory/ClientNotesMainHospitalityStaff";
import SearchAndAddClientModal from "../component/Misc/SearchAndAddClientModal";
import { LanguageContext } from "../context/LanguageContext";
import ClientNotesMainV2Mobile from "../component/ClientsCategory/ClientNotesMainV2Mobile";

export default function NoteContentV2Mobile(props) {
  //VARIABLES
  const { currentUser } = useAuth();
  const { translate } = useContext(LanguageContext);
  const userIdState = currentUser ? currentUser.uid : "";

  //STATES
  const [userRole, setUserRole] = useState("");

  //FUNCTIONS

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;

          setUserRole(role);
        } else {
          console.log("No role for this user");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
    );
  }

  useEffect(() => {
    if (currentUser) {
      queryRole();
    } else {
      console.log("No user id");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <div className="right-container-mobile" style={{ paddingLeft: "5px" }}>
      <SearchAndAddClientModal translate={translate} />
      {/* <TopTitleBannerV2 title={"Notes"} /> */}
      <div className="tab-form-container-mobile-v3">
        {(() => {
          if (
            userRole === "Therapist" ||
            userRole === "Super Admin" ||
            userRole === "Clinical Director"
          ) {
            return <ClientNotesMainV2Mobile {...props} />;
          } else if (userRole === "Medical Staff") {
            return <ClientNotesMainMedicalStaff {...props} />;
          } else if (userRole === "Hospitality Staff") {
            return <ClientNotesMainHospitalityStaff {...props} />;
          } else {
            return <ClientNotesMainNotTherapist {...props} />;
          }
        })()}
      </div>
    </div>
  );
}
