import React from "react";
import EditButton from "./Buttons/EditButton";
import { Box, Modal } from "@mui/material";
import { useState } from "react";
import EditRole from "./forms/EditRole";
import DeleteButtonUserData from "./Buttons/DeleteButtonUserData";
import DeleteUserModal from "./Notes/DeleteUserModal";

function FormLineMedium5(props) {
  const styleDeleteModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 260,
    borderRadius: 10,
    bgcolor: "background.paper",
    /*border: "1px solid #000",*/
    boxShadow: 24,
    // p: 4,
  };

  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 300,
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const [openEditRole, setOpenEditRole] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const handleOpenEditRole = () => setOpenEditRole(true);
  const handleCloseEditRole = () => setOpenEditRole(false);

  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <div
      className={
        props.color === "1"
          ? "formLineMedium5 light-grey-form"
          : "formLineMedium5 grey-form"
      }
    >
      <Modal
        open={openEditRole}
        onClose={handleCloseEditRole}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral}>
          <EditRole
            closemodal={handleCloseEditRole}
            userName={props.userName}
            roleUser={props.userRole}
            userId={props.userId}
          />
        </Box>
      </Modal>
      <Modal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDeleteModal}>
          <DeleteUserModal onClose={closeDeleteModal} uid={props.userId} />
        </Box>
      </Modal>
      <div className="role-line-block1">{props.userEmail}</div>
      <div className="role-line-block2">{props.userName}</div>
      <div className="role-line-block3">{props.userRole}</div>
      <div className="role-line-block3">{props.userPhone}</div>

      <div className="role-line-block3">
        <EditButton title="Edit" handleOpen={handleOpenEditRole} />
        <DeleteButtonUserData
          handleOpen={openDeleteModal}
          closeModal={closeDeleteModal}
        />
      </div>
    </div>
  );
}

export default FormLineMedium5;
