import React, { useRef, useState } from "react";
import { Button } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function ViewFamilyNote(props) {
  //Tous les states des données récupérées par client

  const [quickNoteText] = useState(props.note);
  const [effectiveDateTime] = useState(props.effectiveDate);
  const [isLoading] = useState(false);
  const [relationshipFamilyNote] = useState(props.relationshipFamilyNote || "");

  const printRef = useRef(); // Ajouter cette ligne
  const cancelButtonRef = React.useRef(null);

  function formatDate(timestamp) {
    const date = timestamp.toDate();
    const day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day} ${month} ${year} ${hours}:${minutes}`;
  }

  const handlePrint = () => {
    const oldHeight = printRef.current.style.height;
    const oldOverflowY = printRef.current.style.overflowY;

    // Set the height to the scroll height of the content
    printRef.current.style.height = printRef.current.scrollHeight + "px";
    printRef.current.style.overflowY = "visible";

    html2canvas(printRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("note.pdf");

      // Revert the height and overflowY properties
      printRef.current.style.height = oldHeight;
      printRef.current.style.overflowY = oldOverflowY;
    });
  };

  return (
    <div>
      <div className="inputAndLabelModalContainerTitle2">
        <div className="line1-modal">
          <div className="modal-title">
            View Note{" "}
            <span style={{ fontSize: "10px" }}>(note Id: {props.noteId})</span>
          </div>

          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.closemodal}
          />
        </div>

        <div
          id="printableArea"
          ref={printRef}
          style={{
            maxHeight: "auto",
            // maxHeight: "600px",
            overflowY: "auto",
            paddingRight: "10px",
          }}
        >
          <div className="line2-column-modal">
            <div className="label-textfield-underline">Note content</div>

            <div
              className="note-content-for-print"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {quickNoteText}
            </div>
          </div>
          <div className="line2-column-modal">
            <div className="label-textfield-underline">Effective date</div>
            <div className="note-content-for-print">
              {effectiveDateTime && formatDate(effectiveDateTime)}
            </div>
          </div>

          <div className="line2-column-modal">
            <div className="label-textfield-underline">Relationship</div>
            <div className="note-content-for-print">
              {relationshipFamilyNote}
            </div>
          </div>
        </div>
      </div>

      <div className="line4-modal">
        <>
          <Button
            variant="contained"
            style={{ backgroundColor: "white", color: "black" }}
            onClick={props.closemodal}
            ref={cancelButtonRef}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            style={
              isLoading
                ? {
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "15px",
                  }
                : {
                    backgroundColor: "#1565C0",
                    color: "white",
                    marginLeft: "15px",
                  }
            }
            disabled={isLoading}
            onClick={handlePrint}
          >
            {isLoading ? "DOWNLOADING NOTES..." : "DOWNLOAD NOTE"}
          </Button>
        </>
      </div>
    </div>
  );
}

export default ViewFamilyNote;
