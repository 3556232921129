import React from "react";
import "../css/dashboard.css";
import CasadaSwitchMobile from "../component/Misc/CasadaSwitchMobile";

export default function RoomLayoutContentMobile() {
  return (
    <div className="right-container-mobile">
      <CasadaSwitchMobile />
    </div>
  );
}
