import React, { useState } from "react";
import { Button } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import TopTitleFormComponent from "../forms/TopTitleFormComponent";
import { useRef } from "react";
import ReactToPrint from "react-to-print";

function ViewGroupNotes(props) {
  const printRef = useRef();
  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  // Initialize states with props
  const [typeGroupNote] = useState(props.groupType);
  const [otherTypeGroupNote] = useState(props.otherTypeGroupNote);
  const [groupTopic] = useState(props.groupTopic);
  const [noteContent] = useState(props.noteContent);
  const [notInAttendance] = useState(props.notInAttendance);

  const [groupNoteDateTime] = useState(props.effectiveDateTime || "");

  const [groupNoteStartDateTime] = useState(props.groupStartDateTime);

  // Other state definitions
  const [, setIsLoading] = useState(false);

  // Other handler definitions

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  function formatDateWithTime(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    const formattedDate = format(date, "d MMMM yyyy"); // Date
    const formattedTime = format(date, "H 'h' mm 'min'"); // Heure en format européen

    return `${formattedDate} à ${formattedTime}`;
  }

  return (
    <>
      <style>
        {`
          @media print {
            #printableContent {
              height: auto !important;
              overflow-y: visible !important;
            }
          }
        `}
      </style>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title="VIEW GROUP NOTE"
            closemodal={props.closemodal}
          />
        </div>

        <div
          id="printableContent"
          style={{
            width: "100%",
            marginTop: "10px",
            height: "75%",
            overflowY: "scroll",
            // overflowX: "hidden",
          }}
          ref={printRef}
        >
          {" "}
          <div className="top-title-view-modal">
            Group note - {formatDate(groupNoteStartDateTime)}{" "}
            <span style={{ fontSize: "14px" }}> done by :</span>
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {" "}
              {props.doneBy}
            </span>
          </div>
          <div className="viewBlock">
            <div className="titleInput">Group topic</div>
            <div className="contentInput">{groupTopic}</div>
          </div>
          <div className="viewBlock">
            <div className="titleInput">Group Date and start time</div>
            <div className="contentInput">
              {formatDateWithTime(groupNoteStartDateTime)}
            </div>
          </div>
          <div className="viewBlock">
            <div className="titleInput">Type of group</div>
            {typeGroupNote === "Other" ? (
              <div className="contentInput">{otherTypeGroupNote}</div>
            ) : (
              <div className="contentInput">{typeGroupNote}</div>
            )}
          </div>
          <div className="viewBlock">
            <div className="titleInput"> Group note</div>
            <div className="contentInput">{noteContent}</div>
          </div>
          <div className="viewBlock">
            <div className="titleInput"> Clients NOT in attendance</div>
            <div className="contentInput">{notInAttendance}</div>
          </div>
          <div className="viewBlock">
            <div className="titleInput"> Effective date and time</div>
            <div className="contentInput">
              {formatDateWithTime(groupNoteDateTime)}
            </div>
          </div>
          <div className="viewFooter"></div>
        </div>

        <div className="line4-modal-percent">
          <Button
            variant="contained"
            style={{ backgroundColor: "white", color: "black" }}
            onClick={props.closemodal}
          >
            Cancel
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" style={{ marginLeft: "20px" }}>
                Print
              </Button>
            )}
            content={() => printRef.current}
            onAfterPrint={props.closemodal}
          />
        </div>
      </div>
    </>
  );
}

export default ViewGroupNotes;
