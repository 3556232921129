import React from "react";

export default function CheckWithDateMobile(props) {
  return (
    <div
      className="container-check-with-name"
      title={`Revoked by: ${props.title}`}
      style={{ cursor: "pointer" }}
    >
      <div className="name-for-check-mobile">{props.content} </div>
    </div>
  );
}
