import React from "react";
import "../css/dashboard.css";
import "../css/client.css";
import { useLocation, useParams } from "react-router-dom";
import ClientProfileMobileV2 from "../component/ClientProfileMobileV2";

export default function ClientContentMobileV2(props) {
  const { id } = useParams();
  const clientId = props.clientId || id;
  const { state } = useLocation();
  const selectedTab = state?.selectedTab || "2";

  return (
    <div className="right-container-mobile">
      <ClientProfileMobileV2 clientId={clientId} selectedTab={selectedTab} />
    </div>
  );
}
