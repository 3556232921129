import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { Timestamp } from "firebase/firestore";
import dayjs from "dayjs";
import "../css/customDatePickerStyles.css";

export default function DatePickerComponentNoTitle(props) {
  const handleChange = (date) => {
    const timestamp = Timestamp.fromMillis(date.valueOf());
    props.onChange(timestamp);
  };

  const dateObject =
    props.value && typeof props.value.toMillis === "function"
      ? dayjs(new Date(props.value.toMillis()))
      : props.value;

  return (
    <div className="input-label-container-newdesign">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          textField={
            <TextField
              variant="outlined"
              error={false}
              InputProps={{
                style: {
                  padding: "10px",
                  fontSize: "18px",
                  height: 30,
                },
              }}
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "grey", // ou la couleur que vous préférez
                },
              }}
            />
          }
          sx={{}}
          value={dateObject}
          onChange={handleChange}
          format="DD/MM/YYYY"
        />
      </LocalizationProvider>
    </div>
  );
}
