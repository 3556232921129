import React, { useState } from "react";
import { updateDoc, doc } from "firebase/firestore";
import { Button } from "@mui/material";
import { db } from "../../firebase";
import DropDownComponentSorted from "../DropDownComponentSorted";
import { showCustomToast } from "../Misc/CustomToast";
import { createZip } from "../exports/exportUtils";

function Admin(props) {
  const [googleReview, setGoogleReview] = useState(props.googleReview || "");
  const [isLoading, setIsLoading] = useState(false);
  const [recoveryRadio, setRecoveryRadio] = useState(props.recoveryRadio || "");
  const [isExporting, setIsExporting] = useState(false);
  const [projectedResidentialDischargeDate, ,] = useState(
    props.projectedResidentialDischargeDate || ""
  );

  const calculateDaysDifference = (seconds) => {
    const date1 = new Date(seconds * 1000);
    const date2 = new Date();
    const diffTime = date2 - date1;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const handleActiveRecoveryChange = (event) => {
    setRecoveryRadio(event);
  };
  const handleGoogleReview = (event) => {
    setGoogleReview(event);
  };

  const updateAdminData = async (clientId) => {
    setIsLoading(true);
    try {
      const clientRef = doc(db, "clients", clientId);

      await updateDoc(clientRef, {
        recoveryRadio,
        googleReview,
      });
      setIsLoading(false);
      showCustomToast(`Admin datas successfully updated!`, "success");
    } catch (error) {
      console.error("Error updating Admin datas: ", error);
      setIsLoading(false);
      showCustomToast(`Error updating Admin datas: ${error}`, "error");
    }
  };

  const handleExportData = async () => {
    setIsExporting(true);
    try {
      await createZip(props.clientId); // Appel à la fonction createZip pour générer le fichier ZIP
      showCustomToast(`Client data successfully exported!`, "success");
    } catch (error) {
      console.error("Error exporting client data: ", error);
      showCustomToast(`Error exporting client data: ${error}`, "error");
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <div className="tab-container-white" style={{ paddingLeft: "20px" }}>
      <div className="tab-container-title">Administrator extra infos</div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: 30,
          }}
        >
          {/* <div style={{ marginLeft: 15 }}>In active recovery?</div> */}
          <div style={{ width: 150 }}>
            <DropDownComponentSorted
              api="yesno"
              title="In active recovery?"
              value={recoveryRadio}
              onChange={handleActiveRecoveryChange}
            />
          </div>

          {recoveryRadio === "Yes" && (
            <div style={{ marginLeft: 15, marginTop: 10, color: "red" }}>
              Total time in recovery:{" "}
              {calculateDaysDifference(
                projectedResidentialDischargeDate.seconds
              )}{" "}
              days
            </div>
          )}
        </div>
        <DropDownComponentSorted
          api="yesno"
          title="Google review done?"
          value={googleReview}
          onChange={handleGoogleReview}
        />
      </div>

      <div className="button-container-form-gi">
        <Button
          variant="contained"
          onClick={() => updateAdminData(props.clientId)}
          style={
            isLoading
              ? {
                  backgroundColor: "red",
                  color: "white",
                  marginLeft: "15px",
                }
              : {
                  backgroundColor: "#1565C0",
                  color: "white",
                  marginLeft: "15px",
                }
          }
          disabled={isLoading}
          //   onClick={handlePostRecord}

          sx={{
            height: "40px",
            width: "220px",
            marginLeft: "25px",
            marginRight: "30px",
          }}
        >
          {isLoading ? "SAVING MODIFICATIONS..." : "SAVE MODIFICATIONS"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleExportData}
          disabled={isExporting} // Désactive le bouton pendant l'exportation
          sx={{
            height: "40px",
            width: "220px",
            marginLeft: "25px",
            marginRight: "30px",
          }}
        >
          {isExporting ? "EXPORTING DATA..." : "EXPORT DATA"}
        </Button>
      </div>
    </div>
  );
}

export default Admin;
