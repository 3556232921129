import React from "react";
import AllNotesContainerV2Mobile from "../containers/AllNotesContainerV2Mobile";

function ClientNotesMainV2Mobile(props) {
  return (
    <div className="tab-container-white-v2-mobile">
      <AllNotesContainerV2Mobile />
    </div>
  );
}

export default ClientNotesMainV2Mobile;
