import React, { useState } from "react";
import { format } from "date-fns";
import { Box, Modal } from "@mui/material";
import ViewReleaseOfInformation from "./forms/ViewReleaseOfInformation";
import ViewButtonMobileV2 from "./Buttons/ViewButtonMobileV2";
import ReviewButtonMobileV2 from "./Buttons/ReviewButtonMobileV2";
import CheckWithDateMobileV3 from "./CheckWithDateMobileV3";
import ViewRevokeMobileV2 from "./forms/ViewRevokeMobileV2";

function FormLineRoiMobileV2(props) {
  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const styleModal2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "auto",
    borderRadius: 2,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const [showViewModal, setShowViewModal] = useState(false);
  const [showRevokeModal, setShowRevokeModal] = useState(false);

  const openViewModal = () => {
    setShowViewModal(true);
  };

  const closeViewModal = () => {
    setShowViewModal(false);
  };

  const openRevokeModal = () => {
    setShowRevokeModal(true);
  };

  const closeRevokeModal = () => {
    setShowRevokeModal(false);
  };

  function formatDate2(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MM yyyy");
  }

  return (
    <div
      style={{
        marginLeft: "0px",
        display: "flex",
        flexDirection: "row",
        height: "30px",
        borderRadius: "30px",
        border: "1px solid lightgrey",
        width: "100%",
        marginTop: "5px",
        marginBottom: "5px",
        paddingLeft: "15px",
        paddingRight: "5px",
      }}
    >
      <Modal
        open={showViewModal}
        onClose={closeViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <ViewReleaseOfInformation
            {...props}
            firstName={props.firstName}
            closemodal={closeViewModal}
          />
        </Box>
      </Modal>

      <Modal
        open={showRevokeModal}
        onClose={closeRevokeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal2}>
          <ViewRevokeMobileV2
            documentId={props.documentId}
            subcollectionDocumentId={props.subcollectionDocumentId}
            {...props}
            firstName={props.firstName}
            closemodal={closeRevokeModal}
            clientId={props.clientId}
          />
        </Box>
      </Modal>

      <div
        className="formLineRoiBlock1"
        style={{ fontSize: "14px", width: "25%" }}
      >
        {props.title}
      </div>

      <div
        className="formLineRoiBlock2"
        style={{ fontSize: "14px", width: "15%" }}
      >
        {props.relationship}
      </div>

      <div
        className="formLineRoiBlock2bis"
        style={{ width: "20%", display: "flex", justifyContent: "center" }}
      >
        <CheckWithDateMobileV3
          checked={true}
          content={formatDate2(props.signedOn)}
        />
      </div>

      <div
        className="formLineRoiBlock2tris"
        style={{ width: "20%", display: "flex", justifyContent: "center" }}
      >
        <CheckWithDateMobileV3
          title={props.revokedBy}
          checked={props.revoked}
          content={props.revoked ? formatDate2(props.dateRevoked) : ""}
        />
      </div>

      <div className="formLineRoiBlock3" style={{ width: "20%" }}>
        <ViewButtonMobileV2 handleOpen={openViewModal} />
        {props.revoked ? (
          ""
        ) : (
          <ReviewButtonMobileV2 handleOpen={openRevokeModal} />
        )}
      </div>
    </div>
  );
}

export default FormLineRoiMobileV2;
