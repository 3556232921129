import React, { useContext, useEffect, useState } from "react";
import "../css/dashboard.css";
import "../css/client.css";
import { Box, Modal } from "@mui/material";
import AddClient from "../component/forms/AddClient";
import { documentId, onSnapshot, query, where } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { roles } from "../firebase.collection";
import { LanguageContext } from "../context/LanguageContext";
import SearchAndAddClientModal from "../component/Misc/SearchAndAddClientModal";
import AvailabilityChartMobileV2 from "../component/clientsList/AvailabilityChartMobileV2";

export default function AvailabilityContentMobileV2() {
  const { translate } = useContext(LanguageContext);
  const { currentUser } = useAuth();
  const userIdState = currentUser ? currentUser.uid : "";
  const [userRole, setUserRole] = useState("");
  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 600,
    borderRadius: 0,
    backgroundColor: "white",
    boxShadow: 24,
  };

  const [openAddClient, setOpenAddClient] = useState(false);

  const handleCloseAddClient = () => setOpenAddClient(false);

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;
          setUserRole(role);
        } else {
          console.log("No role for this user");
        }
      }
    );
  }

  useEffect(() => {
    if (currentUser) {
      queryRole();
    } else {
      console.log("No user id");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const isSuperAdmin =
    userRole === "Super Admin" ||
    userRole === "Admin" ||
    userRole === "Admission staff";

  return (
    <div className="right-container-mobile" style={{ paddingLeft: "5px" }}>
      {isSuperAdmin ? (
        <>
          <Modal
            open={openAddClient}
            onClose={handleCloseAddClient}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleModalGeneral}>
              <AddClient closemodal={handleCloseAddClient} />
            </Box>
          </Modal>

          {/* <TopTitleBannerV2 title={translate("RoomAvailability")} /> */}
          <SearchAndAddClientModal translate={translate} />

          <div className="top-component-list-clients"> </div>

          <AvailabilityChartMobileV2 />
          {/* <ClientsTopTabAvailability /> */}
        </>
      ) : (
        <div>
          {/* <TopTitleBannerV2 title={"Advanced search"} /> */}
          <p style={{ marginLeft: "60px", color: "red", fontWeight: "bold " }}>
            Loading...
          </p>
        </div>
      )}
    </div>
  );
}
