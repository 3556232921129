import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

function TitleSearchComponentLenghtOfStay(props) {
  // Liste des statuts disponibles
  const statusList = ["30 days", "60 days", "90 days", "120 days", "(empty)"];

  return (
    <div style={{ marginRight: "10px" }}>
      <div style={{ width: "95px" }}>
        <div
          style={{
            fontSize: "12px",
            paddingBottom: "3px",
            fontWeight: "700",
            textAlign: "start",
            color: "#7a8199",
            textTransform: "uppercase",
          }}
        >
          {props.title}
        </div>
        <div className="title-search-area">
          <FormControl sx={{ width: "100%" }}>
            <Select
              value={props.value}
              onChange={props.onChange}
              label="Status"
              sx={{
                border: "0.3px solid black",
                borderRadius: 1,
                height: 30,
                fontSize: 12,
              }}
            >
              {statusList.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

export default TitleSearchComponentLenghtOfStay;
