import React, { useContext, useState } from "react";
import { Button, Checkbox } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";
import { updateDoc, doc as firestoreDoc } from "firebase/firestore";
import { showCustomToast } from "../Misc/CustomToast";
import { LanguageContext } from "../../context/LanguageContext";
import TopTitleFormComponentMobileV2 from "./TopTitleFormComponentMobileV2";
import SignaturePadComponentV2Mobile from "../Signature/SignaturePadComponentV2Mobile";

function AddGeneralLiabilityFormMobileV2(props) {
  const { translate } = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(false);
  const { clientId } = props;
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [, setIsSignatureStarted] = useState(false);
  const text1 =
    "This agreement releases our rehab (Thailand) Ltd from all liability relating to injuries that may occur during my treatment term at our rehab and any and all Service Providers related to my treatment process, including but not limited to, Thanyapura Health & Sports Resort and massage salons, and any and all other off-site excursion related persons/organisations. By signing this agreement, I agree to hold our rehab (Thailand) Ltd free from any liability, including financial and responsibility for any injuries incurred, regardless of whether injuries are caused by negligence.";
  const text2 =
    "I also acknowledge the risks involved in treatment related activities on-site or off-site and all excursions. These include but are not limited to exercise and massage and excursion activities, such as boating etc. I swear that I am participating voluntarily, and that all risks have been made clear to me. Additionally, I do not have any conditions that would increase my likelihood of experiencing injuries while engaging in this activity.";
  const text3 =
    "Final Notice of General Liability Release by Client : By signing below, I forfeit all right to bring a suit against our rehab (Thailand) Ltd. for any reason. I will also make every effort to obey safety precautions. I will ask for clarification when needed.";
  const text4 = "Our rehab is not responsible for any lost or stolen property.";
  const [isSignatureValidated, setIsSignatureValidated] = useState(false);

  const handleSignatureSave = (dataURL) => {
    setSignatureDataURL(dataURL);
  };

  const handleCheckboxChange1 = (event) => {
    setChecked1(event.target.checked);
  };

  const handleCheckboxChange2 = (event) => {
    setChecked2(event.target.checked);
  };

  const handleCheckboxChange3 = (event) => {
    setChecked3(event.target.checked);
  };

  const handleCheckboxChange4 = (event) => {
    setChecked4(event.target.checked);
  };

  const uploadSignature = async () => {
    const storage = getStorage();
    const fileName = `${clientId}.png`;
    const signatureRef = ref(
      storage,
      `MandatoryForms/GeneralLiability/${fileName}`
    ); // Modifiez cette ligne

    await uploadString(signatureRef, signatureDataURL, "data_url");
    const downloadURL = await getDownloadURL(signatureRef);

    return downloadURL;
  };

  const handleFormSubmit = async () => {
    if (
      !checked1 ||
      !checked2 ||
      !checked3 ||
      !checked4 ||
      !signatureDataURL ||
      !isSignatureValidated
    ) {
      showCustomToast(
        "Please agree to all the terms before submitting.",
        "error"
      );
    }

    try {
      setIsLoading(true);
      const signatureURL = await uploadSignature();
      const docRef = doc(db, "GeneralLiability", clientId);
      await setDoc(docRef, {
        checked1,
        checked2,
        checked3,
        checked4,
        text1,
        text2,
        text3,
        text4,
        signatureURL,
        signedOn: serverTimestamp(),
      });

      const clientDocRef = firestoreDoc(db, "clients", clientId);
      await updateDoc(clientDocRef, {
        GeneralLiabilityOk: true,
        GeneralLiabilityDateSigned: serverTimestamp(),
      });

      showCustomToast(translate("GeneralLiabilityFormSubmitted"), "success");

      setIsLoading(false);
      props.closemodal();
    } catch (e) {
      showCustomToast(translate("ErrorAddingDocument"), "error");

      console.error("Error adding document: ", e);
    }
  };

  return (
    <>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div
          className="line1-modal-percent"
          style={{ borderBottom: "1px solid lightgray" }}
        >
          <TopTitleFormComponentMobileV2
            title={translate("GeneralLiabilityReleaseForm")}
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "02px",
            height: "80%",
            overflowY: "scroll",
            // overflowX: "hidden",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <div className="text-block" style={{ fontSize: "14px" }}>
            {text1}
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked1} onChange={handleCheckboxChange1} />I
            fully understand and agree to the above terms.
          </div>
          <div className="text-block" style={{ fontSize: "14px" }}>
            {text2}
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked2} onChange={handleCheckboxChange2} />I
            fully understand and agree to the above terms.
          </div>

          <div className="text-block" style={{ fontSize: "14px" }}>
            {text3}
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked3} onChange={handleCheckboxChange3} />I
            fully understand and agree to the above terms.
          </div>

          <div className="text-block" style={{ fontSize: "14px" }}>
            {text4}
          </div>

          <div
            className="checkbox-container-margin"
            style={{ fontSize: "14px" }}
          >
            <Checkbox checked={checked4} onChange={handleCheckboxChange4} />I
            fully understand and agree to the above terms.
          </div>

          <div
            style={{ width: "100%", marginBottom: "25px", marginTop: "20px" }}
          >
            <SignaturePadComponentV2Mobile
              onSignatureSave={handleSignatureSave}
              signatureDataURL={signatureDataURL}
              onSignatureStart={() => setIsSignatureStarted(true)}
              onSignatureValidate={setIsSignatureValidated}
            />
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <div>
            <Button
              variant="contained"
              style={{
                backgroundColor: "white",
                color: "black",
                fontSize: "12px",
              }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              {translate("Cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                      fontSize: "12px",
                    }
                  : !signatureDataURL ||
                    !isSignatureValidated ||
                    !checked1 ||
                    !checked2 ||
                    !checked3 ||
                    !checked4
                  ? {
                      backgroundColor: "grey",
                      color: "white",
                      marginLeft: "15px",
                      fontSize: "12px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                      fontSize: "12px",
                    }
              }
              disabled={
                isLoading ||
                !signatureDataURL ||
                !isSignatureValidated ||
                !checked1 ||
                !checked2 ||
                !checked3 ||
                !checked4
              }
            >
              {isLoading ? translate("Saving") : translate("Save")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddGeneralLiabilityFormMobileV2;
