import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";

function TitleNoteCompactMobile(props) {
  const { translate } = useContext(LanguageContext);

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "25px",
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid lightgrey",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
          <div
            style={{
              width: "30%",
              height: "25px",
              display: "flex",
              flexDirection: "row",
              color: "#a3a3a4",
              fontWeight: "500",
              // textAlign: "left",
              justifyContent: "center",
              alignContent: "center",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            {translate("DateC")}
          </div>

          <div
            style={{
              width: "40%",
              height: "25px",
              display: "flex",
              color: "#a3a3a4",
              fontWeight: "500",
              textAlign: "center",
              justifyContent: "center",
              paddingLeft: "0px",
            }}
          >
            <div
              style={{
                paddingRight: "0px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {translate("ClientC")}
            </div>
          </div>

          <div
            style={{
              width: "30%",
              height: "25px",
              display: "flex",
              color: "#a3a3a4",
              fontWeight: "500",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {translate("Type")}
          </div>
        </div>
      </div>
    </>
  );
}

export default TitleNoteCompactMobile;
