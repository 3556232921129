import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../css/navbar.css";
import { useAuth } from "../contexts/AuthContext";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

export default function ProfileWidgetMobileNoRole(props) {
  const { currentUser } = useAuth();

  // const [photoUrl, setPhotoUrl] = useState(
  //   currentUser ? currentUser.photoURL : ""
  // );

  const [photoUrl, setPhotoUrl] = useState(
    props.photoUrl ? props.photoUrl : currentUser ? currentUser.photoURL : ""
  );

  useEffect(() => {
    if (currentUser) {
      const userId = currentUser.uid;
      const roleRef = doc(db, "roles", userId); // Changez "roles" si la collection a un autre nom

      const unsubscribe = onSnapshot(roleRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          if (data.photoUrl) {
            setPhotoUrl(data.photoUrl); // Utilise la photo modifiée
          }
        }
      });

      return () => unsubscribe(); // Nettoyage du listener
    }
  }, [currentUser]);

  let roleColor = "";
  switch (props.userRole) {
    case "Super Admin":
      roleColor = "red"; // Jaune doré
      break;
    case "Admin":
      roleColor = "#1E90FF"; // Bleu ciel
      break;
    case "Therapist":
      roleColor = "#32CD32"; // Vert lime
      break;
    case "Support Staff":
      roleColor = "#FFA500"; // Orange vif
      break;
    case "Admission staff":
      roleColor = "#c729e9"; // Violet
      break;
    case "Medical Staff":
      roleColor = "#4B0082"; // Indigo
      break;
    case "Hospitality Staff": // Ajout de cette ligne
      roleColor = "#F08080"; // Vous pouvez choisir la couleur que vous préférez
      break;
    default:
      roleColor = "#808080"; // Gris foncé
      break;
  }

  return (
    <div
      className="widget-profile-container-mobile"
      style={{ width: "auto", paddingRight: "10%" }}
    >
      <div
        // className="avatar-container"
        style={{}}
      >
        <Avatar
          src={photoUrl}
          style={{
            width: "30px",
            height: "30px",
            border: "2px solid white",
            // marginLeft: "20px",
          }}
        />
      </div>
    </div>
  );
}
