import React from "react";

const PDFContent = React.forwardRef(({ data }, ref) => {
  const {
    firstName,
    lastName,
    text1,
    text2,
    text3,
    text4,
    GeneralLiabilityDateSigned,
    signatureURL,
  } = data;

  return (
    <div ref={ref}>
      <div style={{ padding: "20px" }}>
        <h3>
          General liability form for: {firstName || ""} {lastName || ""}
        </h3>
        <div>{text1 || ""}</div>
        <div>
          <input type="checkbox" checked readOnly />I fully understand and agree
          to the above terms.
        </div>
        <div>{text2 || ""}</div>
        <div>
          <input type="checkbox" checked readOnly />I fully understand and agree
          to the above terms.
        </div>
        <div>{text3 || ""}</div>
        <div>
          <input type="checkbox" checked readOnly />I fully understand and agree
          to the above terms.
        </div>
        <div>{text4 || ""}</div>
        <div>
          <input type="checkbox" checked readOnly />I fully understand and agree
          to the above terms.
        </div>
        <div>
          Timestamp signature:{" "}
          {GeneralLiabilityDateSigned
            ? new Date(
                GeneralLiabilityDateSigned.seconds * 1000
              ).toLocaleString()
            : "N/A"}
        </div>
        <div>
          Client signature ({firstName || ""} {lastName || ""}):
        </div>
        <img
          id="signature"
          src={signatureURL || ""}
          alt="Signature"
          style={{
            marginLeft: "25px",
            marginTop: "20px",
            border: "1px solid black",
            borderRadius: "20px",
            padding: "30px",
            width: "300px",
            height: "200px",
          }}
        />
      </div>
    </div>
  );
});

export default PDFContent;
