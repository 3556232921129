import React, { useRef } from "react";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReactToPrint from "react-to-print";

function ViewEndOfShiftNote(props) {
  const printRef = useRef();

  return (
    <>
      <style>
        {`
          @media print {
            #printableContent {
              height: auto !important;
              overflow-y: visible !important;
            }
          }
        `}
      </style>

      <div style={{ width: "auto", height: "100%" }}>
        <div className="line1-modal-percent">
          <div className="modal-title">VIEW END OF SHIFT NOTE</div>
          <CloseIcon
            onClick={props.closemodal}
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
          />
        </div>

        <div
          id="printableContent"
          style={{
            width: "100%",
            marginTop: "10px",
            height: "75%",
            overflowY: "scroll",
            // overflowX: "hidden",
          }}
          ref={printRef}
        >
          <div className="top-title-view-modal">
            End of Shift note
            {/* - {formatDate(groupNoteStartDateTime) */}{" "}
            <span style={{ fontSize: "14px" }}> done by :</span>
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {" "}
              {props.doneBy}
            </span>
          </div>
          <div className="viewBlock">
            <div className="titleInput">Done by</div>
            <div className="contentInput">{props.doneBy}</div>
          </div>
          <div className="viewBlock">
            <div className="titleInput">Start Date and Time</div>
            <div className="contentInput">
              {/* {formatDate(props.shiftStart)} */}
              {props.shiftStart}
            </div>
          </div>
          <div className="viewBlock">
            <div className="titleInput">End Date and Time</div>
            <div className="contentInput">
              {/* {formatDate(props.shiftEnd)} */}
              {props.shiftEnd}
            </div>
          </div>
          <div className="viewBlock">
            <div className="titleInput">Note</div>
            <div className="contentInput">{props.noteContent}</div>
          </div>
          <div className="viewBlock">
            <div className="titleInput">Staff</div>
            <div className="contentInput">{props.staff}</div>
          </div>
        </div>

        <div className="line4-modal-percent">
          <Button
            onClick={props.closemodal}
            variant="contained"
            style={{ backgroundColor: "white", color: "black" }}
          >
            Close
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" style={{ marginLeft: "20px" }}>
                Print
              </Button>
            )}
            content={() => printRef.current}
            onAfterPrint={props.closemodal}
          />
          {/* <Button
          onClick={handlePrint}
          variant="contained"
          style={{ marginLeft: "20px" }}
        >
          Print
        </Button> */}
        </div>
      </div>
    </>
  );
}

export default ViewEndOfShiftNote;
