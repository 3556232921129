import React, { useState, useEffect } from "react";
import { Button, TextField, Autocomplete, Avatar } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { useAuth } from "../../contexts/AuthContext";
import { showCustomToast } from "../Misc/CustomToast";
import SearchIcon from "@mui/icons-material/Search";
import { query, where, limit, onSnapshot } from "firebase/firestore";

function AddQuickNoteWithClients(props) {
  const { currentUser } = useAuth();
  const [quickNoteText, setQuickNoteText] = useState("");
  const [effectiveDateTime, setEffectiveDateTime] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const handleDateTimeChange = (event) => {
    setEffectiveDateTime(event);
  };

  const handleAddQuickNote = async (note, clientID, effectiveDateTime) => {
    const noteData = {
      noteType: "quickNote",
      noteContent: note,
      clientId: selectedClient ? selectedClient.id : clientID,
      createdAt: serverTimestamp(),
      effectiveDateTime: effectiveDateTime,
      doneBy: currentUser.displayName,
      clientFirstName: selectedClient
        ? selectedClient.firstName
        : props.firstName,
      clientLastName: selectedClient ? selectedClient.lastName : props.lastName,
      clientProfilePicture: selectedClient
        ? selectedClient.profilePicture
        : props.profilePicture || "",
    };

    if (
      quickNoteText &&
      effectiveDateTime &&
      (selectedClient || props.clientId)
    ) {
      setIsLoading(true);
      try {
        const docRef = await addDoc(collection(db, "notes"), noteData);
        showCustomToast(`Quick note successfully added!`, "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.log("error adding note :", error);
        showCustomToast(`Problem when adding note`, "error");
        setIsLoading(false); // Ajoutez cette ligne pour réinitialiser l'état de chargement en cas d'erreur
      }
    } else {
      showCustomToast(
        `Some mandatory fields are empty. Please check.`,
        "error"
      );
      setIsLoading(false); // Ajoutez cette ligne pour réinitialiser l'état de chargement en cas de champs obligatoires manquants
    }
  };

  const handleSubmit = () => {
    if (selectedClient || props.clientId) {
      handleAddQuickNote(quickNoteText, props.clientId, effectiveDateTime);
    } else {
      showCustomToast(`Client ID is missing. Please check.`, "error");
      setIsLoading(false); // Ajoutez cette ligne pour réinitialiser l'état de chargement si le client n'est pas sélectionné
    }
  };

  const handleChange = (event) => {
    setQuickNoteText(event.target.value);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [clients, setClients] = useState([]);
  const [, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientName, setSelectedClientName] = useState("");
  const [unsubscribe, setUnsubscribe] = useState(null);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500); // 500ms de délai

    // Nettoyer le timeout lors de la mise à jour de searchQuery
    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  useEffect(() => {
    let newUnsubscribe;

    if (debouncedSearchQuery.length > 0) {
      setLoading(true);

      const search = query(
        collection(db, "clients"),
        where(
          "searchTerms",
          "array-contains-any",
          debouncedSearchQuery.trim().toLowerCase().split(" ")
        ),
        limit(10)
      );

      newUnsubscribe = onSnapshot(search, (snapshot) => {
        const results = [];
        snapshot.forEach((doc) => {
          results.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setClients(results);
        setLoading(false);
      });
    } else {
      setClients([]);
    }

    setUnsubscribe(() => newUnsubscribe);
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [debouncedSearchQuery]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setSelectedClient(null);
    setSelectedClientName("");
  };

  const handleSelect = (event, value) => {
    if (value) {
      const client = clients.find(
        (client) => `${client.firstName} ${client.lastName}` === value
      );
      if (client) {
        setSelectedClient(client);
        setSelectedClientName(`${client.firstName} ${client.lastName}`);
      } else {
        setSelectedClient(null);
        setSelectedClientName("");
      }
    } else {
      setSelectedClient(null);
      setSelectedClientName("");
    }
  };

  return (
    <>
      <div style={{ width: "auto", height: "100%" }}>
        <div className="line1-modal-percent">
          <div className="modal-title">Add Quick Note</div>
          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "10px",
            height: "75%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                width: "48%",
                paddingLeft: "25px",
                paddingTop: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
              }}
            >
              <Autocomplete
                freeSolo
                disableClearable
                options={clients.map(
                  (client) => `${client.firstName} ${client.lastName}`
                )}
                renderOption={(props, option, { selected }) => {
                  const client = clients.find(
                    (client) =>
                      `${client.firstName} ${client.lastName}` === option
                  );

                  return (
                    <li
                      {...props}
                      key={client.id}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #BEBCBC",
                      }}
                    >
                      {client.profilePicture && (
                        <Avatar
                          src={client.profilePicture}
                          style={{
                            marginRight: "10px",
                            width: "50px",
                            height: "50px",
                          }}
                        />
                      )}
                      <div style={{ width: "220px" }}>
                        {`${client.firstName} ${client.lastName}`}
                      </div>
                      {client.clientIdentifier}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <div
                    ref={params.InputProps.ref}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="text"
                      {...params.inputProps}
                      onChange={handleSearchChange}
                      value={searchQuery}
                      placeholder="Search client…"
                      style={{
                        width: "100%",
                        height: "45px",
                        borderRadius: "5px",
                        paddingLeft: "10px",
                        background: "#FFFFFF",
                        border: "1px solid #BEBCBC",
                      }}
                    />
                    <SearchIcon />
                  </div>
                )}
                onChange={handleSelect}
              />

              {selectedClientName && (
                <div style={{ marginTop: "10px", fontWeight: "bold" }}>
                  Selected Client: {selectedClientName}
                </div>
              )}
            </div>
            <div
              style={{
                width: "48%",
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "40px",
                paddingBottom: "10px",
              }}
            >
              <DatePickerWithTimeComponent
                title="Effective date and time"
                value={effectiveDateTime}
                onChange={handleDateTimeChange}
                leftMargin="none"
              />
            </div>
          </div>

          <div className="line2-column-modal-v2">
            <div className="label-textfield2">Note content</div>
            <TextField
              name="quickNote"
              value={quickNoteText}
              onChange={handleChange}
              style={{
                width: "95%",
                borderRadius: "5px",
                backgroundColor: "#f2eeef",
              }}
              placeholder="Type quick session note here"
              multiline
              rows={12}
            />
            {/* <div
              className="line3-modal-nopad"
              style={{
                paddingTop: "10px",
                marginBottom: "20px",
              }}
            >
              <DatePickerWithTimeComponent
                title="Effective date and time"
                value={effectiveDateTime}
                onChange={handleDateTimeChange}
                leftMargin="none"
              />
            </div> */}
          </div>
        </div>
        <div className="line4-modal-percent">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? "ADDING NOTE..." : "ADD NOTE"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddQuickNoteWithClients;
