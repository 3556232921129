import React, { useState, useEffect } from "react";
import { LanguageContext } from "./LanguageContext";
import { translations } from "../api/translations";

export const LanguageProvider = ({ children }) => {
  const defaultLanguage = navigator.language.startsWith("fr")
    ? "fr"
    : navigator.language.startsWith("de")
    ? "de"
    : navigator.language.startsWith("es")
    ? "es"
    : "en";
  const [language, setLanguageState] = useState(defaultLanguage);
  console.log("Browser language:", defaultLanguage);
  const setLanguage = (newLanguage) => {
    setLanguageState(newLanguage);
  };
  console.log("Browser language:", defaultLanguage);
  console.log("language:", language);

  useEffect(() => {
    console.log("Updated language:", language);
  }, [language]); // Cette dépendance assure que le code s'exécute chaque fois que `language` change.

  const translate = (key) => translations[language][key] || key;

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translate }}>
      {children}
    </LanguageContext.Provider>
  );
};
