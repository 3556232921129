import React, { useState } from "react";
import { doc, deleteDoc, getDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { getStorage } from "firebase/storage";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";

const DeleteMedicalNote = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleDeleteFamilyNote = async () => {
    setIsLoading(true);
    try {
      const noteDoc = doc(db, "notes", props.noteId);
      const noteData = (await getDoc(noteDoc)).data();

      if (noteData.fileUrls && noteData.fileUrls.length > 0) {
        console.log("Deleting files from storage...");
        const storage = getStorage();
        for (const file of noteData.fileUrls) {
          const fileRef = ref(
            storage,
            `Documents/medicalNotes/${props.noteId}/${file.name}`
          );
          console.log(`Deleting file: ${file.name}`);
          await deleteObject(fileRef);
        }
        console.log("Files deleted from storage.");
      } else {
        console.log("No files to delete from storage.");
      }

      await deleteDoc(noteDoc);
      showCustomToast();

      showCustomToast(`Medical note successfully deleted!`, "success");

      setIsLoading(false);
      props.closemodal();
    } catch (error) {
      console.log("error deleting family note :", error);
      showCustomToast(`Problem when deleting family note`, "error");

      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="inputAndLabelModalContainerTitle2">
        <div className="line1-modal-red">
          <div className="modal-title">
            Delete Note{" "}
            <span style={{ fontSize: "10px" }}>( {props.noteId})</span>
            {/* <span style={{ fontSize: "10px" }}>(note Id: {props.noteId})</span> */}
          </div>

          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.closemodal}
          />
        </div>
        <div className="line2-column-modal">
          <div className="confirmation-delete">
            Are you sure you want to delete this note ?
          </div>
        </div>
      </div>

      <div className="line4-modal">
        <>
          <Button
            variant="contained"
            style={{ backgroundColor: "white", color: "black" }}
            // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
            onClick={props.closemodal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={
              isLoading
                ? {
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "15px",
                  }
                : {
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "15px",
                  }
            }
            disabled={isLoading}
            onClick={handleDeleteFamilyNote}
          >
            {isLoading ? "DELETING NOTE..." : "DELETE NOTES"}
          </Button>
        </>
      </div>
    </>
  );
};

export default DeleteMedicalNote;
