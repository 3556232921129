import React, { useRef, useState } from "react";
import { Button, Checkbox, CircularProgress } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { getDoc, doc, collection } from "firebase/firestore";
import { useEffect } from "react";
import { db } from "../../firebase";
import DatePickerComponent from "../DatePickerComponent";
import ReactToPrint from "react-to-print";
import TopTitleFormComponentMobileV2 from "./TopTitleFormComponentMobileV2";

function ViewEmergencyDischargeReportMobileV2(props) {
  //Tous les states des données récupérées par client

  const clientIdState = props.clientId;
  const [isLoading, setIsLoading] = useState(false);
  const [voluntaryDischarge, setVoluntaryDischarge] = useState(null);
  const [, setVoluntaryDischargeAcknoledgment] = useState(false);
  const [, setTreatmentAcknowledge] = useState(false);
  const [, setReleaseAgreement] = useState(false);
  const [finalUnderstanding, setFinalUnderstanding] = useState(false);
  const [signatureClientUrl, setSignatureClientURL] = useState("");

  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");

  const printRef = useRef(); // Ajouter cette ligne

  function formatDate(timestamp) {
    const date = timestamp.toDate();
    const day = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    // Ajout du fuseau horaire
    const timeZone = date
      .toLocaleString("en", { timeZoneName: "short" })
      .split(" ")
      .pop();

    return `${day} ${month} ${year} ${hours}:${minutes} ${timeZone}`;
  }

  useEffect(() => {
    // fetchSignatureUrl();
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDatas = async () => {
    setIsLoading(true);

    try {
      const dischargeFormsRef = collection(db, "EmergencyDischarge");
      const dischargeFormDocRef = doc(dischargeFormsRef, clientIdState);

      const dischargeDataSnapshot = await getDoc(dischargeFormDocRef);

      if (dischargeDataSnapshot.exists()) {
        const dischargeData = dischargeDataSnapshot.data();

        setText1(dischargeData.text1);
        setText2(dischargeData.text2);
        setText3(dischargeData.text3);
        setVoluntaryDischarge(dischargeData.voluntaryDischarge);
        setVoluntaryDischargeAcknoledgment(
          dischargeData.voluntaryDischargeAcknoledgment
        );
        setReleaseAgreement(dischargeData.releaseAgreement);
        setTreatmentAcknowledge(dischargeData.treatmentAcknowledge);
        setFinalUnderstanding(dischargeData.finalUnderstanding);

        setSignatureClientURL(dischargeData.signatureClientUrl);
      } else {
        console.error("No data found for the specified client ID.");
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error retrieving data:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <style>
        {`
          @media print {
            #printableContent {
              height: auto !important;
              overflow-y: visible !important;
            }
          }
        `}
      </style>
      <div style={{ width: "auto", height: "100%" }}>
        <div
          className="line1-modal-percent"
          style={{ borderBottom: "1px solid lightgrey" }}
        >
          <TopTitleFormComponentMobileV2
            title="EMERGENCY DISCHARGE FORM"
            closemodal={props.closemodal}
          />
        </div>

        <div
          id="printableContent"
          style={{
            width: "100%",
            marginTop: "10px",
            height: "80%",
            overflowY: "scroll",
            // overflowX: "hidden",
          }}
          ref={printRef}
        >
          {isLoading ? (
            <div className="circular-progress-container">
              <CircularProgress />
              <div className="loading-text">Loading, please wait...</div>
            </div>
          ) : (
            <>
              <div
                className="client-name-container"
                style={{ fontSize: "16px" }}
              >
                Emergency discharge form for: {props.firstName} {props.lastName}
              </div>
              <div className="tab-form-container" style={{ fontSize: "14px" }}>
                <div className="checkbox-container-margin">
                  <Checkbox checked={true} /> {text1}
                </div>
              </div>
              <div className="tab-form-container" style={{ fontSize: "14px" }}>
                <div className="checkbox-container-margin">
                  <Checkbox checked={true} /> {text2}
                </div>
              </div>

              <div className="tab-form-container">
                <div style={{ marginLeft: 20 }}>
                  <DatePickerComponent
                    title="Date of voluntary discharge/leaving the treatment facility"
                    width="400"
                    isRequired={true}
                    value={voluntaryDischarge}
                    // onChange={handleDischargeDateChange}
                  />
                </div>
              </div>
              <div className="tab-form-container">
                <div className="checkbox-container-margin">
                  <Checkbox checked={true} /> {text3}
                </div>
              </div>

              <div className="tab-form-container">
                <div className="checkbox-container-margin">
                  <Checkbox
                    checked={finalUnderstanding}
                    // onChange={handleFinalUnderstandingChange}
                  />{" "}
                  I,{" "}
                  <span style={{ fontWeight: "bold" }}>{props.firstName}</span>
                  &ensp;
                  <span style={{ fontWeight: "bold" }}>{props.lastName}</span>,
                  fully understand and agree to the above terms.
                </div>
              </div>

              <div className="signature-date-container">
                <div className="text-block-2">
                  Timestamp signature :{" "}
                  {formatDate(props.EmergencyDischargeDateSigned)}{" "}
                </div>
                <div className="signature-title">
                  Client signature (
                  <span style={{ fontWeight: "bold" }}>
                    {props.firstName} {props.lastName}
                  </span>
                  ):
                </div>
                <img
                  alt="Signature"
                  className="signature-pic-roi"
                  src={signatureClientUrl}
                />
              </div>
            </>
          )}
        </div>

        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              onClick={props.closemodal}
            >
              Cancel
            </Button>

            <ReactToPrint
              trigger={() => (
                <Button variant="contained" style={{ marginLeft: "20px" }}>
                  Print
                </Button>
              )}
              content={() => printRef.current}
              onAfterPrint={props.closemodal}
            />
          </>
        </div>
      </div>
    </>
  );
}

export default ViewEmergencyDischargeReportMobileV2;
