import React, { useState } from "react";
import RoomLayoutMapMobile from "./RoomLayoutMapMobile";
import RoomLayoutMap2Mobile from "./RoomLayoutMap2Mobile";
export default function CasadaSwitchMobile() {
  const [selectedCasada] = useState("Casada 1"); // État pour le switch

  return (
    <div
      className="main-top-title-banner-container"
      style={{ paddingTop: "10px" }}
    >
      {selectedCasada === "Casada 1" ? (
        <RoomLayoutMapMobile />
      ) : (
        <RoomLayoutMap2Mobile />
      )}
    </div>
  );
}
