import React, { useContext, useEffect, useState } from "react";
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../firebase";
import { format } from "date-fns";
import { Autocomplete, Button, Menu, MenuItem, TextField } from "@mui/material";
import TitleComponentV2 from "../Misc/TitleComponentV2";
import FilterListIcon from "@mui/icons-material/FilterList";
import { styled, alpha } from "@mui/material/styles";
import StorageIcon from "@mui/icons-material/Storage";
import ClientRowDetailsV2 from "../Misc/ClientRowDetailsV2";
import FlipNumbers from "react-flip-numbers";
import { Skeleton } from "@mui/material";
import { LanguageContext } from "../../context/LanguageContext";
import TitleNoteCompactClientMobile from "../Notes/TitleNoteCompactClientMobile";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    padding: "20px 0", // Ajout de padding en haut et en bas
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function AllClientMobile({ viewMode, ...props }) {
  const clientTypes = [
    "In House",
    "Aftercare",
    "Incoming",
    "Clinical Assessment",
    "Potential",
    "Inactive",
  ]; // Ajouter tous les types de clients ici

  const { translate } = useContext(LanguageContext);
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientDisplayLimit, setClientDisplayLimit] = useState(10);
  const [readCount] = useState(0); // Ajouté
  // const [clients, setClients] = useState({ data: [], lastVisible: null });
  const [, setTotalRecords] = useState(0);
  const [selectedClientType, setSelectedClientType] = useState(["In House"]);
  const [selectedTherapist, setSelectedTherapist] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAddiction, setSelectedAddiction] = useState([]);
  const [selectedILS, setSelectedILS] = useState("");

  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const resetFilters = () => {
    setSelectedClientType([]);
    setSelectedTherapist("");
    setSelectedAddiction([]);
    setSelectedILS("");
  };

  const filteredClients = clients.filter((client) => {
    const matchesType = selectedClientType.length
      ? selectedClientType.includes(client.clientStatus)
      : true;
    const matchesTherapist = selectedTherapist
      ? client.selectedTherapist === selectedTherapist
      : true;
    const matchesAddiction = selectedAddiction.length
      ? selectedAddiction.includes(client.primaryAddiction)
      : true;
    const matchesILS = selectedILS
      ? client.durationOfStay === parseInt(selectedILS)
      : true;

    return matchesType && matchesTherapist && matchesAddiction && matchesILS;
  });

  const handleSort = (order) => {
    let sortedClients = [...clients];
    switch (order) {
      case "clientAsc":
        sortedClients.sort((a, b) => a.firstName.localeCompare(b.firstName));
        break;
      case "clientDesc":
        sortedClients.sort((a, b) => b.firstName.localeCompare(a.firstName));
        break;
      case "addictionAsc":
        sortedClients.sort((a, b) =>
          a.primaryAddiction.localeCompare(b.primaryAddiction)
        );
        break;
      case "addictionDesc":
        sortedClients.sort((a, b) =>
          b.primaryAddiction.localeCompare(a.primaryAddiction)
        );
        break;
      case "akaAsc":
        sortedClients.sort((a, b) => a.nickname.localeCompare(b.nickname));
        break;
      case "akaDesc":
        sortedClients.sort((a, b) => b.nickname.localeCompare(a.nickname));
        break;
      case "admissionAsc":
        sortedClients.sort(
          (a, b) =>
            new Date(
              a.dateOfAdmission.seconds * 1000 +
                a.dateOfAdmission.nanoseconds / 1000000
            ) -
            new Date(
              b.dateOfAdmission.seconds * 1000 +
                b.dateOfAdmission.nanoseconds / 1000000
            )
        );
        break;
      case "admissionDesc":
        sortedClients.sort(
          (a, b) =>
            new Date(
              b.dateOfAdmission.seconds * 1000 +
                b.dateOfAdmission.nanoseconds / 1000000
            ) -
            new Date(
              a.dateOfAdmission.seconds * 1000 +
                a.dateOfAdmission.nanoseconds / 1000000
            )
        );
        break;
      case "pddAsc":
        sortedClients.sort(
          (a, b) =>
            new Date(
              a.projectedResidentialDischargeDate.seconds * 1000 +
                a.projectedResidentialDischargeDate.nanoseconds / 1000000
            ) -
            new Date(
              b.projectedResidentialDischargeDate.seconds * 1000 +
                b.projectedResidentialDischargeDate.nanoseconds / 1000000
            )
        );
        break;
      case "pddDesc":
        sortedClients.sort(
          (a, b) =>
            new Date(
              b.projectedResidentialDischargeDate.seconds * 1000 +
                b.projectedResidentialDischargeDate.nanoseconds / 1000000
            ) -
            new Date(
              a.projectedResidentialDischargeDate.seconds * 1000 +
                a.projectedResidentialDischargeDate.nanoseconds / 1000000
            )
        );
        break;
      case "ilsAsc":
        sortedClients.sort((a, b) => a.durationOfStay - b.durationOfStay);
        break;
      case "ilsDesc":
        sortedClients.sort((a, b) => b.durationOfStay - a.durationOfStay);
        break;
      default:
        break;
    }
    setClients(sortedClients);
  };

  const handleShowMoreClients = () => {
    setClientDisplayLimit((prevLimit) => prevLimit + 10);
  };

  const handleShowLessClients = () => {
    setClientDisplayLimit((prevLimit) => Math.max(prevLimit - 10, 10));
  };

  useEffect(() => {
    const clientCollection = collection(db, "clients");
    getDocs(clientCollection).then((snapshot) => {
      setTotalRecords(snapshot.size);
    });
    const q = query(collection(db, "clients"), orderBy("dateOfAdmission"));
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const clientDataWithAlerts = await Promise.all(
        querySnapshot.docs.map(async (clientDoc) => {
          const clientData = clientDoc.data();
          const alertsSnapshot = await getDocs(
            collection(clientDoc.ref, "alerts")
          );
          const alerts = alertsSnapshot.docs.map((alertDoc) => alertDoc.data());
          return { ...clientData, alerts, id: clientDoc.id };
        })
      );
      setClients(clientDataWithAlerts);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    console.log(`Nombre de lectures Firestore dans all clients : ${readCount}`);
  }, [readCount]);

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          paddingBottom: "20px",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ paddingTop: "5px" }}>
            <StorageIcon
              style={{ marginRight: "8px", color: "#a3a3a4", fontSize: "18px" }}
            />
          </div>
          <div
            style={{ fontWeight: "650", fontSize: "22px", paddingRight: "5px" }}
          >
            <FlipNumbers
              height={20}
              width={10}
              color="#000000"
              background="white"
              play
              perspective={1000}
              numbers={filteredClients.length.toString()}
            />
          </div>{" "}
          <div
            style={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#a3a3a4",
              lineHeight: "14px",
              paddingTop: "2px",
              paddingLeft: "5px",
            }}
          >
            {" "}
            {`${translate("TotalRecord")}${
              filteredClients.length > 1 ? "s" : ""
            }`}
          </div>
        </div>

        <div style={{ marginBottom: "0px" }}>
          <Button
            id="filters-button"
            aria-controls={openMenu ? "filters-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            variant="outlined"
            color="primary"
            disableElevation
            onClick={handleMenuClick}
            endIcon={<FilterListIcon sx={{ fontSize: "8px" }} />}
            sx={{ fontSize: "10px" }}
          >
            {translate("Filters")}
          </Button>
          <StyledMenu
            id="filters-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "filters-button",
            }}
          >
            <MenuItem style={{ justifyContent: "flex-end" }}>
              <Autocomplete
                multiple
                options={clientTypes}
                getOptionLabel={(option) => option}
                value={selectedClientType}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate("SelectClientType")}
                    variant="outlined"
                    style={{ width: 250 }}
                    fullWidth
                  />
                )}
                onChange={(event, value) => setSelectedClientType(value)}
              />
            </MenuItem>
            <MenuItem style={{ justifyContent: "flex-end" }}>
              <Autocomplete
                options={clients.map((client) => client.selectedTherapist)}
                getOptionLabel={(option) => option}
                value={selectedTherapist}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate("SelectTherapist")}
                    variant="outlined"
                    style={{ width: 250 }}
                    fullWidth
                  />
                )}
                onChange={(event, value) => setSelectedTherapist(value)}
              />
            </MenuItem>
            <MenuItem style={{ justifyContent: "flex-end" }}>
              <Autocomplete
                multiple
                options={[
                  ...new Set(clients.map((client) => client.primaryAddiction)),
                ]}
                getOptionLabel={(option) => option}
                value={selectedAddiction}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={translate("SelectAddiction")}
                    variant="outlined"
                    style={{ width: 250 }}
                    fullWidth
                  />
                )}
                onChange={(event, value) => setSelectedAddiction(value)}
              />
            </MenuItem>
            <MenuItem style={{ justifyContent: "flex-end" }}>
              <TextField
                label={translate("SelectILS")}
                variant="outlined"
                type="number"
                value={selectedILS}
                onChange={(event) => setSelectedILS(event.target.value)}
                style={{ width: 250 }}
                fullWidth
              />
            </MenuItem>
            <MenuItem
              style={{ justifyContent: "flex-end" }}
              onClick={resetFilters}
            >
              <Button variant="outlined" onClick={resetFilters}>
                {translate("ResetFilters")}
              </Button>
            </MenuItem>
          </StyledMenu>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
      >
        <TitleNoteCompactClientMobile handleSort={handleSort} />
      </div>

      {isLoading ? (
        Array.from({ length: 10 }).map((_, index) => (
          <div key={index} style={{ paddingLeft: "15px", width: "100%" }}>
            <div
              style={{
                width: "90%",
                cursor: "pointer",
                marginBottom: "0px",
                borderBottom: "1px solid lightgrey",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "5px",
                paddingLeft: "0px",
                color: "grey",
                fontSize: "14px",
                fontWeight: "450",
                height: "60px",
                backgroundColor: "white",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={50}
                height={50}
                style={{ marginRight: "10px", borderRadius: "8px" }}
              />
              <Skeleton variant="text" width="18%" height={30} />
              <Skeleton variant="text" width="11%" height={30} />
              <Skeleton variant="text" width="7%" height={30} />
              <Skeleton variant="text" width="5%" height={30} />
              <Skeleton variant="text" width="11%" height={30} />
              <Skeleton variant="text" width="11%" height={30} />
              <Skeleton variant="text" width="5%" height={30} />
              <Skeleton variant="text" width="9%" height={30} />
              <Skeleton variant="text" width="8%" height={30} />
              <Skeleton variant="text" width="10%" height={30} />
            </div>
          </div>
        ))
      ) : (
        <div
          className="client-widgets-global-container"
          style={{ paddingLeft: "0px" }}
        >
          {viewMode ? <TitleComponentV2 /> : ""}
          {filteredClients.slice(0, clientDisplayLimit).map((client) => (
            <ClientRowDetailsV2
              key={client.id}
              clientStatus={client.clientStatus}
              firstName={client.firstName}
              lastName={client.lastName}
              gender={client.gender}
              nationality={client.nationality}
              country={client.country}
              primaryAddiction={client.primaryAddiction}
              secondaryAddiction={client.secondaryAddiction}
              inActiveRecovery={client.inActiveRecovery}
              eta={formatDate(client.eta)}
              googleReviewDone={client.googleReviewDone}
              personalEmail={client.personalEmail}
              admissionDate={formatDate(client.dateOfAdmission)}
              durationOfStay={client.durationOfStay}
              flight={client.flight}
              paymentDetail={client.paymentDetail}
              vipTransfer={client.vipTransfer}
              passportStatus={client.passportStatus}
              profilPicture={client.profilePicture}
              aka={client.nickname}
              idClient={client.id}
              clientData={client}
              idd={formatDate(client.idd)}
              ils={client.durationOfStay}
              roomNumber={client.roomNumber}
              assignedTherapist={client.selectedTherapist}
              arrivalDate={formatDate(client.dateOfAdmission)}
              dob={client.dob}
              projectedResidentialDischargeDate={formatDate(
                client.projectedResidentialDischargeDate
              )}
              assignedSupportStaff={client.assignedSupportWorker}
            />
          ))}
        </div>
      )}

      <div style={{ display: "flex", flexDirection: "row" }}>
        {!isLoading && filteredClients.length > clientDisplayLimit && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "20px",
              paddingLeft: "40px",
              marginBottom: "25px",
            }}
          >
            <Button
              onClick={handleShowMoreClients}
              variant="outlined"
              style={{
                padding: "5px 10px",
                fontSize: "12px",
                cursor: "pointer",
              }}
            >
              {translate("ShowMore")}
            </Button>
          </div>
        )}
        {!isLoading && clientDisplayLimit > 10 && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "20px",
              paddingLeft: "40px",
              marginBottom: "40px",
            }}
          >
            <Button
              onClick={handleShowLessClients}
              variant="outlined"
              style={{
                padding: "5px 10px",
                fontSize: "12px",
                cursor: "pointer",
              }}
            >
              {translate("ShowLess")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AllClientMobile;
