import { doc, updateDoc } from "firebase/firestore";
import {
  deleteObject,
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import React, { useState } from "react";
import { db } from "../../firebase";
import DeleteButtonFile from "../Buttons/DeleteButtonFile";
import TopTitleFormComponent from "../forms/TopTitleFormComponent";
import { Button } from "@mui/material";
import { showCustomToast } from "../Misc/CustomToast";

const maxSizePicFile = 5 * 1024 * 1024; // 5 Mo

function UpdloaderPsychosocial(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileLinks, setFileLinks] = useState(props.fileUrlsBioPsycho || []);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files.length <= 5) {
      setFiles(event.target.files);
    } else {
      showCustomToast("You can only upload up to 5 files", "error");
    }
  };

  const deleteFile = async (fileUrl, fileName, clientId) => {
    const storage = getStorage();
    const filePath = `Documents/ScannedBioPsychosocialForms/${clientId}/${fileName}`;
    const storageRef = ref(storage, filePath);

    try {
      await deleteObject(storageRef);
      const newFileLinks = fileLinks.filter((file) => file.url !== fileUrl);
      setFileLinks(newFileLinks);
      const clientRef = doc(db, "clients", clientId);

      // Modifier cette ligne pour inclure les noms des fichiers
      await updateDoc(clientRef, {
        fileUrlsBioPsycho: newFileLinks.map((file) => ({
          url: file.url,
          name: file.name,
        })),
      });

      showCustomToast("File successfully deleted!", "success");
    } catch (error) {
      console.log("Error deleting file :", error);
      showCustomToast("Error deleting file", "error");
    }
  };

  const uploadFilesAndGetUrls = async (docId) => {
    const storage = getStorage();
    const fileUrls = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > maxSizePicFile) {
        showCustomToast(`File size exceeds the limit: ${file.name}`, "error");

        console.error(`File size exceeds the limit: ${file.name}`);
        continue; // Passez au fichier suivant dans la boucle
      }
      const filePath = `Documents/ScannedBioPsychosocialForms/${docId}/${file.name}`;
      const storageRef = ref(storage, filePath);

      await uploadBytes(storageRef, file);
      const fileUrl = await getDownloadURL(storageRef);
      fileUrls.push({ url: fileUrl, name: file.name });
    }

    return fileUrls;
  };

  const updateClientData = async (clientId) => {
    setIsLoading(true);
    if (files.length > 0) {
      try {
        const clientRef = doc(db, "clients", clientId);
        const fileUrls = await uploadFilesAndGetUrls(clientRef.id);
        const existingFileUrls = fileLinks;
        const mergedFileUrls = [...existingFileUrls, ...fileUrls];

        await updateDoc(clientRef, {
          fileUrlsBioPsycho: mergedFileUrls,
        });

        setFileLinks(mergedFileUrls);
        showCustomToast("Files successfully uploaded!", "success");
      } catch (error) {
        console.error("Error updating client: ", error);
        showCustomToast(`Error updating client: ${error}`, "error");
      }
    } else {
      showCustomToast("No files selected for upload.", "error");
    }
    setIsLoading(false);
  };

  return (
    <div className="uploader-roi">
      <TopTitleFormComponent
        closemodal={props.closemodal}
        title="UPLOAD SCANNED BIOPSYCHOSOCIAL FORMS"
      />

      <div className="uploader-component-general-infos">
        <div className="form3">
          <div className="line5-modal">
            <div className="form-container">
              <div className="input-file-container">
                <label
                  htmlFor="uploadFiles"
                  style={{
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "8px",
                    marginTop: "20px",
                  }}
                >
                  Upload BioPsychoSocialForms file(s) (max 5)
                </label>
                <input
                  type="file"
                  id="uploadFiles"
                  multiple
                  onChange={handleFileChange}
                  accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt,.csv,.zip,.rar,.7z"
                  style={{
                    width: "0.1px",
                    height: "0.1px",
                    opacity: 0,
                    overflow: "hidden",
                    position: "absolute",
                    zIndex: -1,
                  }}
                />
                {/* <input
                  type="file"
                  multiple
                  accept=".png, .jpg, .jpeg, .pdf"
                  onChange={handleFileChange}
                /> */}
              </div>
              <div className="btn-upload-container">
                <label htmlFor="uploadFiles" className="custom-file-upload">
                  Select file(s)
                </label>
              </div>
            </div>
            <div className="line5-modal">
              <div className="form-container">
                {files.length > 0 && (
                  <div className="select-title">
                    Selected files:
                    <ul className="ul-container">
                      {Array.from(files).map((file) => (
                        <a className="a-container" key={file.name}>
                          <span
                            className="ellipsis"
                            style={{
                              maxWidth: "calc(30ch)",
                              color: "black",
                              fontWeight: "500",
                            }}
                          >
                            {file.name}
                          </span>
                        </a>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="form4">
          <label
            htmlFor="uploadFiles"
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "8px",
              marginTop: "20px",
            }}
          >
            Document(s) already uploaded :
          </label>

          {fileLinks && fileLinks !== null && fileLinks !== undefined ? (
            fileLinks.map((fileLink, index) => (
              <div key={index} className="file-item2">
                File :
                {fileLink.name.match(/\.(jpg|jpeg|png|gif)$/i) && (
                  <a href={fileLink.url} target="_blank" rel="noreferrer"></a>
                )}
                <a
                  className="ellipsis2"
                  style={{
                    marginLeft: "0px",
                    marginRight: "0px",
                    width: "80%",
                  }}
                  href={fileLink.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {fileLink.name}
                </a>
                <DeleteButtonFile
                  onClick={() =>
                    deleteFile(fileLink.url, fileLink.name, props.clientId)
                  }
                  fileName={fileLink.name}
                />
              </div>
            ))
          ) : (
            <div>No files found</div>
          )}
        </div>
      </div>

      <div className="bottom-button-uploader">
        <Button
          variant="contained"
          style={{
            backgroundColor: "white",
            color: "black",
            marginBottom: "10px",
          }}
          onClick={props.closemodal}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          // onClick={props.onSave}
          onClick={() => updateClientData(props.clientId)}
          style={
            isLoading
              ? {
                  backgroundColor: "red",
                  color: "white",
                  marginLeft: "15px",
                  marginBottom: "10px",
                }
              : {
                  backgroundColor: "#1565C0",
                  color: "white",
                  marginLeft: "15px",
                  marginBottom: "10px",
                }
          }
          disabled={isLoading}
        >
          {isLoading ? "UPLOADING..." : "UPLOAD"}
        </Button>
      </div>
    </div>
  );
}

export default UpdloaderPsychosocial;
