import React from "react";
import "../css/dashboard.css";
import KabanMobileV2 from "../component/Misc/KabanMobileV2";

export default function CrmContentMobileV2() {
  return (
    <div className="right-container-mobile">
      <KabanMobileV2 />
    </div>
  );
}
