// src/component/LoadingOverlay.jsx

import React from "react";
import { CircularProgress } from "@mui/material";

export default function LoadingOverlay() {
  return (
    <div style={overlayStyle}>
      <CircularProgress
        style={{ color: "#fff" }}
        size={60}
        thickness={4.5}
      />
    </div>
  );
}

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.4)", // Fond gris avec opacité
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 9999, // Assurez-vous d'être au-dessus du reste de l'UI
};
