import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function PhoneNumberInput(props) {
  const handleChange = (event) => {
    props.onChange(event);
  };

  return (
    <div className="input-label-container">
      <div
        style={{
          color: "#a0a0a0",
          fontWeight: "500",
          fontSize: "12px",
          textTransform: "uppercase",
          paddingBottom: "3px",
        }}
      >
        {props.title}{" "}
        {props.isRequired && <span style={{ color: "red" }}>*</span>}
      </div>

      <PhoneInput
        className="phone-input"
        value={props.value}
        onChange={handleChange}
        placeholder={props.placeholder}
        style={{
          width: props.width || "100%", // Utiliser la prop width, avec une valeur par défaut
          backgroundColor: "#fafafa", // Fond de couleur
          border: "none",
        }}
      />
    </div>
  );
}
