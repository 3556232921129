import React from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function PhoneNumberInputNoTitle(props) {
  const handleChange = (event) => {
    props.onChange(event);
  };

  return (
    <div className="input-label-container-newdesign">
      <PhoneInput
        className="phone-input"
        value={props.value}
        onChange={handleChange}
        placeholder={props.placeholder}
      />
    </div>
  );
}
