import React, { useRef, useState } from "react";
import { Button, CircularProgress } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { getDoc, doc, collection } from "firebase/firestore";
import { useEffect } from "react";
import { db } from "../../firebase";
import RatingComponent from "../RatingComponent";
import ReactToPrint from "react-to-print";
import TopTitleFormComponentMobileV2 from "./TopTitleFormComponentMobileV2";

function ViewDischargeReportMobileV2(props) {
  //Tous les states des données récupérées par client

  const clientIdState = props.clientId;
  const [isLoading, setIsLoading] = useState(false);
  const [dischargeDate, setDischargeDate] = useState(null);
  const [dischargeType, setDischargeType] = useState("");
  const [dischargeTo, setDischargeTo] = useState("");
  const [, setMedication] = useState("");
  const [issuesUponAdmission, setIssuesUponAdmission] = useState("");
  const [emergingIssues, setEmergingIssues] = useState("");
  const [progress, setProgress] = useState("");
  const [familyInvolvement, setFamilyInvolvement] = useState("");
  const [roadblocks, setRoadblocks] = useState("");
  const [clientPrognosis, setClientPrognosis] = useState("");
  const [dischargePlan, setDischargePlan] = useState("");
  const [staffMemberName, setStaffMemberName] = useState("");
  const [rating, setRating] = useState(0);
  const [, setSignatureDataURL] = useState(null);
  const [dischargeTypeOther, setDischargeTypeOther] = useState("");
  const [dischargeToOther, setDischargeToOther] = useState("");
  const [selectedTherapist, setSelectedTherapist] = useState("");
  const [dateOfAdmission, setDateOfAdmission] = useState("");
  const [lengthOfStay, setLengthOfStay] = useState("");
  const printRef = useRef(); // Ajouter cette ligne
  function formatDate2(timestamp) {
    const date = timestamp.toDate();
    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const day = dayNames[date.getDay()];
    const dayNumber = date.getDate().toString().padStart(2, "0");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${day}, ${dayNumber}, ${month}, ${year}`;
  }

  useEffect(() => {
    // fetchSignatureUrl();
    fetchDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDatas = async () => {
    setIsLoading(true);

    try {
      const dischargeFormsRef = collection(db, "dischargeForms");
      const dischargeFormDocRef = doc(dischargeFormsRef, clientIdState);

      const dischargeDataSnapshot = await getDoc(dischargeFormDocRef);

      if (dischargeDataSnapshot.exists()) {
        const dischargeData = dischargeDataSnapshot.data();

        // Set state values using the retrieved data
        setDischargeDate(dischargeData.dischargeDate);
        setDischargeType(dischargeData.dischargeType);
        setDischargeTo(dischargeData.dischargeTo);
        setMedication(dischargeData.medication);
        setIssuesUponAdmission(dischargeData.issuesUponAdmission);
        setEmergingIssues(dischargeData.emergingIssues);
        setProgress(dischargeData.progress);
        setFamilyInvolvement(dischargeData.familyInvolvement);
        setRoadblocks(dischargeData.roadblocks);
        setClientPrognosis(dischargeData.clientPrognosis);
        setDischargePlan(dischargeData.dischargePlan);
        setRating(dischargeData.rating);
        setStaffMemberName(dischargeData.staffMemberName);
        setDischargeToOther(dischargeData.dischargeToOther);
        setDischargeTypeOther(dischargeData.dischargeTypeOther);
        setSelectedTherapist(dischargeData.selectedTherapist);
        setDateOfAdmission(dischargeData.dateOfAdmission);
        setLengthOfStay(dischargeData.lengthOfStay);

        // Fetch the signature URL and update the state
        const signatureUrl = dischargeData.signatureData;
        setSignatureDataURL(signatureUrl);
      } else {
        console.error("No data found for the specified client ID.");
      }

      setIsLoading(false);
    } catch (error) {
      console.error("Error retrieving data:", error);
      setIsLoading(false);
    }
  };

  const formattedDateOfAdmission = dateOfAdmission
    ? formatDate2(dateOfAdmission)
    : "";
  const formattedDischargeDate = dischargeDate
    ? formatDate2(dischargeDate)
    : "";

  return (
    <>
      <style>
        {`
          @media print {
            #printableContent {
              height: auto !important;
              overflow-y: visible !important;
            }
          }
        `}
      </style>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div
          className="line1-modal-percent"
          style={{ borderBottom: "1px solid lightgrey" }}
        >
          <TopTitleFormComponentMobileV2
            title="Discharge Summary Form"
            closemodal={props.closemodal}
          />
        </div>

        <div
          id="printableContent"
          style={{
            width: "100%",
            marginTop: "10px",
            height: "80%",
            overflowY: "scroll",
            // overflowX: "hidden",
          }}
          ref={printRef}
        >
          {isLoading ? (
            <div className="circular-progress-container">
              <CircularProgress />
              <div className="loading-text">Loading, please wait...</div>
            </div>
          ) : (
            <>
              <div
                className="client-name-container"
                style={{
                  marginLeft: "40px",
                  marginTop: "20px",
                  fontSize: "14px",
                }}
              >
                Discharge summary form for: {props.firstName} {props.lastName}
              </div>
              <div style={{ marginLeft: 40 }}>
                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Discharge Date:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {formattedDischargeDate}
                  </div>
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Discharge Type:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {dischargeType}
                  </div>
                  {dischargeType === "Other" ? dischargeTypeOther : ""}
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Discharge to:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {dischargeTo}
                  </div>
                  {dischargeTo === "Other" ? dischargeToOther : ""}
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Assigned Therapist:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {selectedTherapist}
                  </div>
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Date of admission:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {formattedDateOfAdmission}
                  </div>
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Total length of stay:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {lengthOfStay}
                  </div>
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Medication prescribed upon discharge:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {lengthOfStay}
                  </div>
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Identified issues upon admission:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {issuesUponAdmission}
                  </div>
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Identified issues upon admission:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {issuesUponAdmission}
                  </div>
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Emerging issues identified during treatment:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {emergingIssues}
                  </div>
                </div>

                <div
                  style={{
                    width: "100%",

                    marginLeft: "-30px",
                  }}
                >
                  <RatingComponent value={rating} />
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Progress:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {progress}
                  </div>
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Family / significant Other Involvement:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {familyInvolvement}
                  </div>
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Roadblocks/Concerns:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {roadblocks}
                  </div>
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Roadblocks/Concerns:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {roadblocks}
                  </div>
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Client prognosis:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {clientPrognosis}
                  </div>
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Recommended Discharge Plan:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {dischargePlan}
                  </div>
                </div>

                <div className="tab-form-container-viewmodule">
                  <div
                    className="SubTitleBold-viewmodule"
                    style={{ fontSize: "14px" }}
                  >
                    Staff Member Name:
                  </div>
                  <div
                    style={{
                      width: "auto",
                      height: "auto",
                      fontSize: "14px",
                    }}
                  >
                    {staffMemberName}
                  </div>
                </div>
              </div>
              {/* <div className="signature-date-container">
                <div className="text-block-2">
                  Timestamp signature :{" "}
                  {formatDate(props.DischargeReportDateSigned)}{" "}
                </div>
                <div className="signature-title">
                  Client signature (
                  <span style={{ fontWeight: "bold" }}>
                    {props.firstName} {props.lastName}
                  </span>
                  ):
                </div>
                <img
                  alt="Signature"
                  className="signature-pic-roi"
                  src={signatureDataURL}
                />
              </div> */}
            </>
          )}
        </div>

        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              onClick={props.closemodal}
            >
              Cancel
            </Button>

            <ReactToPrint
              trigger={() => (
                <Button variant="contained" style={{ marginLeft: "20px" }}>
                  Print
                </Button>
              )}
              content={() => printRef.current}
              onAfterPrint={props.closemodal}
            />
          </>
        </div>
      </div>
    </>
  );
}

export default ViewDischargeReportMobileV2;
