import { Avatar } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ChipStatus from "../ChipStatus";

function ClientRowDetails({
  clientStatus,
  firstName,
  lastName,
  primaryAddiction,
  profilPicture,
  idClient,
  clientData,
  aka,
  idd,
  ils,
  roomNumber,
  assignedTherapist,
  arrivalDate,
  dob,
  projectedResidentialDischargeDate,
}) {
  const navigate = useNavigate();
  const [shouldNavigate, setShouldNavigate] = React.useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleNavigate = () => {
    setShouldNavigate(true);
  };

  React.useEffect(() => {
    if (shouldNavigate) {
      navigate(`/client/${idClient}`, {
        state: clientData, // Passez l'objet client complet
      });
    }
  }, [shouldNavigate]);

  const handleNavigateToAlerts = () => {
    navigate(`/client/${idClient}`, {
      state: { clientData, selectedTab: "8" }, // 8 correspond à l'onglet Alerts
    });
  };

  const fullName = firstName + " " + lastName;
  const textStyle = {
    width: "95px",
    paddintRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "center",
  };

  const textStyle2 = {
    width: "95px",
    paddingRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    justifyContent: "center",
  };

  const textStyle4 = {
    width: "110px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: "10px",
    paddingLeft: "5px",
    textAlign: "center",
  };

  const textStyle5 = {
    width: "120px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: "10px",
    fontSize: "12px",
    textAlign: "center",
  };

  const textStyle6 = {
    width: "120px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: "10px",
    fontSize: "14px",
    fontWeight: "500",
    color: "black",
  };

  const alertCounts = {
    reminders: (clientData.alerts || []).filter((alert) => alert.type === 1)
      .length,
    allergies: (clientData.alerts || []).filter((alert) => alert.type === 2)
      .length,
    alerts: (clientData.alerts || []).filter((alert) => alert.type === 3)
      .length,
  };

  const getAgeFromDOB = () => {
    if (!dob) {
      return "---";
    }

    const dobDate = dob.toDate(); // Convert Firestore timestamp to JavaScript Date
    const diff = Date.now() - dobDate.getTime();
    const ageDate = new Date(diff);

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const [age] = useState(getAgeFromDOB());

  return (
    <div
      style={{
        paddingLeft: "15px",
        cursor: "pointer",
        marginBottom: "5px",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleNavigate}
    >
      <div
        style={{
          width: "90%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "5px",
          // padding: "10px",
          paddingLeft: "10px",
          color: "grey",
          fontSize: "14px",
          fontWeight: "450",
          height: "60px",
          backgroundColor: isHovered ? "#d9e0ea" : "white",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "18%",
          }}
        >
          <div style={{ width: "60px" }}>
            <Avatar
              src={profilPicture}
              alt="Profile Picture"
              sx={{
                marginRight: "10px",
                borderRadius: "8px", // Ajustez cette valeur pour contrôler l'arrondi des bords
                width: 50, // Ajustez la largeur pour obtenir une forme carrée
                height: 50, // Ajustez la hauteur pour obtenir une forme carrée
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "120px",
              height: "auto",
              justifyContent: "center",
            }}
          >
            <div style={textStyle6}>{fullName}</div>
            <div style={textStyle5}>
              <ChipStatus status={clientStatus} />
              {/* {clientStatus} */}
            </div>
          </div>
        </div>

        <div
          style={{
            ...textStyle4,
            paddingLeft: "5px",
            width: "11%",
          }}
        >
          {primaryAddiction}
        </div>
        <div
          style={{
            ...textStyle,
            paddingLeft: "5px",
            width: "7%",
          }}
        >
          {aka}
        </div>
        <div
          style={{
            ...textStyle,
            paddingLeft: "5px",
            width: "7%",
          }}
        >
          {age}
        </div>
        <div
          style={{
            ...textStyle,
            paddingLeft: "5px",
            width: "11%",
          }}
        >
          {arrivalDate}
        </div>
        {/* <div style={textStyle}>{personalEmail}</div> */}
        <div
          style={{
            ...textStyle,
            paddingLeft: "5px",
            width: "11%",
          }}
        >
          {projectedResidentialDischargeDate
            ? projectedResidentialDischargeDate
            : idd}
        </div>
        <div style={{ ...textStyle, width: "8%" }}>{ils}</div>
        <div
          style={{
            ...textStyle,
            paddingLeft: "5px",
            width: "9%",
          }}
        >
          {assignedTherapist}
        </div>
        <div style={{ ...textStyle2, width: "8%" }}>{roomNumber}</div>

        <div
          style={{
            ...textStyle,
            width: "10%",
            paddingLeft: "0px",
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "0px",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
          // onClick={handleNavigate}
          onClick={handleNavigateToAlerts}
        >
          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor:
                alertCounts.alerts > 0
                  ? "rgba(255, 0, 0, 0.8)"
                  : "rgba(255, 0, 0, 0.1)",
              animation: alertCounts.alerts > 0 ? "blink 3s infinite" : "none",
            }}
            title={`Alerts: ${alertCounts.alerts}`}
          ></div>

          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor:
                alertCounts.reminders > 0
                  ? "rgba(0, 0, 255, 0.8)"
                  : "rgba(0, 0, 255, 0.1)",
              animation:
                alertCounts.reminders > 0 ? "blink 3s infinite" : "none",
            }}
            title={`Reminders: ${alertCounts.reminders}`}
          ></div>

          <div
            style={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              backgroundColor:
                alertCounts.allergies > 0
                  ? "rgba(255, 165, 0, 0.8)"
                  : "rgba(255, 165, 0, 0.1)",
              animation:
                alertCounts.allergies > 0 ? "blink 3s infinite" : "none",
            }}
            title={`Allergies: ${alertCounts.allergies}`}
          ></div>
        </div>
      </div>
    </div>
  );
}

export default ClientRowDetails;
