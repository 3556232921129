import { Avatar } from "@mui/material";
import React from "react";

export default function SmallClientChipV2(props) {
  return (
    <div className="small-client-chip-container-v2">
      <Avatar
        style={{
          width: "40px",
          height: "40px",
        }}
        src={props.clientPic}
      />
      <div
        className="small-client-chip-name"
        style={{ fontSize: "16px", fontWeight: "600" }}
      >
        {props.firstName} {props.lastName}
      </div>
    </div>
  );
}
