import React, { useContext, useEffect, useState } from "react";
import "../css/navbar.css";
import menuitems from "../api/menuitems";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { documentId, onSnapshot, query, where } from "firebase/firestore";
import { roles } from "../firebase.collection";
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { LanguageContext } from "../context/LanguageContext";
import ThailandFlag from "../images/flags/thai-flag.png";
import USFlag from "../images/flags/uk-flag-icon.png";
import FranceFlag from "../images/flags/french-flag-icon.png";
import ProfileWidgetMobile from "./ProfileWidgetMobile";
import CloseIcon from "@mui/icons-material/Close";

export default function MobileNavbar(props) {
  const { translate, language, setLanguage } = useContext(LanguageContext);
  const { currentUser } = useAuth();
  const [activeLink, setActiveLink] = useState(props.linkActive);
  const handleAi = () => {
    navigate("/ai");
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const [userRole, setUserRole] = useState("");
  const [userIdState] = useState(currentUser ? currentUser.uid : "");

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  function handleLinkClick(linkId) {
    setActiveLink(linkId === activeLink ? null : linkId);
  }

  useEffect(() => {
    if (currentUser) {
      queryRole();
    } else {
      console.log("No user id");
    }
  }, [currentUser]);

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;
          setUserRole(role);
          setLoading(false);
        } else {
          console.log("Problem loading roles");
        }
      }
    );
  }

  const renderMenuItems = (items) => {
    return items
      .filter((item) => item.roles.includes(userRole))
      .map((item, index) => (
        <div className="sidebar-title-mobile" key={index}>
          {/* <div className="icon-menu">{item.icon}</div> */}
          <Link
            className={`link-navbar ${item.id === activeLink ? "active" : ""}`}
            to={item.link}
            onClick={() => handleLinkClick(item.id)}
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div className="icon-menu">{item.icon}</div>
            <div
              className={`menu-title ${item.id === activeLink ? "active" : ""}`}
            >
              {translate(item.name)}
            </div>
          </Link>
        </div>
      ));
  };

  return (
    <div className="mobile-navbar-container">
      <div className="navbar-top-group-mobile">
        <div
          // className="profile-widget-component"
          style={{
            height: "10%",
            paddingLeft: "0px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: "5%",
          }}
        >
          <ProfileWidgetMobile userRole={userRole} />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "auto",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            // className={shouldBlink ? "blink" : ""}
          >
            <div
              style={{
                color: "white",
                fontWeight: "700",
                fontSize: "16px",
                paddingRight: "10px",
                width: "auto",
                textAlign: "end",
                fontSize: "12px",
                fontWeight: "500",
                paddingLeft: "10px",
                border: "1px solid lightgrey",
                paddingBottom: "3px",
                paddingTop: "3px",
                borderRadius: "5px",
                backgroundColor: "white",
                color: "black",
                // display: "none", // Hidden by default
              }}
              className="ai-text"
              onClick={handleAi}
            >
              {translate("TryOurAI")}
            </div>
            {/* <Tooltip title={translate("TryOurAI")} arrow>
              <img
                src={logoAi}
                width={30}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={handleAi} // Move onClick here
              />
            </Tooltip> */}
          </div>

          <div
            style={{
              width: "auto",
              paddingLeft: "0px",
              paddingTop: "0px",
              // height: "5%",
              display: "flex",
              alignItems: "center",
              paddingRight: "0px",
            }}
          >
            <FormControl
              variant="outlined"
              size="small"
              style={{
                minWidth: 50,
                border: "none",
                boxShadow: "none", // Remove any shadow
              }}
            >
              <Select
                labelId="language-select-label"
                id="language-select"
                value={language}
                onChange={handleLanguageChange}
                displayEmpty
                style={{
                  fontSize: 10,
                  height: 30,
                  padding: 0,
                  minWidth: 50,
                  border: "none", // Remove the border
                  boxShadow: "none", // Remove shadow
                  paddingRight: 0, // Remove padding for the triangle
                  backgroundColor: "transparent", // Ensure no background color
                }}
                renderValue={(selected) => (
                  <Box display="flex" alignItems="center">
                    {selected === "en" && (
                      <img
                        src={USFlag}
                        alt="US Flag"
                        style={{ width: 30, marginRight: 0 }} // No margin when closed
                      />
                    )}
                    {selected === "fr" && (
                      <img
                        src={FranceFlag}
                        alt="France Flag"
                        style={{ width: 30, marginRight: 0 }}
                      />
                    )}
                    {selected === "th" && (
                      <img
                        src={ThailandFlag}
                        alt="Thailand Flag"
                        style={{ width: 30, marginRight: 0 }}
                      />
                    )}
                  </Box>
                )}
                IconComponent={() => null} // Remove the triangle
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: 5, // Optional: Adjust the position of the dropdown menu
                    },
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove the border completely
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // No border when focused
                  },
                  "& .MuiSelect-select": {
                    paddingRight: 0, // Ensure no padding on the right
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                <MenuItem value="en">
                  <Box display="flex" alignItems="center">
                    <img
                      src={USFlag}
                      alt="US Flag"
                      style={{ width: 30, marginRight: 10 }}
                    />
                    <span style={{ fontSize: 10 }}>English</span>
                  </Box>
                </MenuItem>
                <MenuItem value="fr">
                  <Box display="flex" alignItems="center">
                    <img
                      src={FranceFlag}
                      alt="France Flag"
                      style={{ width: 30, marginRight: 10 }}
                    />
                    <span style={{ fontSize: 10 }}>Français</span>
                  </Box>
                </MenuItem>
                <MenuItem value="th">
                  <Box display="flex" alignItems="center">
                    <img
                      src={ThailandFlag}
                      alt="Thailand Flag"
                      style={{ width: 30, marginRight: 10 }}
                    />
                    <span style={{ fontSize: 10 }}>ภาษาไทย</span>
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="menu-items-container-mobile" style={{ height: "90%" }}>
          {loading ? (
            <div className="circular-progress-container">
              <CircularProgress />
              <div className="loading-text">Loading, please wait...</div>
            </div>
          ) : (
            <div
              style={{
                height: "100%",
                // paddingLeft: "20px",
              }}
            >
              {/* Premier groupe de menu items */}
              <div style={{ paddingLeft: "20px", marginTop: "10px" }}>
                {renderMenuItems(
                  menuitems.filter((item) => ["Dashboard"].includes(item.name))
                )}
              </div>

              {/* Séparateur pour la section "Clients" */}
              <div className="menu-separator"></div>

              {/* <div
                style={{
                  color: "#a7a7ab",
                  paddingBottom: "0px",
                  fontSize: "16px",
                  marginTop: "10px",
                  paddingLeft: "5px",
                  paddingBottom: "10px",
                  fontWeight: "500",
                }}
              >
                {translate("ClientC")}
              </div> */}

              <div style={{ paddingLeft: "20px" }}>
                {renderMenuItems(
                  menuitems.filter((item) =>
                    [
                      "Clients",
                      "Notes",
                      "RoomAvailability",
                      "RoomBooking",
                      "CRM",
                      "Layout",
                    ].includes(item.name)
                  )
                )}
              </div>

              {/* Séparateur pour la section "Clients" */}
              <div className="menu-separator"></div>

              {/* <div
                style={{
                  color: "#a7a7ab",
                  paddingBottom: "0px",
                  fontSize: "16px",
                  marginTop: "10px",
                  paddingLeft: "5px",
                  paddingBottom: "10px",
                  fontWeight: "500",
                }}
              >
                {translate("Admin")}
              </div> */}

              {/* Deuxième groupe de menu items : "Clients" */}
              <div style={{ paddingLeft: "20px" }}>
                {renderMenuItems(
                  menuitems.filter((item) =>
                    ["Metrics", "ExportData", "Documents"].includes(item.name)
                  )
                )}
              </div>

              {/* Séparateur pour la section "Admin" */}
              <div className="menu-separator"></div>

              {/* Troisième groupe de menu items : "Admin" */}
              <div style={{ paddingLeft: "20px" }}>
                {renderMenuItems(
                  menuitems.filter((item) =>
                    [
                      "MyProfile",
                      "Support",
                      // "Roles",
                      // "Staff contacts",
                      // "Metrics",
                      // "ExportData",
                      // "Documents",
                    ].includes(item.name)
                  )
                )}
              </div>
            </div>
          )}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: "0%",
          }}
        ></div>
      </div>
      <div
        className="close-icon"
        onClick={() => navigate(-1)}
        style={{
          cursor: "pointer",
          marginLeft: "auto",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          paddingBottom: "30px",
        }}
      >
        <CloseIcon />
      </div>

      {/* Bas de la barre de navigation */}
      {/* <div className="navbar-bottom-group">
        <InternetCheck />
      </div> */}
    </div>
  );
}
