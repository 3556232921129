import React, { useState } from "react";
import { Button, Checkbox } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import TopTitleFormComponent from "./TopTitleFormComponent";

import {
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from "firebase/storage";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { updateDoc, doc as firestoreDoc } from "firebase/firestore";
import { showCustomToast } from "../Misc/CustomToast";
import SignaturePadComponentV2 from "../Signature/SignaturePadComponentV2";

function AddAgressionControl(props) {
  //Tous les states des données récupérées par client
  const { clientId } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const [, setIsSignatureStarted] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);
  const [isSignatureValidated, setIsSignatureValidated] = useState(false);

  const [text0] = useState(
    "Our rehab’s philosophies and beliefs cannot be fostered in the presence of aggression or emotional abuse. Definitions :"
  );
  const [text1] = useState(
    "Aggression: Actions of physical assault toward anyone at our rehab inclusive of the visitors, staff or anyone at our rehab or in the community while a client of our rehab."
  );
  const [text2] = useState(
    "Emotional Abuse: Consistent use of insulting or coarse language or to consistently assail with contemptuous hurtful communication toward anyone of our rehab inclusive of the community, visitors, staff or anyone the client is interacting with while a client of our rehab"
  );
  const [text2bis] = useState(
    "Policy: Acts of aggression or emotional abuse will not be tolerated at our rehab. Aggression and emotional abuse will be dealt with by the following policies:"
  );
  const [text3] = useState(
    "Acts of aggression: Staff are instructed to immediately call the police to have the client(s) removed from the premises. Clients who choose to participate in acts of aggression will be discharged from treatment and will need a psychological/ psychiatric clearance prior to consideration for readmission to our rehab."
  );
  const [text4] = useState(
    "Emotional Abuse: Clients who identify as emotionally abusive and who have been unresponsive to clinical interventions will be therapeutically discharged. Readmission to our rehab will be taken into consideration only after an interview with the Managing Director."
  );
  const [text5] = useState(
    "Incidents of physical abuse or incidents requiring attention from outside services such as medical or law enforcement are required to be documented in the client’s chart and an incident report is to be completed immediately. The Managing Director is to be notified immediately upon occurrence."
  );
  const [text6] = useState("I fully understand and agree to the above terms.");

  const handleCheckboxChange1 = (event) => {
    setChecked1(event.target.checked);
  };

  const handleCheckboxChange2 = (event) => {
    setChecked2(event.target.checked);
  };

  const handleCheckboxChange3 = (event) => {
    setChecked3(event.target.checked);
  };

  const handleCheckboxChange4 = (event) => {
    setChecked4(event.target.checked);
  };

  const handleCheckboxChange5 = (event) => {
    setChecked5(event.target.checked);
  };

  const handleCheckboxChange6 = (event) => {
    setChecked6(event.target.checked);
  };

  const handleSignatureSave = (dataURL) => {
    setSignatureDataURL(dataURL);
  };

  const uploadSignature = async () => {
    const storage = getStorage();
    const fileName = `${clientId}.png`;
    const signatureRef = ref(
      storage,
      `MandatoryForms/AgressionControlPolicy/${fileName}`
    );

    await uploadString(signatureRef, signatureDataURL, "data_url");
    const downloadURL = await getDownloadURL(signatureRef);

    return downloadURL;
  };

  const handleFormSubmit = async () => {
    if (
      !checked1 ||
      !checked2 ||
      !checked3 ||
      !checked4 ||
      !checked5 ||
      !checked6 ||
      !signatureDataURL ||
      !isSignatureValidated
    ) {
      showCustomToast(
        "Please agree to all the terms before submitting.",
        "error"
      );
    }

    try {
      setIsLoading(true);
      const signatureURL = await uploadSignature();
      const docRef = doc(db, "AgressionControlPolicy", props.clientId);
      await setDoc(docRef, {
        checked1,
        checked2,
        checked3,
        checked4,
        checked5,
        checked6,
        text0,
        text1,
        text2,
        text2bis,
        text3,
        text4,
        text5,
        text6,
        signatureURL,
        signedOn: serverTimestamp(),
      });

      const clientDocRef = firestoreDoc(db, "clients", props.clientId);
      await updateDoc(clientDocRef, {
        AggressionControlPolicyOk: true,
        AggressionControlPolicyDateSigned: serverTimestamp(),
      });

      showCustomToast(
        "Aggression control policy form correctly submitted!",
        "success"
      );

      setIsLoading(false);
      props.closemodal();
    } catch (e) {
      showCustomToast("Error adding document", "error");

      console.error("Error adding document: ", e);
    }
  };

  const allChecked =
    checked1 && checked2 && checked3 && checked4 && checked5 && checked6;
  return (
    <>
      <div style={{ width: "auto", height: "100%" }}>
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title="AGGRESSION CONTROL POLICY FORM"
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "10px",
            height: "80%",
            overflowY: "scroll",
            // overflowX: "hidden",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <div className="SubTitleBold">{text0}</div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked1} onChange={handleCheckboxChange1} />
            <div className="block-text-check">{text1}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked2} onChange={handleCheckboxChange2} />
            <div className="block-text-check">{text2}</div>
          </div>

          <div className="SubTitleBold">{text2bis}</div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked3} onChange={handleCheckboxChange3} />
            <div className="block-text-check">{text3}</div>
          </div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked4} onChange={handleCheckboxChange4} />
            <div className="block-text-check">{text4}</div>
          </div>

          <div className="SubTitleBold">Incident reports:</div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked5} onChange={handleCheckboxChange5} />
            <div className="block-text-check">{text5}</div>
          </div>

          <div className="SubTitleBold">Acknowledgement</div>

          <div className="checkbox-container-margin">
            <Checkbox checked={checked6} onChange={handleCheckboxChange6} />
            <div className="block-text-check">{text6}</div>
          </div>

          <div style={{ width: "100%", marginBottom: "25px" }}>
            <SignaturePadComponentV2
              onSignatureSave={handleSignatureSave}
              signatureDataURL={signatureDataURL}
              onSignatureStart={() => setIsSignatureStarted(true)}
              onSignatureValidate={setIsSignatureValidated}
            />
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleFormSubmit}
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : !signatureDataURL || !isSignatureValidated || !allChecked
                  ? {
                      backgroundColor: "grey",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={
                isLoading ||
                !signatureDataURL ||
                !allChecked ||
                !isSignatureValidated
              }
            >
              {isLoading ? "SAVING..." : "SAVE"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddAgressionControl;
