import React from "react";
import "../css/dashboard.css";
import TicketsMobile from "../component/Misc/TicketsMobile";

export default function SupportContentMobile() {
  return (
    <div className="right-container-mobile">
      <TicketsMobile />
    </div>
  );
}
