import React from "react";
import underConstruction from "../../images/underconstruction.png";

function UnderConstruction() {
  return (
    <>
      <div className="empty-content">
        <img src={underConstruction} alt="" />
      </div>
    </>
  );
}

export default UnderConstruction;
