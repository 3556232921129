import React, { useEffect } from "react";
import connector from "../../images/Connector.png";
import DeleteButton from "../Buttons/DeleteButton";
import EditButton from "../Buttons/EditButton";
import DateAndTimeChip from "../Chips/DateAndTimeChip";
import SmallClientChip from "../SmallClientChip";
import { useState } from "react";
import { Box, Modal } from "@mui/material";
import EditFamilyNote from "./EditFamilyNote";
import DeleteFamilyNote from "./DeleteFamilyNote";
import ViewFamilyNote from "./ViewFamilyNote";
import TypeNoteChip from "../Chips/TypeNoteChip";
import { useNavigate } from "react-router-dom";

function FamilyNoteContainer(props) {
  const styleEdit = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
    borderRadius: 5,
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleView = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    height: "auto",
    borderRadius: 5,
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleDeleteModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    height: 260,
    borderRadius: 2,
    bgcolor: "background.paper",
    /*border: "1px solid #000",*/
    boxShadow: 24,
    // p: 4,
  };

  //STATES
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);

  //FUNCTIONS
  const openEditModal = () => {
    setShowEditModal(true);
  };
  const openDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const closeViewModal = () => {
    setShowViewModal(false);
  };

  const handleNavigate = () => {
    setShouldNavigate(true);
  };

  const [shouldNavigate, setShouldNavigate] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (shouldNavigate) {
      navigate(`/client/${props.clientId}`, {
        state: props, // Passez l'objet client complet
      });
    }
  }, [shouldNavigate]);

  return (
    <div className="notes-wrapper">
      <Modal
        open={showEditModal}
        onClose={closeEditModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleEdit}>
          <EditFamilyNote
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDate={props.effectiveDate}
            closemodal={closeEditModal}
            fileLinks={props.fileUrls}
            relationshipFamilyNote={props.relationship}
          />
        </Box>
      </Modal>

      <Modal
        open={showDeleteModal}
        onClose={closeDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleDeleteModal}>
          <DeleteFamilyNote
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDate={props.effectiveDate}
            closemodal={closeDeleteModal}
          />
        </Box>
      </Modal>

      <Modal
        open={showViewModal}
        onClose={closeViewModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleView}>
          <ViewFamilyNote
            note={props.noteContent}
            noteId={props.noteId}
            typeOfNote={props.noteType}
            effectiveDate={props.effectiveDate}
            closemodal={closeViewModal}
            relationshipFamilyNote={props.relationship}
          />
        </Box>
      </Modal>
      <div className="group-note-container">
        <div
          className="group-note-container-block-1"
          style={{ width: "98px", height: "auto" }}
        >
          <DateAndTimeChip date={props.noteDate} />
          <DateAndTimeChip date={props.noteTime} />
          <TypeNoteChip noteType={props.noteType} />
        </div>
        <div className="group-note-container-block-2" style={{ width: "45px" }}>
          <img src={connector} height="300" />
        </div>
        <div className="group-note-container-block-3">
          <div className="group-note-container-line-1">
            <div
              className="note-container-line1-block1 centre-block"
              onClick={handleNavigate}
              style={{ cursor: "pointer", paddingLeft: "10px" }}
            >
              <SmallClientChip
                firstName={props.clientFirstName}
                lastName={props.clientLastName}
                avatar="1"
                clientPic={props.clientPic}
              />
            </div>
            <div className="note-container-line1-block1bis left-block">
              <div className="title-cell-container">Done by:</div>
              <div className="content-cell-container">{props.doneBy}</div>
            </div>
            <div
              className="note-container-line1-block2 centre-block"
              style={{ paddingLeft: "10px" }}
            >
              <div className="title-cell-container">Record Timestamp:</div>
              <div className="content-cell-container">
                {props.timeStamp}
              </div>{" "}
              <span
                style={{ fontSize: "10px", textAlign: "left", width: "100%" }}
              >
                {props.lastModification &&
                  `(Last modification: ${props.lastModification})`}
              </span>
            </div>
            <div className="note-container-line1-block3-bis centre-block2">
              <div className="sub-block-2">
                {/* <ViewButton handleOpen={openViewModal} /> */}
                <EditButton handleOpen={openEditModal} />
                <DeleteButton handleOpen={openDeleteModal} />
              </div>
            </div>
          </div>
          <div className="group-note-container-line-1">
            <div className="group-container-line1-block1 left-block">
              {props.relationship === "Other" ? (
                <>
                  <div className="title-cell-container">
                    {" "}
                    Relationhsip to client:
                  </div>
                  <div className="content-cell-container">
                    {" "}
                    {props.relationshipOther}
                  </div>
                </>
              ) : (
                <>
                  <div className="title-cell-container">
                    {" "}
                    Relationship to client:
                  </div>

                  <div className="content-cell-container">
                    {" "}
                    {props.relationship}
                  </div>
                </>
              )}
            </div>
            <div className="group-container-line1-block4 left-block">
              {props.fileUrls && props.fileUrls.length > 0 ? (
                <>
                  <span className="title-cell-container">
                    File(s) attached:{" "}
                  </span>
                  <span className="content-cell-container">
                    {props.fileUrls
                      .map((file, index) => (
                        <a
                          href={file.url}
                          key={index}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {file.name}
                        </a>
                      ))
                      .reduce((prev, curr, index, array) => {
                        return index < array.length - 1
                          ? [prev, ", ", curr]
                          : [prev, curr];
                      }, [])}
                  </span>
                </>
              ) : (
                "No files attached"
              )}
            </div>
          </div>
          <div className="group-note-container-line-2">{props.noteContent}</div>
        </div>
      </div>
    </div>
  );
}

export default FamilyNoteContainer;
