import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import InputComponent from "../InputComponent";
import { useAuth } from "../../contexts/AuthContext";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";
import { format } from "date-fns";
import DropDownComponentSorted from "../DropDownComponentSorted";

function EditGroupNote(props) {
  const { currentUser } = useAuth();
  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  // Initialize states with props
  const [typeGroupNote, setTypeGroupNote] = useState(props.groupType);
  const [otherTypeGroupNote, setOtherTypeGroupNote] = useState(
    props.otherTypeGroupNote
  );
  const [groupTopic, setGroupTopic] = useState(props.groupTopic);
  const [noteContent, setNoteContent] = useState(props.noteContent);
  const [notInAttendance, setNotInAttendance] = useState(props.notInAttendance);
  const [hasChanged, setHasChanged] = useState(false);
  const [groupNoteDateTime, setGroupNoteDateTime] = useState(
    props.effectiveDateTime || ""
  );
  const [groupNoteStartDateTime, setGroupNoteStartDateTime] = useState(
    props.groupStartDateTime
  );

  // Other state definitions
  const [isLoading, setIsLoading] = useState(false);

  // Other handler definitions

  const handleTypeGroupNote = (event) => {
    setTypeGroupNote(event);
    setHasChanged(true);
  };

  const handleGroupTopic = (event) => {
    setGroupTopic(event);
    setHasChanged(true);
  };

  const handleOtherTypeGroupNote = (event) => {
    setOtherTypeGroupNote(event);
    setHasChanged(true);
  };

  const handleNoteContent = (event) => {
    setNoteContent(event.target.value);
    setHasChanged(true);
  };

  const handleNotInAttendance = (event) => {
    setNotInAttendance(event.target.value);
    setHasChanged(true);
  };

  const handleDateTimeChange = (event) => {
    setGroupNoteDateTime(event);
    setHasChanged(true);
  };

  const handleGroupStartDateTimeChange = (event) => {
    setGroupNoteStartDateTime(event);
    setHasChanged(true);
  };

  const handleUpdateGroupNote = async (note, effectiveDateTime) => {
    const noteData = {
      noteType: "groupNote",
      noteContent: note,
      updateAt: serverTimestamp(),
      effectiveDateTime: effectiveDateTime,
      doneBy: currentUser.displayName,
      notInAttendance: notInAttendance,
      groupTopic: groupTopic,
      typeGroupNote: typeGroupNote,
      otherTypeGroupNote: otherTypeGroupNote,
    };

    if (typeGroupNote && groupTopic && noteContent && notInAttendance) {
      setIsLoading(true);
      try {
        const docRef = doc(db, "notes", props.noteId); // Get a reference to the document to update
        await updateDoc(docRef, noteData); // Update the document
        showCustomToast("Group note successfully updated!", "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.log("error updating note :", error);
        showCustomToast("Problem when updating note!", "error");
      }
    } else {
      showCustomToast(
        "Some mandatory fields are empty. Please check.!",
        "error"
      );
    }
  };

  const handleSubmit = () => {
    handleUpdateGroupNote(noteContent, groupNoteDateTime);
  };

  return (
    <>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <div className="modal-title">Edit group note </div>
          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "0px",
            height: "75%",
            overflowY: "scroll",
            // overflowX: "hidden",
          }}
        >
          {" "}
          <div
            className="line3-modal"
            style={{ marginLeft: "20px", marginTop: "10px" }}
          >
            <InputComponent
              isRequired={true}
              title="Group topic"
              width="300px"
              value={groupTopic}
              onChange={handleGroupTopic}
            />
          </div>
          <div className="line3-modal" style={{ marginLeft: "20px" }}>
            <DatePickerWithTimeComponent
              isRequired={true}
              value={groupNoteStartDateTime}
              title="Group Date and start time"
              width="300px"
              onChange={handleGroupStartDateTimeChange}
            />
          </div>
          <div className="line3-modal" style={{ marginLeft: "20px" }}>
            <DropDownComponentSorted
              isRequired={true}
              api="groupType"
              title="Type of group"
              value={typeGroupNote}
              onChange={handleTypeGroupNote}
            />

            {typeGroupNote === "Other" ? (
              <div style={{ height: "auto" }}>
                <InputComponent
                  title="if other, describe"
                  width="auto"
                  value={otherTypeGroupNote}
                  onChange={handleOtherTypeGroupNote}
                />
              </div>
            ) : (
              <div style={{ height: "auto" }}>""</div>
            )}
          </div>
          <div className="line2-column-modal">
            <div className="label-textfield">
              Group note
              <span style={{ color: "red", marginLeft: "5px" }}>*</span>
            </div>
            <TextField
              style={{
                width: "95%",
                borderRadius: "5px",
                backgroundColor: "#f2eeef",
              }}
              placeholder="Type group note here"
              multiline
              rows={10}
              value={noteContent}
              onChange={handleNoteContent}
            />
          </div>
          <div className="line2-column-modal">
            <div className="label-textfield">
              Clients NOT in attendance
              <span style={{ color: "red", marginLeft: "5px" }}>*</span>
            </div>
            <TextField
              style={{
                width: "95%",
                borderRadius: "5px",
                backgroundColor: "#f2eeef",
              }}
              placeholder="Type names here (first name / or preferred name and first initial of last name) and reason why client is not in group, if known..."
              // placeholder="Type therapy session note here."
              multiline
              rows={4}
              value={notInAttendance}
              onChange={handleNotInAttendance}
            />
          </div>
          <div
            className="line3-modal"
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            <DatePickerWithTimeComponent
              isRequired={true}
              value={groupNoteDateTime}
              onChange={handleDateTimeChange}
              title="Effective date and time"
            />
          </div>
        </div>
        <div className="line4-modal-percent">
          <Button
            variant="contained"
            style={{ backgroundColor: "white", color: "black" }}
            onClick={props.closemodal}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={
              !hasChanged // si aucune modification n'a été effectuée
                ? {
                    backgroundColor: "lightgray", // bouton est gris clair
                    color: "black",
                    marginLeft: "15px",
                  }
                : isLoading
                ? {
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "15px",
                  }
                : {
                    backgroundColor: "#1565C0",
                    color: "white",
                    marginLeft: "15px",
                  }
            }
            disabled={!hasChanged || isLoading}
            onClick={handleSubmit}
          >
            {isLoading ? "UPDATING..." : "UPDATE NOTE"}
          </Button>
        </div>
      </div>
    </>
  );
}

export default EditGroupNote;
