import React, { useContext } from "react";
import ViewButton from "./Buttons/ViewButton";
import CheckWithDate from "./CheckWithDate";
import EditButton from "./Buttons/EditButton";
import { format } from "date-fns";
import { Box, Modal, Slide } from "@mui/material";
import { useState } from "react";
import ViewTreatmentPlan from "./forms/ViewTreatmentPlan";
import AddReviewForm from "./forms/AddReviewForm";
import RevokeButton from "./Buttons/RevokeButton";
import EditTreatmentPlanDesktop from "./forms/EditTreatmentPlanDesktop";
import { LanguageContext } from "../context/LanguageContext";

function FormLineMediumTreatmentPlan(props) {
  const { translate } = useContext(LanguageContext);
  const styleModalGeneral = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    height: "80%",
    borderRadius: 5,
    // overflowY: "scroll",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styleModalGeneralView = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    height: "80%",
    borderRadius: 5,
    // overflowY: "scroll",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const [openEditTreatment, setOpenEditTreatment] = useState(false);
  const [openViewTreatment, setOpenViewTreatment] = useState(false);
  const [openAddReviewTreatment, setOpenAddReviewTreatment] = useState(false);

  const handleOpenEditTreatment = () => setOpenEditTreatment(true);
  const handleCloseEditTreatment = () => setOpenEditTreatment(false);
  console.log("openEditTreatment: ", openEditTreatment);

  const handleOpenViewTreatment = () => setOpenViewTreatment(true);
  const handleCloseViewTreatment = () => setOpenViewTreatment(false);

  const handleOpenAddReviewTreatment = () => setOpenAddReviewTreatment(true);
  const handleCloseAddReviewTreatment = () => setOpenAddReviewTreatment(false);

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }
  return (
    <div
      style={{
        marginLeft: "0px",
        display: "flex",
        flexDirection: "row",
        height: "50px",
        borderRadius: "30px",
        border: "1px solid lightgrey",
        width: "60%",
        marginTop: "5px",
        marginBottom: "5px",
        paddingLeft: "15px",
        paddingRight: "5px",
      }}
    >
      <Modal
        open={openEditTreatment}
        onClose={handleCloseEditTreatment}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openEditTreatment}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "30%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <EditTreatmentPlanDesktop
              {...props}
              clientId={props.clientId}
              closemodal={handleCloseEditTreatment}
            />
          </Box>
        </Slide>
      </Modal>

      <Modal
        open={openViewTreatment}
        onClose={handleCloseViewTreatment}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openViewTreatment}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "30%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ViewTreatmentPlan
              {...props}
              clientId={props.clientId}
              closemodal={handleCloseViewTreatment}
            />
          </Box>
        </Slide>
      </Modal>

      <Modal
        open={openAddReviewTreatment}
        onClose={handleCloseAddReviewTreatment}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openAddReviewTreatment}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "30%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AddReviewForm
              {...props}
              clientId={props.clientId}
              closemodal={handleCloseAddReviewTreatment}
            />
          </Box>
        </Slide>
      </Modal>

      <div
        style={{
          width: "40%",
          fontSize: "18px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props.presentingIssues} {/* Ajouter un espace après `props.title` */}
      </div>
      <div
        style={{
          width: "30%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.signed === "signed" ? (
          <CheckWithDate checked="checked" content={formatDate(props.date)} />
        ) : (
          <CheckWithDate checked={true} content={formatDate(props.date)} />
        )}
      </div>
      <div className="formLine2Block2bis">
        {props.reviewDone == true ? (
          <CheckWithDate
            checked={props.reviewDone || false}
            content={formatDate(props.reviewDoneAt)}
          />
        ) : (
          <CheckWithDate checked="missed" />
        )}
      </div>
      <div
        style={{
          width: "30%",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          paddingLeft: "3%",
          paddingRight: "3%",
        }}
      >
        <ViewButton
          {...props}
          clientId={props.clientId}
          createBy={props.createdBy}
          createdAt={props.createdAt}
          handleOpen2={handleOpenViewTreatment}
        />
        <EditButton
          {...props}
          clientId={props.clientId}
          createBy={props.createdBy}
          createdAt={props.createdAt}
          handleOpen2={handleOpenEditTreatment}
        />
        <RevokeButton
          {...props}
          clientId={props.clientId}
          createBy={props.createdBy}
          createdAt={props.createdAt}
          handleOpen2={handleOpenAddReviewTreatment}
        />
      </div>
    </div>
  );
}

export default FormLineMediumTreatmentPlan;
