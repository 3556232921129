import { Box, Button, Modal, TextField } from "@mui/material";
import React from "react";
import DatePickerComponent from "../DatePickerComponent";
import { useState } from "react";
import { useEffect } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";
import DropDownComponentSorted from "../DropDownComponentSorted";
import DropDownSpecificStaff from "../Dropdowns/DropDownSpecificStaff";
import DatePickerComponentWithTime from "../DatePickerComponentWithTime";
import DropDownComponentSortedFixed from "../DropDownComponentSortedFixed";
import FormLineCTS from "../Lines/FormLineCTS";
import AddButtonRound from "../Buttons/AddButtonRound";
import { format } from "date-fns";
import AddContinuatedTherapySupport from "../forms/AddContinuatedTherapySupport";

function Swots(props) {
  //STATE
  const [isLoading, setIsLoading] = useState(false);
  const [durationOfStay, setDurationOfStay] = useState(
    props.durationOfStay || ""
  );
  const [dateOfAdmission, setDateOfAdmission] = useState(
    props.dateOfAdmission || ""
  );
  const [
    projectedResidentialDischargeDate,
    setProjectedResidentialDischargeDate,
  ] = useState(props.projectedResidentialDischargeDate || "");

  const [flight, setFligth] = useState(props.flight || "");
  const [swot, setSwot] = useState(props.swot || "");
  const [aftercareStaff1, setAftercareStaff1] = useState(
    props.aftercareStaff1 || ""
  );
  const [aftercareStaff2, setAftercareStaff2] = useState(
    props.aftercareStaff2 || ""
  );
  const [aftercareStaff3, setAftercareStaff3] = useState(
    props.nameRecoveryCoach || ""
  );
  const [swotDone, setSwotDone] = useState(props.swotDone || false);
  const [miraclesAtHome, setMiraclesAtHome] = useState(
    props.miraclesAtHome || false
  );
  const [aftercareStaff, setAftercareStaff] = useState(
    props.aftercareStaff || ""
  );
  const [startDateAftercare, setStartDateAftercare] = useState(
    props.startDateAftercare || ""
  );
  const [endDateAftercare, setEndDateAftercare] = useState(
    props.endDateAftercare || ""
  );
  const [mathomeStaff, setMathomeStaff] = useState(props.mathomeStaff || "");
  const [mathomeStatus, setMathomeStatus] = useState(props.mathomeStatus || "");
  const [startDateMAtHome, setStartDateMAtHome] = useState(
    props.startDateMAtHome || ""
  );
  const [endDateMAtHome, setEndDateMAtHome] = useState(
    props.endDateMAtHome || ""
  );
  const [ccStaff, setCcStaff] = useState(props.ccStaff || "");
  const [ccStatus, setCcStatus] = useState(props.ccStatus || "");
  const [ccStartDate, setCcStartDate] = useState(props.ccStartDate || "");
  const [ccEndDate, setCcEndDate] = useState(props.ccEndDate || "");
  const [recoveryStaff, setRecoveryStaff] = useState(props.recoveryStaff || "");
  const [recoveryStatus, setRecoveryStatus] = useState(
    props.recoveryStatus || ""
  );
  const [recoveryStartDate, setRecoveryStartDate] = useState(
    props.recoveryStartDate || ""
  );
  const [recoveryEndDate, setRecoveryEndDate] = useState(
    props.recoveryEndDate || ""
  );

  const [recoveryRadio, setRecoveryRadio] = useState(props.recoveryRadio || "");
  const [googleReview, setGoogleReview] = useState(props.googleReview || "");
  const [sponsor, setSponsor] = useState(props.sponsor || null);
  const [meetingSchedule, setMeetingSchedule] = useState(
    props.meetingSchedule || null
  );
  const [therapySession, setTherapySession] = useState(
    props.therapySession || null
  );
  const [homeSignUp] = useState(props.homeSignUp || null);
  const [recoveryCoaching, setRecoveryCoaching] = useState(
    props.recoveryCoaching || null
  );
  const [receivedMedication, setReceivedMedication] = useState(
    props.receivedMedication || null
  );
  const [paidMedication, setPaidMedication] = useState(
    props.paidMedication || null
  );
  const [workbookCompleted, setWorkbookCompleted] = useState(
    props.workbookCompleted || null
  );
  const [zoomIDGiven, setZoomIDGiven] = useState(props.zoomIDGiven || null);
  const [detailsSponsor, setDetailsSponsor] = useState(
    props.detailsSponsor || ""
  );
  const [detailsMeetingSchedule, setDetailsMeetingSchedule] = useState(
    props.detailsMeetingSchedule || ""
  );
  const [detailsTherapySession, setDetailsTherapySession] = useState(
    props.detailsTherapySession || ""
  );
  const [detailsHomeSignUp, setDetailsHomeSignUp] = useState(
    props.detailsHomeSignUp || ""
  );
  const [detailsRecoveryCoaching, setDetailsRecoveryCoaching] = useState(
    props.detailsRecoveryCoaching || ""
  );
  const [detailsZoomIDGiven, setDetailsZoomIDGiven] = useState(
    props.detailsZoomIDGiven || ""
  );

  const [belongingsReturned, setBelongingsReturned] = useState(
    props.belongingsReturned || ""
  );
  const [detailsBelongingsReturned, setDetailsBelongingsReturned] = useState(
    props.detailsBelongingsReturned || ""
  );

  const [roomCheck, setRoomCheck] = useState(props.roomCheck || "");
  const [detailsRoomCheck, setDetailsRoomCheck] = useState(
    props.detailsRoomCheck || ""
  );

  const [others, setOthers] = useState(props.others || "");
  const [detailsOthers, setDetailsOthers] = useState(props.detailsOthers || "");
  const [
    openContinuatedTherapySupportForm,
    setOpenContinuatedTherapySupportForm,
  ] = useState(false);

  //HANDLE FUNCTIONS

  const handleDischargeDateChange = (event) => {
    setProjectedResidentialDischargeDate(event);
  };

  const handleSwotChange = (event) => {
    setSwot(event);
  };

  const handleAftercareStaffChange = (event) => {
    setAftercareStaff(event);
  };

  const handleStartDateAftercare = (event) => {
    setStartDateAftercare(event);
  };

  const handleEndDateAftercare = (event) => {
    setEndDateAftercare(event);
  };

  const handleMAtHomeStaffChange = (event) => {
    setMathomeStaff(event);
  };

  const handleMAtHomeStatus = (event) => {
    setMathomeStatus(event);
  };

  const handleStartDateMAtHome = (event) => {
    setStartDateMAtHome(event);
  };

  const handleEndDateMAtHome = (event) => {
    setEndDateMAtHome(event);
  };

  const handleccStaffChange = (event) => {
    setCcStaff(event);
  };

  const handleccStatusChange = (event) => {
    setCcStatus(event);
  };

  const handleCcStartDateChange = (event) => {
    setCcStartDate(event);
  };

  const handleCcEndDateChange = (event) => {
    setCcEndDate(event);
  };

  const handleRecoveryStaffChange = (event) => {
    setRecoveryStaff(event);
  };

  const handleRecoveryStatus = (event) => {
    setRecoveryStatus(event);
  };

  const handleRecoveryStartDate = (event) => {
    setRecoveryStartDate(event);
  };

  const handleRecoveryEndDate = (event) => {
    setRecoveryEndDate(event);
  };

  const handleSponsor = (value) => setSponsor(value);
  const handleMeetingSchedule = (value) => setMeetingSchedule(value);
  const handleTherapySession = (value) => setTherapySession(value);
  const handleRecoveryCoaching = (value) => setRecoveryCoaching(value);
  const handleReceivedMedication = (value) => setReceivedMedication(value);
  const handlePaidMedication = (value) => setPaidMedication(value);
  const handleWorkbookCompleted = (value) => setWorkbookCompleted(value);
  const handleZoomIDGiven = (value) => setZoomIDGiven(value);
  const handleChangeDetailsSponsor = (e) => setDetailsSponsor(e.target.value);
  const handleChangeDetailsMeetingSchedule = (e) =>
    setDetailsMeetingSchedule(e.target.value);
  const handleChangeDetailsTherapySession = (e) =>
    setDetailsTherapySession(e.target.value);

  const handleChangeDetailsRecoveryCoaching = (e) =>
    setDetailsRecoveryCoaching(e.target.value);

  const handleChangeDetailsZoomIDGiven = (e) => {
    setDetailsZoomIDGiven(e.target.value);
  };

  const handleBelongingsReturned = (e) => {
    setBelongingsReturned(e);
  };

  const handleChangeDetailsBelongingsReturned = (e) => {
    setDetailsBelongingsReturned(e.target.value);
  };

  const handleRoomCheck = (e) => {
    setRoomCheck(e);
  };

  const handleChangeDetailsRoomCheck = (e) => {
    setDetailsRoomCheck(e.target.value);
  };

  const handleOthers = (e) => {
    setOthers(e.target.value);
  };

  const handleOpenContinuatedTherapySupportForm = () =>
    setOpenContinuatedTherapySupportForm(true);
  const handleCloseContinuatedTherapySupportForm = () =>
    setOpenContinuatedTherapySupportForm(false);

  //STYLES

  const styleModalGeneral2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "80%",
    borderRadius: 5,
    // overflowY: "scroll",
    backgroundColor: "white",
    /*border: "1px solid #000",*/
    boxShadow: 24,
  };

  const styles = {
    tabFormContainer: {
      flexDirection: "column",
      marginRight: 10,
    },
    inputLabel: {
      marginLeft: 10,
      marginTop: 20,
    },
    textField: {
      marginLeft: 10,
      width: 200,
      backgroundColor: "#FFFFFF",
      marginTop: 7,
    },
  };

  const updateSwotData = async (clientId) => {
    setIsLoading(true);
    try {
      const clientRef = doc(db, "clients", clientId);

      await updateDoc(clientRef, {
        durationOfStay,
        dateOfAdmission,
        projectedResidentialDischargeDate,
        flight,
        swot,
        aftercareStaff1,
        aftercareStaff2,
        aftercareStaff3,
        nameRecoveryCoach: aftercareStaff3,
        swotDone,
        miraclesAtHome,
        aftercareStaff,
        startDateAftercare,
        endDateAftercare,
        mathomeStaff,
        mathomeStatus,
        startDateMAtHome,
        endDateMAtHome,
        ccStaff,
        ccStatus,
        ccStartDate,
        ccEndDate,
        recoveryStaff,
        recoveryStatus,
        recoveryStartDate,
        recoveryEndDate,
        recoveryRadio,
        googleReview,
        sponsor,
        meetingSchedule,
        therapySession,
        homeSignUp,
        recoveryCoaching,
        receivedMedication,
        paidMedication,
        workbookCompleted,
        zoomIDGiven,
        detailsSponsor,
        detailsMeetingSchedule,
        detailsTherapySession,
        detailsHomeSignUp,
        detailsRecoveryCoaching,
        zoomIDGiven,
        detailsZoomIDGiven,
        belongingsReturned,
        detailsBelongingsReturned,
        roomCheck,
        detailsRoomCheck,
        others,
        detailsOthers,
      });

      setIsLoading(false);
      showCustomToast(`Swot datas successfully updated!`, "success");
    } catch (error) {
      console.error("Error updating swot datas: ", error);
      setIsLoading(false);
      showCustomToast(`Error updating swot datas: ${error}`, "error");
    }
  };

  useEffect(() => {
    setDurationOfStay(props.durationOfStay || "");
    setDateOfAdmission(props.dateOfAdmission || "");
    setProjectedResidentialDischargeDate(
      props.projectedResidentialDischargeDate || ""
    );
    setFligth(props.flight || "");
    setSwot(props.swot || "");
    setAftercareStaff1(props.aftercareStaff1 || "");
    setAftercareStaff2(props.aftercareStaff2 || "");
    setAftercareStaff3(props.nameRecoveryCoach || "");
    setSwotDone(props.swotDone || false);
    setMiraclesAtHome(props.miraclesAtHome || false);
    setAftercareStaff(props.aftercareStaff || "");
    setStartDateAftercare(props.startDateAftercare || "");
    setEndDateAftercare(props.endDateAftercare || "");
    setMathomeStaff(props.mathomeStaff || "");
    setMathomeStatus(props.mathomeStatus || "");
    setStartDateMAtHome(props.startDateMAtHome || "");
    setEndDateMAtHome(props.endDateMAtHome || "");
    setCcStaff(props.ccStaff || "");
    setCcStatus(props.ccStatus || "");
    setCcStartDate(props.ccStartDate || "");
    setCcEndDate(props.ccEndDate || "");
    setRecoveryStaff(props.recoveryStaff || "");
    setRecoveryStatus(props.recoveryStatus || "");
    setRecoveryStartDate(props.recoveryStartDate || "");
    setRecoveryEndDate(props.recoveryEndDate || "");
    setRecoveryRadio(props.recoveryRadio || "");
    setGoogleReview(props.googleReview || "");
    setDetailsSponsor(props.detailsSponsor || "");
    setDetailsMeetingSchedule(props.detailsMeetingSchedule || "");
    setDetailsTherapySession(props.detailsTherapySession || "");
    setDetailsHomeSignUp(props.detailsHomeSignUp || "");
    setDetailsRecoveryCoaching(props.detailsRecoveryCoaching || "");
    setZoomIDGiven(props.zoomIDGiven || "");
    setDetailsZoomIDGiven(props.detailsZoomIDGiven || "");
    setBelongingsReturned(props.belongingsReturned || "");
    setDetailsBelongingsReturned(props.detailsBelongingsReturned || "");
    setRoomCheck(props.roomCheck || "");
    setDetailsRoomCheck(props.detailsRoomCheck || "");
    setOthers(props.others || "");
    setDetailsOthers(props.detailsOthers || "");
  }, [
    props.googleReview,
    props.recoveryRadio,
    props.durationOfStay,
    props.dateOfAdmission,
    props.projectedResidentialDischargeDate,
    props.flight,
    props.swot,
    props.aftercareStaff1,
    props.aftercareStaff2,
    props.nameRecoveryCoach,
    props.swotDone,
    props.miraclesAtHome,
    props.aftercareStaff,
    props.startDateAftercare,
    props.endDateAftercare,
    props.mathomeStaff,
    props.mathomeStatus,
    props.startDateMAtHome,
    props.endDateMAtHome,
    props.ccStaff,
    props.ccStatus,
    props.ccStartDate,
    props.ccEndDate,
    props.recoveryStaff,
    props.recoveryStatus,
    props.recoveryStartDate,
    props.recoveryEndDate,
    props.detailsSponsor,
    props.detailsMeetingSchedule,
    props.detailsTherapySession,
    props.detailsHomeSignUp,
    props.detailsRecoveryCoaching,
    props.zoomIDGiven,
    props.detailsZoomIDGiven,
    props.belongingsReturned,
    props.detailsBelongingsReturned,
    props.roomCheck,
    props.detailsRoomCheck,
    props.others,
    props.detailsOthers,
  ]);

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  return (
    <div className="tab-container-white">
      <Modal
        open={openContinuatedTherapySupportForm}
        onClose={handleCloseContinuatedTherapySupportForm}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModalGeneral2}>
          <AddContinuatedTherapySupport
            closemodal={handleCloseContinuatedTherapySupportForm}
            clientId={props.clientId}
          />
        </Box>
      </Modal>
      <div className="container-main-plan margin-bottom-2">
        <div
          className="tab-container-title"
          style={{ paddingBottom: "20px", paddingLeft: "20px" }}
        >
          Continuated Therapy Support form
        </div>
        <div className="form-line-button">
          <FormLineCTS
            title="Continuated Therapy Support"
            color="1"
            viewButton={props.ContinuatedTherapySupportOk}
            signed={props.ContinuatedTherapySupportOk}
            date={formatDate(props.ContinuatedTherapySupportDateSigned)}
            {...props}
          />
          {props.ContinuatedTherapySupportOk ? (
            ""
          ) : (
            <AddButtonRound
              handleOpen={handleOpenContinuatedTherapySupportForm}
            />
          )}
        </div>
      </div>
      <div
        className="container-main-plan margin-bottom-2"
        style={{ paddingLeft: "25px" }}
      >
        <div className="tab-container-title">Discharge & SWOT Information</div>
        <div className="tab-form-container">
          <DatePickerComponentWithTime
            value={projectedResidentialDischargeDate}
            onChange={handleDischargeDateChange}
            title="Discharge date"
          />

          <DatePickerComponentWithTime
            title="Swot date"
            width="150px"
            value={swot}
            onChange={handleSwotChange}
          />
        </div>
      </div>
      <div
        className="container-main-plan margin-bottom-2"
        style={{ paddingLeft: "25px" }}
      >
        <div className="tab-container-title">Support Team Checklist</div>

        <div className="tab-form-container">
          {/* Sponsor */}
          <div className="tab-form-container" style={styles.tabFormContainer}>
            <DropDownComponentSortedFixed
              api="yesno"
              title="Sponsor?"
              value={sponsor}
              onChange={handleSponsor}
            />
            <div className="input-label" style={styles.inputLabel}>
              {" "}
              Details{" "}
            </div>
            <TextField
              style={styles.textField}
              multiline
              rows={2}
              onChange={handleChangeDetailsSponsor}
              value={detailsSponsor}
            />
          </div>

          {/* AA Meeting Schedule */}
          <div className="tab-form-container" style={styles.tabFormContainer}>
            <DropDownComponentSortedFixed
              api="yesno"
              title="AA Meeting schedule?"
              value={meetingSchedule}
              onChange={handleMeetingSchedule}
            />
            <div className="input-label" style={styles.inputLabel}>
              {" "}
              Details{" "}
            </div>
            <TextField
              style={styles.textField}
              multiline
              rows={2}
              onChange={handleChangeDetailsMeetingSchedule}
              value={detailsMeetingSchedule}
            />
          </div>

          {/* Therapy Session with Miracles */}
          <div className="tab-form-container" style={styles.tabFormContainer}>
            <DropDownComponentSortedFixed
              api="yesno"
              title="Therapy Session with our rehab?"
              value={therapySession}
              onChange={handleTherapySession}
            />
            <div className="input-label" style={styles.inputLabel}>
              {" "}
              Details{" "}
            </div>
            <TextField
              style={styles.textField}
              multiline
              rows={2}
              onChange={handleChangeDetailsTherapySession}
              value={detailsTherapySession}
            />
          </div>

          {/* Miracles@home sign up */}
          {/* <div className="tab-form-container" style={styles.tabFormContainer}>
            <DropDownComponentSortedFixed
              api="yesno"
              title="Miracles@home sign up?"
              value={homeSignUp}
              onChange={handleHomeSignUp}
            />
            <div className="input-label" style={styles.inputLabel}>
              {" "}
              Details{" "}
            </div>
            <TextField
              style={styles.textField}
              multiline
              rows={2}
              onChange={handleChangeDetailsHomeSignUp}
              value={detailsHomeSignUp}
            />
          </div> */}

          {/* Recovery coaching sign up */}
          <div className="tab-form-container" style={styles.tabFormContainer}>
            <DropDownComponentSortedFixed
              api="yesno"
              title="Recovery coaching sign up?"
              value={recoveryCoaching}
              onChange={handleRecoveryCoaching}
            />
            <div className="input-label" style={styles.inputLabel}>
              {" "}
              Details{" "}
            </div>
            <TextField
              style={styles.textField}
              multiline
              rows={2}
              onChange={handleChangeDetailsRecoveryCoaching}
              value={detailsRecoveryCoaching}
            />
          </div>
          <div className="tab-form-container" style={styles.tabFormContainer}>
            <DropDownComponentSortedFixed
              api="yesno"
              title="Our rehab Online meeting zoom ID given to client?"
              value={zoomIDGiven}
              onChange={handleZoomIDGiven}
            />
            <div className="input-label" style={styles.inputLabel}>
              {" "}
              Details{" "}
            </div>
            <TextField
              style={styles.textField}
              multiline
              rows={2}
              onChange={handleChangeDetailsZoomIDGiven}
              value={detailsZoomIDGiven}
            />
          </div>

          <div className="tab-form-container" style={styles.tabFormContainer}>
            <DropDownComponentSortedFixed
              api="yesno"
              title="Client’s belongings returned to client?"
              value={belongingsReturned}
              onChange={handleBelongingsReturned}
            />
            <div className="input-label" style={styles.inputLabel}>
              {" "}
              Details{" "}
            </div>
            <TextField
              style={styles.textField}
              multiline
              rows={2}
              placeholder="List items here"
              onChange={handleChangeDetailsBelongingsReturned}
              value={detailsBelongingsReturned}
            />
          </div>

          <div className="tab-form-container" style={styles.tabFormContainer}>
            <DropDownComponentSortedFixed
              api="yesno"
              title="Room check upon discharge for client’s belongings"
              value={roomCheck}
              onChange={handleRoomCheck}
            />
            <div className="input-label" style={styles.inputLabel}>
              {" "}
              Details{" "}
            </div>
            <TextField
              style={styles.textField}
              multiline
              rows={2}
              onChange={handleChangeDetailsRoomCheck}
              value={detailsRoomCheck}
            />
          </div>

          <div className="tab-form-container" style={styles.tabFormContainer}>
            {/* <DropDownComponentSortedFixed
              api="yesno"
              title="Room check upon discharge for client’s belongings"
              value={recoveryCoaching}
              onChange={handleRecoveryCoaching}
            /> */}
            <div className="input-label" style={styles.inputLabel}>
              {" "}
              Others{" "}
            </div>
            <TextField
              style={styles.textField}
              multiline
              rows={2}
              onChange={handleOthers}
              value={others}
            />
          </div>
        </div>
        <div className="tab-form-container"></div>
        <div className="tab-form-container">
          <DropDownComponentSortedFixed
            api="yesno"
            title="Did they receive medication?"
            value={receivedMedication}
            onChange={handleReceivedMedication}
          />
          <DropDownComponentSortedFixed
            api="yesno"
            title="Did they pay medication?"
            value={paidMedication}
            onChange={handlePaidMedication}
          />
        </div>
        <div className="tab-form-container">
          <DropDownComponentSortedFixed
            api="yesno"
            title="Relapse Prevention Workbook completed?"
            value={workbookCompleted}
            onChange={handleWorkbookCompleted}
          />
          <DropDownComponentSortedFixed
            api="yesno"
            title="Miracles online meeting Zoom ID given to
            client?"
            value={zoomIDGiven}
            onChange={handleZoomIDGiven}
          />
        </div>
      </div>

      <div
        className="container-main-plan margin-bottom-2"
        style={{ paddingLeft: "25px" }}
      >
        <div className="tab-container-title">Aftercare</div>

        <div className="tab-form-container">
          <DropDownSpecificStaff
            title="Assigned support staff"
            value={aftercareStaff}
            onChange={handleAftercareStaffChange}
            allowedRoles={["Support Staff"]}
          />
        </div>

        <div className="tab-form-container">
          <DatePickerComponent
            title="Start date"
            width="150px"
            value={startDateAftercare}
            onChange={handleStartDateAftercare}
          />

          <DatePickerComponent
            title="End date"
            width="150px"
            value={endDateAftercare}
            onChange={handleEndDateAftercare}
          />
        </div>
      </div>

      <div
        className="container-main-plan margin-bottom-2"
        style={{ paddingLeft: "25px" }}
      >
        <div className="tab-container-title">Miracles@Home</div>

        <div className="tab-form-container">
          <DropDownSpecificStaff
            title="Assigned support staff"
            value={mathomeStaff}
            onChange={handleMAtHomeStaffChange}
            allowedRoles={["Psycho-ed"]}
          />

          <DropDownComponentSorted
            api="status"
            title="Status"
            value={mathomeStatus}
            onChange={handleMAtHomeStatus}
          />
        </div>

        <div className="tab-form-container">
          <DatePickerComponent
            title="Start date"
            width="150px"
            value={startDateMAtHome}
            onChange={handleStartDateMAtHome}
          />

          <DatePickerComponent
            title="End date"
            width="150px"
            value={endDateMAtHome}
            onChange={handleEndDateMAtHome}
          />
        </div>
      </div>

      <div
        className="container-main-plan margin-bottom-2"
        style={{ paddingLeft: "25px" }}
      >
        <div className="tab-container-title">
          Continued Counselling with our Therapists
        </div>

        <div className="tab-form-container">
          <DropDownSpecificStaff
            title="Assigned staff"
            value={ccStaff}
            onChange={handleccStaffChange}
            allowedRoles={["Therapist"]}
          />

          <DropDownComponentSorted
            api="status"
            title="Status"
            value={ccStatus}
            onChange={handleccStatusChange}
          />
        </div>

        <div className="tab-form-container">
          <DatePickerComponent
            title="Start date"
            width="150px"
            value={ccStartDate}
            onChange={handleCcStartDateChange}
          />

          <DatePickerComponent
            title="End date"
            width="150px"
            value={ccEndDate}
            onChange={handleCcEndDateChange}
          />
        </div>
      </div>

      <div
        className="container-main-plan"
        style={{ marginBottom: 70, paddingLeft: 20 }}
      >
        <div className="tab-container-title">Recovery Coaching</div>

        <div className="tab-form-container">
          <DropDownSpecificStaff
            title="Assigned staff"
            value={recoveryStaff}
            onChange={handleRecoveryStaffChange}
            allowedRoles={["Psycho-ed"]}
          />

          <DropDownComponentSorted
            api="status"
            title="Status"
            value={recoveryStatus}
            onChange={handleRecoveryStatus}
          />
        </div>

        <div className="tab-form-container">
          <DatePickerComponent
            title="Start date"
            width="150px"
            value={recoveryStartDate}
            onChange={handleRecoveryStartDate}
          />

          <DatePickerComponent
            title="End date"
            width="150px"
            value={recoveryEndDate}
            onChange={handleRecoveryEndDate}
          />
        </div>
      </div>

      <div className="button-container-form-gi">
        <Button
          variant="contained"
          onClick={() => updateSwotData(props.clientId)}
          style={
            isLoading
              ? {
                  backgroundColor: "red",
                  color: "white",
                  marginLeft: "15px",
                }
              : {
                  backgroundColor: "#1565C0",
                  color: "white",
                  marginLeft: "15px",
                }
          }
          disabled={isLoading}
          //   onClick={handlePostRecord}

          sx={{
            height: "40px",
            width: "220px",
            marginLeft: "25px",
            marginRight: "30px",
          }}
        >
          {isLoading ? "SAVING MODIFICATIONS..." : "SAVE MODIFICATIONS"}
        </Button>
      </div>
    </div>
  );
}

export default Swots;
