import { Avatar } from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LanguageContext } from "../../context/LanguageContext";
import ChipStatusMobile from "../ChipStatusMobile";

function ClientRowDetailsV2({
  clientStatus,
  firstName,
  lastName,
  primaryAddiction,
  profilPicture,
  idClient,
  clientData,
  roomNumber,
  dob,
}) {
  const { translate } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [shouldNavigate, setShouldNavigate] = React.useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleNavigate = () => {
    setShouldNavigate(true);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (shouldNavigate) {
      navigate(`/client/${idClient}`, {
        state: clientData, // Passez l'objet client complet
      });
    }
  }, [shouldNavigate]);
  const fullName = firstName + " " + lastName;
  const textStyle = {
    width: "5%",
    // paddintRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
  };

  const handleNavigateToAlerts = () => {
    navigate(`/client/${idClient}`, {
      state: { clientData, selectedTab: "8" }, // 8 correspond à l'onglet Alerts
    });
  };

  const alertCounts = {
    reminders: (clientData.alerts || []).filter((alert) => alert.type === 1)
      .length,
    allergies: (clientData.alerts || []).filter((alert) => alert.type === 2)
      .length,
    alerts: (clientData.alerts || []).filter((alert) => alert.type === 3)
      .length,
  };

  const textStyle2 = {
    width: "5%",
    // paddingRight: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    justifyContent: "flex-start",
  };

  const textStyle4 = {
    width: "13%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // paddingRight: "10px",
    // paddingLeft: "5px",
    textAlign: "left",
  };

  const textStyle5 = {
    width: "65%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // paddingRight: "10px",
    fontSize: "12px",
    textAlign: "left",
  };

  const textStyle6 = {
    width: "65%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // paddingRight: "10px",
    fontSize: "14px",
    fontWeight: "500",
    color: "black",
  };

  return (
    <div style={{ paddingLeft: "15px", width: "100%" }}>
      <div
        style={{
          width: "100%",
          cursor: "pointer",
          marginBottom: "0px",
          borderBottom: "1px solid lightgrey",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "5px",
            // padding: "10px",
            paddingLeft: "0px",
            color: "grey",
            fontSize: "14px",
            fontWeight: "450",
            height: "60px",
            backgroundColor: isHovered ? "#f9f9f9" : "white",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "40%",
            }}
            onClick={handleNavigate}
          >
            <div style={{ width: "35%" }}>
              <Avatar
                src={profilPicture}
                alt="Profile Picture"
                sx={{
                  marginRight: "10px",
                  borderRadius: "8px", // Ajustez cette valeur pour contrôler l'arrondi des bords
                  width: 30, // Ajustez la largeur pour obtenir une forme carrée
                  height: 30, // Ajustez la hauteur pour obtenir une forme carrée
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "65%",
                height: "auto",
                justifyContent: "center",
              }}
            >
              <div style={{ ...textStyle6, fontSize: "10px" }}>{fullName}</div>
              <div style={{ ...textStyle5 }}>
                <ChipStatusMobile status={clientStatus} />
              </div>
            </div>
          </div>

          <div
            style={{
              ...textStyle4,
              width: "20%",
              paddingLeft: "0px",

              whiteSpace: "nowrap", // Pour forcer le texte à rester sur une seule ligne
              overflow: "hidden", // Cache le texte qui dépasse
              textOverflow: "ellipsis",
            }}
            onClick={handleNavigate}
          >
            <div
              style={{
                color: "#000",
                fontSize: "12px",
                fontWeight: "550",
                textAlign: "center",
                width: "100%",
                whiteSpace: "nowrap", // Pour forcer le texte à rester sur une seule ligne
                overflow: "hidden", // Cache le texte qui dépasse
                textOverflow: "ellipsis",
              }}
            >
              {primaryAddiction}
            </div>
          </div>

          {/* <div style={textStyle}>{personalEmail}</div> */}

          <div
            style={{
              ...textStyle2,
              width: "20%",
            }}
            onClick={handleNavigate}
          >
            <div
              style={{
                color: "#000",
                width: "100%",
                fontSize: "14px",
                fontWeight: "550",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              {roomNumber ? roomNumber : "--"}
            </div>
          </div>

          <div
            style={{
              ...textStyle,
              width: "20%",
              paddingLeft: "0px",
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "0px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
            // onClick={handleNavigate}
            onClick={handleNavigateToAlerts}
          >
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor:
                  alertCounts.alerts > 0
                    ? "rgba(255, 0, 0, 0.8)"
                    : "rgba(255, 0, 0, 0.1)",
                animation:
                  alertCounts.alerts > 0 ? "blink 3s infinite" : "none",
              }}
              title={`Alerts: ${alertCounts.alerts}`}
            ></div>

            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor:
                  alertCounts.reminders > 0
                    ? "rgba(0, 0, 255, 0.8)"
                    : "rgba(0, 0, 255, 0.1)",
                animation:
                  alertCounts.reminders > 0 ? "blink 3s infinite" : "none",
              }}
              title={`Reminders: ${alertCounts.reminders}`}
            ></div>

            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor:
                  alertCounts.allergies > 0
                    ? "rgba(255, 165, 0, 0.8)"
                    : "rgba(255, 165, 0, 0.1)",
                animation:
                  alertCounts.allergies > 0 ? "blink 3s infinite" : "none",
              }}
              title={`Allergies: ${alertCounts.allergies}`}
            ></div>
          </div>

          {/* <div
            style={{
              ...textStyle,
              width: "5%",
              paddingLeft: "0px",
            }}
          >
            :
          </div> */}
          <div
            style={{
              ...textStyle,
              width: "5%",
              paddingLeft: "0px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <IconButton onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleNavigate}>{translate("Edit")}</MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientRowDetailsV2;
