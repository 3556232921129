import React, { useContext, useState } from "react";
import { Button } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import BinSyliszed from "../../images/binstyliszed.png";
import { LanguageContext } from "../../context/LanguageContext";
import { showCustomToast } from "../Misc/CustomToast";

function DeleteFile(props) {
  const { translate } = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState(false);

  async function handleDeleteNote() {
    setIsLoading(true);
    try {
      await props.handleDeleteFile();
      // showCustomToast(`${translate("FileSuccessfullyDeleted")}`, "success");
      setIsLoading(false);
      props.closemodal();
    } catch (error) {
      console.error("Error deleting file:", error);
      showCustomToast(`${translate("ErrorDeletingFile")}`, "error");
      setIsLoading(false);
    }
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "70%",
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <img src={BinSyliszed} style={{ width: "60px" }} alt="" />
        <div className="line2-column-modal">
          <div className="confirmation-delete-bis">
            {translate("AreYouSureToDeleteFile")}
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "30%",
          display: "flex",
          justifyContent: "center",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
      >
        <>
          <Button
            variant="contained"
            style={{
              backgroundColor: "white",
              color: "black",
              fontSize: "12px",
            }}
            // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
            onClick={props.closemodal}
          >
            {translate("Cancel")}
          </Button>

          <Button
            variant="contained"
            style={
              isLoading
                ? {
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "15px",
                    fontSize: "12px",
                  }
                : {
                    backgroundColor: "red",
                    color: "white",
                    marginLeft: "15px",
                    fontSize: "12px",
                  }
            }
            disabled={isLoading}
            onClick={handleDeleteNote}
          >
            {isLoading ? translate("Deleting") : translate("Delete")}
          </Button>
        </>
      </div>
    </>
  );
}

export default DeleteFile;
