import React, { useRef, useState } from "react";
import { Button } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import ReactToPrint from "react-to-print";
import ViewBlock from "../Misc/ViewBlock";

function ViewHandoverNotes(props) {
  const printRef = useRef();

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  // Initialize states with props

  const [immigrationAppointment] = useState(
    props.immmigrationAppointment || ""
  );
  const [dischargeSwot] = useState(props.dischargesSwot || "");
  const [newClients] = useState(props.newClients || "");
  const [medicalInfos] = useState(props.medicalInfos || "");
  const [hospitalAppointment] = useState(props.hospitalAppointment || "");
  const [residentialClientMovements] = useState(
    props.residentialClientMovements || ""
  );

  const [handoverNoteDateTime] = useState(props.effectiveDateTime || "");

  const [zoomSessionAssistance] = useState(props.zoomSessionAssistance || "");

  const [staffToday] = useState(props.staffToday || "");
  const [generalAnnouncement] = useState(props.generalAnnouncement || "");

  const [overnight] = useState(props.overnight || "");
  const [numberOfClient] = useState(props.numberOfClient || "");
  const [swot] = useState(props.swot || "");

  // Other state definitions

  // Other handler definitions

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }

  function formatDateWithTime(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    const formattedDate = format(date, "d MMMM yyyy"); // Date
    const formattedTime = format(date, "H 'h' mm 'min'"); // Heure en format européen

    return `${formattedDate} à ${formattedTime}`;
  }

  return (
    <>
      <style>
        {`
          @media print {
            #printableContent {
              height: auto !important;
              overflow-y: visible !important;
            }
          }
        `}
      </style>
      <div
        className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <div className="modal-title2">
            <>View handover note </>
            <span style={{ fontSize: "12px" }}>
              (last modification on{" "}
              {props.updateAt
                ? formatDate(props.updateAt)
                : formatDate(props.createdAt)}{" "}
              by {props.doneBy})
            </span>
          </div>
          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.closemodal}
          />
        </div>

        <div
          id="printableContent"
          style={{
            width: "100%",
            marginTop: "10px",
            height: "75%",
            overflowY: "scroll",
            // overflowX: "hidden",
          }}
          ref={printRef}

          // style={{ marginTop: "60px", height: "500px", overflowY: "scroll" }}
          // style={{ marginTop: "60px", height: "auto", overflowY: "scroll" }}
        >
          {/* <div
            style={{ marginTop: "10px", height: "520px", overflowY: "scroll" }}
          > */}

          <div className="top-title-view-modal">
            Handover note - {formatDate(props.createdAt)}{" "}
            <span style={{ fontSize: "14px" }}> done by :</span>
            <span style={{ fontSize: "16px", fontWeight: "bold" }}>
              {" "}
              {props.doneBy}
            </span>
          </div>

          <ViewBlock
            title="Immigration appointment / visa"
            content={immigrationAppointment}
          />
          <ViewBlock
            title="Discharges / Flights arrangements"
            content={dischargeSwot}
          />
          <ViewBlock title="New clients" content={newClients} />

          <ViewBlock title="Dr. Harshal Appointments" content={medicalInfos} />
          <ViewBlock
            title="(Hospital) appointments"
            content={hospitalAppointment}
          />
          <ViewBlock title="Outings" content={residentialClientMovements} />
          <ViewBlock
            title="Zoom Session Assistance"
            content={zoomSessionAssistance}
          />
          <ViewBlock title="Staff today" content={staffToday} />

          <ViewBlock title="Overnight" content={overnight} />
          <ViewBlock title="Number of clients" content={numberOfClient} />

          <ViewBlock title="Swot" content={swot} />

          <ViewBlock
            title="General announcement / Discussion"
            content={generalAnnouncement}
          />

          <ViewBlock
            title="Effective date and time"
            content={formatDateWithTime(handoverNoteDateTime)}
          />
        </div>

        <div className="line4-modal-percent">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              onClick={props.closemodal}
            >
              Cancel
            </Button>

            <ReactToPrint
              trigger={() => (
                <Button variant="contained" style={{ marginLeft: "20px" }}>
                  Print
                </Button>
              )}
              content={() => printRef.current}
              onAfterPrint={props.closemodal}
            />
          </>
        </div>
      </div>
    </>
  );
}

export default ViewHandoverNotes;
