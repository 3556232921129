import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { Timestamp } from "firebase/firestore";
import dayjs from "dayjs";

export default function DatePickerV2ComponentNoTitleMobileV2(props) {
  const handleChange = (date) => {
    const timestamp = Timestamp.fromMillis(date.valueOf());
    props.onChange(timestamp);
  };

  const dateObject =
    props.value && typeof props.value.toMillis === "function"
      ? dayjs(new Date(props.value.toMillis()))
      : dayjs(props.value);

  return (
    <div className="input-label-container-newdesign-nomargin">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          value={dateObject}
          onChange={handleChange}
          inputFormat="DD/MM/YYYY" // Format de la date
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              InputProps={{
                style: {
                  fontSize: "12px", // Taille de la date affichée
                  padding: "10px", // Padding autour du texte
                  height: "25px", // Hauteur du champ
                },
              }}
              InputLabelProps={{
                style: { fontSize: "12px" }, // Taille du texte du label
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0", // Remise à zéro du padding interne pour contrôler la hauteur
                  height: "40px", // Hauteur de l'input
                },
                "& .MuiOutlinedInput-input": {
                  fontSize: "12px", // Taille du texte de la date
                  padding: "10px", // Padding interne pour ajuster l'espace
                },
                "& .MuiSvgIcon-root": {
                  fontSize: "20px", // Taille de l'icône du calendrier
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
