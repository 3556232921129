import React, { useContext, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import uploadicon from "../../images/uploadfile.png";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import { LanguageContext } from "../../context/LanguageContext";

function UploadedFilesComponentV2({ fileLinks, deleteFile, noteId }) {
  const { translate } = useContext(LanguageContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDeleteClick = (file) => {
    setSelectedFile(file);
    setOpenDialog(true);
  };
  const handleDialogClose = (confirm) => {
    if (confirm && selectedFile) {
      deleteFile(selectedFile.url, selectedFile.name, noteId);
    }
    setOpenDialog(false);
    setSelectedFile(null);
  };

  return (
    <div className="main-upload-container-v2">
      <div className="form2-v3">
        <div
          htmlFor="uploadFiles"
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "15px",
            marginTop: "15px",
            paddingLeft: "5px",
            paddingRight: "5px",
            fontWeight: "600",
            fontSize: "14px",
          }}
        >
          {translate("FileAlreadyUploaded")}
        </div>
        <div
          style={{ width: "100%", paddingLeft: "10px", paddingRight: "10px" }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            {fileLinks.map((file, index) => (
              <div key={index} className="file-item-v2">
                <img src={uploadicon} className="file-icon" alt="file icon" />
                <a
                  style={{
                    marginLeft: "10px",
                    marginRight: "10px",
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    backgroundcolor: "pink",
                  }}
                  href={file.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {file.name}
                </a>
                <DeleteIcon
                  fileName={file.name}
                  // onClick={() => deleteFile(file.url, file.name, noteId)}
                  onClick={() => handleDeleteClick(file)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Dialog
        open={openDialog}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>
          {translate("ConfirmDeletion")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate("AreYouSureToDeleteFile")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            {translate("Cancel")}
          </Button>
          <Button
            onClick={() => handleDialogClose(true)}
            color="primary"
            autoFocus
          >
            {translate("Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UploadedFilesComponentV2;
