import React from "react";
import FamilyNoteContainer from "../Notes/FamilyNoteContainer";
import { useState } from "react";
import { db } from "../../firebase";
import {
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { useEffect } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

function FamilyNotesContainerAll(props) {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setClients] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [clientsWithFamilyNotes, setClientsWithFamilyNotes] = useState([]);

  useEffect(() => {
    const clientsRef = collection(db, "clients");
    const q = query(clientsRef, orderBy("firstName"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const clientsData = [];
      querySnapshot.forEach((doc) => {
        clientsData.push({ id: doc.id, ...doc.data() });
      });
      setClients(clientsData);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const notesRef = collection(db, "notes");
    const q = query(
      notesRef,
      where("noteType", "==", "familyNote"),
      orderBy("effectiveDateTime", "desc"),
      limit(50)
    );

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const notesData = [];
      const clientIds = new Set();
      querySnapshot.forEach((doc) => {
        const note = { id: doc.id, ...doc.data() };
        notesData.push(note);
        clientIds.add(note.clientId);
      });

      const clientsData = [];
      for (const clientId of clientIds) {
        const clientRef = doc(db, "clients", clientId);
        const clientSnap = await getDoc(clientRef);
        if (clientSnap.exists()) {
          clientsData.push({ id: clientId, ...clientSnap.data() });
        }
      }

      setLoading(false);
      setNotes(notesData);
      setClientsWithFamilyNotes(clientsData);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const filteredNotes = selectedClients.length
    ? notes.filter((note) =>
        selectedClients.some((client) => client.id === note.clientId)
      )
    : notes;

  return (
    <div className="notes-container-global">
      <div
        style={{
          width: "92%",
          paddingLeft: "90px",
          paddingRight: "45px",
          marginTop: "20px",
        }}
      >
        <Autocomplete
          multiple
          options={clientsWithFamilyNotes}
          getOptionLabel={(client) => `${client.firstName} ${client.lastName}`}
          onChange={(event, value) => setSelectedClients(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Filter by Clients"
              placeholder="Select Clients"
            />
          )}
          style={{ marginBottom: "20px" }}
        />
      </div>

      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
          <p>Loading, please wait...</p>
        </div>
      ) : (
        filteredNotes.map((note) => {
          const dateOptions = {
            day: "numeric",
            month: "short",
            year: "numeric",
          };

          const dateOptions2 = {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          };

          const timeOptions = {
            hour: "2-digit",
            minute: "2-digit",
          };

          const formattedTimestamp = note.createdAt
            ? new Intl.DateTimeFormat("en-EN", dateOptions2).format(
                note.createdAt.toDate()
              )
            : "";

          const formattedLastModification = note.updateAt
            ? new Intl.DateTimeFormat("en-EN", dateOptions2).format(
                note.updateAt.toDate()
              )
            : "";

          const formattedDate = note.effectiveDateTime
            ? new Intl.DateTimeFormat("en-EN", dateOptions).format(
                note.effectiveDateTime.toDate()
              )
            : "";

          const formattedTime = note.effectiveDateTime
            ? new Intl.DateTimeFormat("en-EN", timeOptions).format(
                note.effectiveDateTime.toDate()
              )
            : "";

          return (
            <FamilyNoteContainer
              key={note.id}
              noteContent={note.noteContent}
              effectiveDate={note.effectiveDateTime}
              noteDate={formattedDate}
              noteTime={formattedTime}
              doneBy={note.doneBy}
              timeStamp={formattedTimestamp}
              lastModification={formattedLastModification}
              clientPic={note.clientProfilePicture}
              clientFirstName={note.clientFirstName}
              clientLastName={note.clientLastName}
              noteId={note.id}
              relationship={note.relationShipFamilyNote}
              relationshipOther={note.relationShipFamilyNoteOther}
              fileUrls={note.fileUrls}
              {...note}
            />
          );
        })
      )}
    </div>
  );
}

export default FamilyNotesContainerAll;
