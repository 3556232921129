import React from "react";
import { TextField } from "@mui/material";
import groupType from "../api/groupType"; // Import the necessary API data
import "../css/inputs/Dropdown.css";

export default function DropDownComponentSortedNoTitle(props) {
  const api = props.api;
  const apiData = {
    groupType: groupType,
    // Add other APIs if needed
  };

  const options = apiData[api];

  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  const renderOptions = () => {
    if (props.level === "2") {
      const category = options.find(
        (option) => option.textDropDownEn === props.mainSubstance
      );
      if (category && category.subcategories) {
        return category.subcategories
          .sort((a, b) => a.id - b.id)
          .map((sub) => (
            <option key={sub.id} value={sub.textDropDownEn}>
              {sub.textDropDownEn}
            </option>
          ));
      }
    } else {
      return options
        .sort((a, b) => a.id - b.id)
        .map((option) => (
          <option key={option.id} value={option.textDropDownEn}>
            {option.textDropDownEn}
          </option>
        ));
    }
  };

  return (
    <div className="input-label-container-newdesign">
      <TextField
        className="dropdown-select"
        SelectProps={{
          native: true,
          style: { height: 40 },
        }}
        select
        value={props.value}
        onChange={handleChange}
        error={props.error}
        helperText={props.helperText}
      >
        {renderOptions()}
      </TextField>
    </div>
  );
}
