import React from "react";
import AddNoteButton from "../Buttons/AddNoteButton";

function TopTitleNoteContainerMain(props) {
  return (
    <div className="notes-button-container-center">
      {props.showButton ? (
        <AddNoteButton
          title={props.title}
          handleOpen={props.handleOpen}
          leftMargin="none"
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default TopTitleNoteContainerMain;
