import React, { useContext } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { LanguageContext } from "../../context/LanguageContext";

function DeleteButtonFile({ onClick, fileName }) {
  const { translate } = useContext(LanguageContext);
  const handleDelete = () => {
    if (
      window.confirm(
        `${translate(
          "AreYouSureYouWantToDeleteTheFile"
        )} "${fileName}"${translate("permanently")}`
      )
    ) {
      onClick();
    }
  };

  return (
    <div
      className="square-button-small delete-button-color"
      title="Delete"
      onClick={handleDelete}
    >
      <DeleteForeverIcon style={{ color: "white", fontSize: 20 }} />
    </div>
  );
}

export default DeleteButtonFile;
