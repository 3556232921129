import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import { useAuth } from "../../contexts/AuthContext";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { showCustomToast } from "../Misc/CustomToast";

function EditHandoverNotes(props) {
  const { currentUser } = useAuth();

  // Initialize states with props

  const [immigrationAppointment, setImmigrationAppointment] = useState(
    props.immmigrationAppointment || ""
  );
  const [dischargeSwot, setDischargeSwot] = useState(
    props.dischargesSwot || ""
  );
  const [newClients, setNewClients] = useState(props.newClients || "");
  const [medicalInfos, setMedicalInfos] = useState(props.medicalInfos || "");
  const [hospitalAppointment, setHospitalAppointment] = useState(
    props.hospitalAppointment || ""
  );
  const [residentialClientMovements, setResidentialClientMovements] = useState(
    props.residentialClientMovements || ""
  );
  const [, setMedicalNotes] = useState(props.noteContent || "");
  const [handoverNoteDateTime, setHandoverNoteDateTime] = useState(
    props.effectiveDateTime || ""
  );

  const [zoomSessionAssistance, setZoomSessionAssistance] = useState(
    props.zoomSessionAssistance || ""
  );

  const [staffToday, setStaffToday] = useState(props.staffToday || "");
  const [generalAnnouncement, setGeneralAnnouncement] = useState(
    props.generalAnnouncement || ""
  );

  const [overnight, setOvernight] = useState(props.overnight || "");
  const [numberOfClient, setNumberOfClient] = useState(
    props.numberOfClient || ""
  );
  const [swot, setSwot] = useState(props.swot || "");

  const [hasChanged, setHasChanged] = useState(false);

  const handleHandoverNoteDateTimes = (event) => {
    setHandoverNoteDateTime(event);
    setHasChanged(true);
  };

  const handleStaffToday = (event) => {
    setStaffToday(event.target.value);
    setHasChanged(true);
  };

  const handleOvernight = (event) => {
    setOvernight(event.target.value);
    setHasChanged(true);
  };

  const handleNumberOfClient = (event) => {
    setNumberOfClient(event.target.value);
    setHasChanged(true);
  };

  const handleSwot = (event) => {
    setSwot(event.target.value);
    setHasChanged(true);
  };

  const handleGeneralAnnoucement = (event) => {
    setGeneralAnnouncement(event.target.value);
    setHasChanged(true);
  };

  const handleImmagrationAppointment = (event) => {
    setImmigrationAppointment(event.target.value);
    setHasChanged(true);
  };

  const handleZoomSessionAssistance = (event) => {
    setZoomSessionAssistance(event.target.value);
    setHasChanged(true);
  };

  const handleDischargeSwot = (event) => {
    setDischargeSwot(event.target.value);
    setHasChanged(true);
  };

  const handleNewClients = (event) => {
    setNewClients(event.target.value);
    setHasChanged(true);
  };

  const handleMedicalInfos = (event) => {
    setMedicalInfos(event.target.value);
    setHasChanged(true);
  };

  const handleHospitalAppointment = (event) => {
    setHospitalAppointment(event.target.value);
    setHasChanged(true);
  };

  const handleResidentialClientMovements = (event) => {
    setResidentialClientMovements(event.target.value);
    setHasChanged(true);
  };

  // Other state definitions
  const [isLoading, setIsLoading] = useState(false);

  // Other handler definitions

  const handleUpdateGroupNote = async (effectiveDateTime) => {
    const noteData = {
      noteType: "handoverNote",
      // noteContent: note,
      updateAt: serverTimestamp(),
      effectiveDateTime: effectiveDateTime,
      doneBy: currentUser.displayName,
      immmigrationAppointment: immigrationAppointment,
      dischargesSwot: dischargeSwot,
      newClients: newClients,
      medicalInfos: medicalInfos,
      hospitalAppointment: hospitalAppointment,
      residentialClientMovements: residentialClientMovements,
      zoomSessionAssistance: zoomSessionAssistance,
      staffToday: staffToday,
      generalAnnouncement: generalAnnouncement,
      overnight: overnight,
      numberOfClient: numberOfClient,
      swot: swot,
    };

    if (
      immigrationAppointment &&
      dischargeSwot &&
      newClients &&
      medicalInfos &&
      hospitalAppointment &&
      residentialClientMovements &&
      zoomSessionAssistance &&
      staffToday &&
      generalAnnouncement &&
      overnight &&
      numberOfClient &&
      swot
    ) {
      setIsLoading(true);
      try {
        const docRef = doc(db, "notes", props.noteId); // Get a reference to the document to update
        await updateDoc(docRef, noteData); // Update the document
        showCustomToast("Handover note successfully updated!", "success");

        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.log("error updating note :", error);
        showCustomToast("Problem when updating note!", "error");
      }
    } else {
      showCustomToast(
        "Some mandatory fields are empty. Please check.!",
        "error"
      );
    }
  };

  const handleSubmit = () => {
    handleUpdateGroupNote(handoverNoteDateTime);
  };

  return (
    <>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div className="line1-modal-percent">
          <div className="modal-title2">
            <>Edit handover note </>
          </div>
          <CloseIcon
            style={{
              marginRight: "16px",
              color: "rgb(214, 213, 213)",
              cursor: "pointer",
            }}
            onClick={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "0px",
            height: "75%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div className="line2-column-modal-handover">
            <div className="tab-form-container-handover">
              <div className="textfield-and-label-container100">
                <div className="label-textfield-handover">
                  Immigration appointment / visa
                </div>
                <TextField
                  value={immigrationAppointment}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleImmagrationAppointment}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div className="textfield-and-label-container100">
                {" "}
                <div className="label-textfield-handover">
                  Discharges / Flights arrangements
                </div>
                <TextField
                  value={dischargeSwot}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleDischargeSwot}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div className="textfield-and-label-container100">
                <div className="label-textfield-handover">New clients</div>
                <TextField
                  value={newClients}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleNewClients}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div className="textfield-and-label-container100">
                {" "}
                <div className="label-textfield-handover">
                  Dr. Harshal Appointments
                </div>
                <TextField
                  value={medicalInfos}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleMedicalInfos}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div className="textfield-and-label-container100">
                <div className="label-textfield-handover">
                  (Hospital) appointments
                </div>
                <TextField
                  value={hospitalAppointment}
                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleHospitalAppointment}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div className="textfield-and-label-container100">
                {" "}
                <div className="label-textfield-handover">Outings</div>
                <TextField
                  value={residentialClientMovements}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleResidentialClientMovements}
                />
              </div>
            </div>

            <div className="tab-form-container-handover">
              <div
                className="textfield-and-label-container100"
                style={{ marginTop: "20px" }}
              >
                {" "}
                <div className="label-textfield-handover">
                  Zoom Session Assistance
                </div>
                <TextField
                  value={zoomSessionAssistance}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleZoomSessionAssistance}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div
                className="textfield-and-label-container100"
                style={{ marginTop: "20px" }}
              >
                {" "}
                <div className="label-textfield-handover">Staff today</div>
                <TextField
                  value={staffToday}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleStaffToday}
                />
              </div>
            </div>
            <div className="tab-form-container-handover">
              <div
                className="textfield-and-label-container100"
                style={{ marginTop: "20px" }}
              >
                {" "}
                <div className="label-textfield-handover">Overnight</div>
                <TextField
                  value={overnight}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleOvernight}
                />
              </div>
            </div>

            <div className="tab-form-container-handover">
              <div
                className="textfield-and-label-container100"
                style={{ marginTop: "20px" }}
              >
                {" "}
                <div className="label-textfield-handover">
                  Number of clients
                </div>
                <TextField
                  value={numberOfClient}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleNumberOfClient}
                />
              </div>
            </div>

            <div className="tab-form-container-handover">
              <div
                className="textfield-and-label-container100"
                style={{ marginTop: "20px" }}
              >
                {" "}
                <div className="label-textfield-handover">Swot</div>
                <TextField
                  value={swot}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "95%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="..."
                  multiline
                  rows={5}
                  onChange={handleSwot}
                />
              </div>

              <div className="tab-form-container-handover">
                <div className="label-textfield-handover">
                  General announcement / Discussion
                </div>
                <TextField
                  value={generalAnnouncement}
                  //   onChange={(e) => setPicture2SubText(e.target.value)}

                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="Type medical note here"
                  multiline
                  rows={5}
                  onChange={handleGeneralAnnoucement}
                />

                {/* <TextField
                  value={medicalNotes}
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    backgroundColor: "#f2eeef",
                  }}
                  placeholder="Type medical note here"
                  multiline
                  rows={5}
                  onChange={handleMedicalNotes}
                /> */}
              </div>
            </div>
          </div>
          <div
            className="line3-modal"
            style={{
              paddingLeft: "20px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <DatePickerWithTimeComponent
              value={handoverNoteDateTime}
              onChange={handleHandoverNoteDateTimes}
              title="Effective date and time"
            />
          </div>
        </div>
        <div className="line4-modal-percent">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={
                !hasChanged // si aucune modification n'a été effectuée
                  ? {
                      backgroundColor: "lightgray", // bouton est gris clair
                      color: "black",
                      marginLeft: "15px",
                    }
                  : isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={!hasChanged || isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? "UPDATING..." : "UPDATE NOTE"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default EditHandoverNotes;
