import { Avatar, Button, CircularProgress, Input } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import RoleWidget from "../component/RoleWidget";
import "../css/dashboard.css";
import "../css/setting.css";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-tippy/dist/tippy.css";
import { roles } from "../firebase.collection";
import {
  doc,
  documentId,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { showCustomToast } from "../component/Misc/CustomToast";
import TopTitleBannerV2 from "../component/TopTitleBannerV2";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { LanguageContext } from "../context/LanguageContext";

export default function AccountContent() {
  const { translate } = useContext(LanguageContext);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [userRole, setUserRole] = useState("");
  const [, setIsActive] = useState(true);
  const [phoneNumberUpdateInProgress, setPhoneNumberUpdateInProgress] =
    useState(false);

  const [isEditing, setIsEditing] = useState({
    photo: false,
    email: false,
    name: false,
    phoneNumber: false,
  });

  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingName, setIsUpdatingName] = useState(false);
  const [isUpdatingPhoneNumber, setIsUpdatingPhoneNumber] = useState(false);

  const { currentUser, logOut } = useAuth();
  if (currentUser) {
  } else {
    console.log("No user logged in.");
  }

  const userIdState = currentUser ? currentUser.uid : "";

  const handleEditClick = (field) => {
    setIsEditing((prevState) => ({ ...prevState, [field]: true }));
  };

  const handleCancelClick = (field) => {
    setIsEditing((prevState) => ({ ...prevState, [field]: false }));
    if (field === "name") {
      setFirstName(displayedName.split(" ")[0] || "");
      setLastName(displayedName.split(" ")[1] || "");
    }
  };

  const handleSaveClick = (field) => {
    setIsEditing((prevState) => ({ ...prevState, [field]: false }));

    if (field === "photo") {
      setIsUpdating(true);
    } else if (field === "name") {
      setIsUpdatingName(true);
    } else if (field === "phoneNumber") {
      setIsUpdatingPhoneNumber(true);
    }

    updateRole(field);
  };

  const handlePhotoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedPhoto = event.target.files[0];
      setNewPhoto(selectedPhoto);

      // Créer une URL de prévisualisation
      const previewUrl = URL.createObjectURL(selectedPhoto);
      setPreviewPhotoUrl(previewUrl);
    }
  };

  useEffect(() => {
    if (currentUser) {
      queryRole();
    } else {
      console.log("No user id");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;
          const phone = doc.data().phoneNumber;
          const photoUrlFromDb = doc.data().photoUrl;
          const firstNameFromDb = doc.data().firstName;
          const lastNameFromDb = doc.data().lastName;

          setUserRole(role);
          setPhoneNumber(phone);
          if (photoUrlFromDb) {
            setPhotoUrl(photoUrlFromDb);
          }
          if (firstNameFromDb) {
            setFirstName(firstNameFromDb);
          }
          if (lastNameFromDb) {
            setLastName(lastNameFromDb);
          }
        } else {
          console.log("Cloud function will be deployed");
        }
      }
    );
  }

  function updateRole(field) {
    const roleRef = doc(db, "roles", userIdState);
    const originalPhoneNumber = phoneNumber;
    setIsActive(false);
    setPhoneNumberUpdateInProgress(true);

    const updateData = {
      phoneNumber: originalPhoneNumber,
      firstName,
      lastName,
    };

    if (field === "photo" && newPhoto) {
      const storage = getStorage();
      const storageRef = ref(storage, `profile_pictures/${userIdState}`);
      uploadBytes(storageRef, newPhoto).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          updateData.photoUrl = url;
          setDoc(roleRef, updateData, { merge: true })
            .then(() => {
              showCustomToast(
                `${translate("ProfileUpdatedSuccessfully")}`,
                "success"
              );
              setPhotoUrl(url);
              setPreviewPhotoUrl("");
            })
            .catch((error) => {
              console.error("Error updating profile: ", error);
            })
            .finally(() => {
              setIsActive(true);
              setPhoneNumberUpdateInProgress(false);
              setIsUpdating(false);
            });
        });
      });
    } else {
      setDoc(roleRef, updateData, { merge: true })
        .then(() => {
          showCustomToast(
            `${translate("ProfileUpdatedSuccessfully")} `,
            "success"
          );
        })
        .catch((error) => {
          console.error("Error updating profile: ", error);
        })
        .finally(() => {
          setIsActive(true);
          setPhoneNumberUpdateInProgress(false);
          if (field === "photo") setIsUpdating(false);
          if (field === "name") setIsUpdatingName(false);
          if (field === "phoneNumber") setIsUpdatingPhoneNumber(false);
        });
    }
  }

  const email = currentUser ? currentUser.email : "";
  const displayedName = currentUser ? currentUser.displayName : "";
  const [photoUrl, setPhotoUrl] = useState(
    currentUser ? currentUser.photoURL : ""
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [newPhoto, setNewPhoto] = useState(null);
  const [previewPhotoUrl, setPreviewPhotoUrl] = useState("");

  async function handleLogout() {
    setError("");
    try {
      await logOut();
      showCustomToast(
        `${translate("User")} ${currentUser.email} ${translate(
          "SuccessfullyLoggedOut"
        )}`,
        "success"
      );

      navigate("/login");
    } catch {
      setError("Failed to logout");
      console.log("Error", error);
    }
  }

  return (
    <div className="right-container-settings">
      <div>
        <TopTitleBannerV2 title={"Settings"} />

        <div style={{ paddingLeft: "60px", paddingRight: "60px" }}>
          <div
            style={{
              width: "100%",
              borderBottom: "1px solid lightgrey",
              paddingLeft: "0px",
              paddingRight: "60px",
              height: "80px",
              display: "flex",
              flexDirection: "row",
              alignSelf: "end",
              alignContent: "flex-end",
              alignItems: "flex-end",
              color: "black",
              fontSize: "24px",
              fontWeight: "600",
              paddingBottom: "5px",
            }}
          >
            {translate("Profile")}
          </div>
        </div>

        <div style={{ paddingLeft: "60px", paddingRight: "60px" }}>
          <div
            className="profil-and-button-container"
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "0px",
              paddingRight: "0px",
              marginTop: "0px",
              borderBottom: "1px solid lightgrey",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "600", width: "40%" }}>
              {translate("Photo")}
            </div>

            <div
              style={{
                width: "60%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignSelf: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar
                // src={currentUser ? photoUrl : ""}
                src={previewPhotoUrl || photoUrl}
                style={{
                  width: "60px",
                  height: "60px",
                  marginLeft: "0px",
                  marginTop: "30px",
                  marginBottom: "25px",
                }}
              />
              <div
                style={{
                  width: "450px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  alignContent: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                {isEditing.photo || isUpdating ? (
                  <>
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="icon-button-file"
                      type="file"
                      onChange={handlePhotoChange}
                    />
                    <label htmlFor="icon-button-file">
                      <div style={{ widht: "250px" }}>
                        <Button
                          variant="outlined"
                          component="span"
                          disabled={phoneNumberUpdateInProgress}
                        >
                          {translate("ChooseFile")}
                        </Button>
                      </div>
                    </label>

                    <div style={{ marginLeft: "10px" }}>
                      <Button
                        variant="outlined"
                        onClick={() => handleCancelClick("photo")}
                        disabled={phoneNumberUpdateInProgress} // Désactiver le bouton pendant la sauvegarde
                      >
                        {translate("Cancel")}
                      </Button>
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <Button
                        variant="contained"
                        onClick={() => handleSaveClick("photo")}
                        disabled={phoneNumberUpdateInProgress} // Désactiver le bouton pendant la sauvegarde
                      >
                        {phoneNumberUpdateInProgress ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          translate("Save")
                        )}
                      </Button>
                    </div>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => handleEditClick("photo")}
                  >
                    {translate("Edit")}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div style={{ paddingLeft: "60px", paddingRight: "60px" }}>
          <div
            className="profil-and-button-container"
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "0px",
              paddingRight: "0px",
              marginTop: "0px",
              borderBottom: "1px solid lightgrey",
              height: "120px",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "600", width: "40%" }}>
              {translate("Email")}
            </div>

            <div
              style={{
                width: "60%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignSelf: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Input
                variant="outlined"
                value={email}
                style={{
                  width: "300px",
                  height: "40px",
                  borderRadius: "5px",
                  background: "#FFFFFF",
                  border: "1px solid #BEBCBC",
                  marginTop: "5px",
                  color: "grey",
                  paddingLeft: "5px",
                }}
                disabled
              />
              <div
                style={{
                  height: "auto",
                }}
              >
                {/* <Button variant="outlined" disabled>
                  Edit
                </Button> */}
              </div>
            </div>
          </div>
        </div>

        <div style={{ paddingLeft: "60px", paddingRight: "60px" }}>
          <div
            className="profil-and-button-container"
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "0px",
              paddingRight: "0px",
              marginTop: "0px",
              borderBottom: "1px solid lightgrey",
              height: "120px",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "600", width: "40%" }}>
              {translate("Name")}
            </div>

            <div
              style={{
                width: "60%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignSelf: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {isEditing.name || isUpdatingName ? (
                <>
                  <div>
                    <Input
                      variant="outlined"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      style={{
                        width: "150px",
                        height: "40px",
                        borderRadius: "5px",
                        background: "#FFFFFF",
                        border: "1px solid #BEBCBC",
                        color: "grey",
                        marginTop: "5px",
                        marginRight: "50px",
                        paddingLeft: "5px",
                      }}
                    />
                    <Input
                      variant="outlined"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      style={{
                        width: "150px",
                        height: "40px",
                        borderRadius: "5px",
                        background: "#FFFFFF",
                        border: "1px solid #BEBCBC",
                        color: "grey",
                        marginTop: "5px",
                        marginRight: "50px",
                        paddingLeft: "5px",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      width: "350px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                      alignContent: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => handleCancelClick("name")}
                      disabled={isUpdatingName}
                    >
                      {translate("Cancel")}
                    </Button>
                    <div style={{ marginLeft: "10px" }}>
                      <Button
                        variant="contained"
                        onClick={() => handleSaveClick("name")}
                        disabled={isUpdatingName}
                      >
                        {isUpdatingName ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          translate("Save")
                        )}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <Input
                      variant="outlined"
                      value={firstName}
                      style={{
                        width: "150px",
                        height: "40px",
                        borderRadius: "5px",
                        background: "#FFFFFF",
                        border: "1px solid #BEBCBC",
                        color: "grey",
                        marginTop: "5px",
                        marginRight: "50px",
                        paddingLeft: "5px",
                      }}
                      disabled
                    />
                    <Input
                      variant="outlined"
                      value={lastName}
                      style={{
                        width: "150px",
                        height: "40px",
                        borderRadius: "5px",
                        background: "#FFFFFF",
                        border: "1px solid #BEBCBC",
                        color: "grey",
                        marginTop: "5px",
                        marginRight: "50px",
                        paddingLeft: "5px",
                      }}
                      disabled
                    />
                  </div>

                  <Button
                    variant="outlined"
                    onClick={() => handleEditClick("name")}
                  >
                    {translate("Edit")}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>

        <div style={{ paddingLeft: "60px", paddingRight: "60px" }}>
          <div
            className="profil-and-button-container"
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "0px",
              paddingRight: "0px",
              marginTop: "0px",
              borderBottom: "1px solid lightgrey",
              height: "120px",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "600", width: "40%" }}>
              {translate("PhoneNumber")}
            </div>

            <div
              style={{
                width: "60%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignSelf: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              {isEditing.phoneNumber || isUpdatingPhoneNumber ? (
                <>
                  <Input
                    variant="outlined"
                    value={phoneNumber}
                    style={{
                      width: "300px",
                      height: "40px",
                      borderRadius: "5px",
                      background: "#FFFFFF",
                      border: "1px solid #BEBCBC",
                      marginTop: "5px",
                      paddingLeft: "5px",
                    }}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  />

                  <div
                    style={{
                      width: "350px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                      alignContent: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="outlined"
                      onClick={() => handleCancelClick("phoneNumber")}
                      disabled={isUpdatingPhoneNumber}
                    >
                      {translate("Cancel")}
                    </Button>
                    <div style={{ marginLeft: "10px" }}>
                      <Button
                        variant="contained"
                        onClick={() => handleSaveClick("phoneNumber")}
                        disabled={isUpdatingPhoneNumber}
                      >
                        {isUpdatingPhoneNumber ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          translate("Save")
                        )}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Input
                    variant="outlined"
                    value={phoneNumber}
                    style={{
                      width: "300px",
                      height: "40px",
                      borderRadius: "5px",
                      background: "#FFFFFF",
                      border: "1px solid #BEBCBC",
                      marginTop: "5px",
                      paddingLeft: "5px",
                    }}
                    disabled
                  />
                  <Button
                    variant="outlined"
                    onClick={() => handleEditClick("phoneNumber")}
                  >
                    {translate("Edit")}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>

        <div style={{ paddingLeft: "60px", paddingRight: "60px" }}>
          <div
            className="profil-and-button-container"
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "0px",
              paddingRight: "0px",
              marginTop: "0px",
              // borderBottom: "1px solid lightgrey",
              height: "120px",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "600", width: "40%" }}>
              {translate("Role")}
            </div>

            <div
              style={{
                width: "60%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignSelf: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <RoleWidget role={userRole} />
              </div>

              <div
                style={{
                  height: "auto",
                }}
              >
                {/* <Button variant="outlined" disabled>
                  Edit
                </Button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-line-setting">
        <div>
          <Button
            style={{
              marginLeft: "60px",
            }}
            variant="outlined"
            color="error"
            onClick={handleLogout}
          >
            {translate("LogOut")}
          </Button>
        </div>
      </div>
    </div>
  );
}
