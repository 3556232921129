import { Avatar } from "@mui/material";
import React from "react";

export default function SmallClientChipV3Large(props) {
  return (
    <div
      // className="small-client-chip-container"
      style={{
        // backgroundColor: "lightyellow",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <Avatar
        style={{
          width: "50px",
          height: "50px",
          border: "1px solid lightgrey",
        }}
        src={props.clientPic}
      />
      <div
        className="small-client-chip-name-large"
        style={{ fontSize: "16px", fontWeight: "600" }}
      >
        {props.firstName} {props.lastName}
      </div>
    </div>
  );
}
