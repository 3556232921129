import React, { useEffect, useState } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import TextField from "@mui/material/TextField";

function DropDownAllStaffsV2(props) {
  const [allStaff, setAllStaff] = useState([]);

  useEffect(() => {
    const roleCollection = collection(db, "roles");
    const unsubscribe = onSnapshot(roleCollection, (querySnapshot) => {
      setAllStaff(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  const renderOptions = () => {
    return (
      <>
        <option value="">--Choose a staff member--</option>
        {allStaff.map((staff) => (
          <option key={staff.id} value={staff.displayName}>
            {staff.displayName}
          </option>
        ))}
      </>
    );
  };

  return (
    <div className="input-label-container">
      <div
        style={{
          color: "#a0a0a0",
          fontWeight: "500",
          fontSize: "12px",
          textTransform: "uppercase",
          paddingBottom: "2px",
        }}
      >
        {props.title}{" "}
        {props.isRequired && <span style={{ color: "red" }}>*</span>}
      </div>
      <TextField
        className="dropdown-select"
        SelectProps={{
          native: true,
          style: { height: 40 },
        }}
        select
        value={props.value}
        onChange={handleChange}
        style={{
          background: "#f3f3f3",
          fontWeight: "500",
          fontSize: "16px",
          width: props.width,
        }}
      >
        {renderOptions()}
      </TextField>
    </div>
  );
}

export default DropDownAllStaffsV2;
