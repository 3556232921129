import React, { useContext } from "react";
import "../css/dashboard.css";
import CollapseComponentMobile from "../component/Misc/CollapseComponentMobile";
import TopTitleBannerV2 from "../component/TopTitleBannerV2";
import { LanguageContext } from "../context/LanguageContext";

export default function DocumentsContent() {
  const { translate } = useContext(LanguageContext);
  return (
    <div className="right-container">
      <TopTitleBannerV2 title={translate("Documents")} />
      <CollapseComponentMobile />
    </div>
  );
}
