import React from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

function DeleteButtonUserData(props) {
  return (
    <div
      className="square-button delete-button-color"
      title="Delete"
      onClick={props.handleOpen}
    >
      <DeleteForeverIcon style={{ color: "white" }} />
    </div>
  );
}

export default DeleteButtonUserData;
