import React from "react";
import "../css/dashboard.css";
import CollapseComponentMobileV2 from "../component/Misc/CollapseComponentMobileV2";

export default function DocumentsContentMobileV2() {
  return (
    <div className="right-container-mobile">
      <CollapseComponentMobileV2 />
    </div>
  );
}
