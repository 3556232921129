import React, { useContext } from "react";
import { format } from "date-fns";
import { Box, Modal, Slide } from "@mui/material";
import { useState } from "react";
import AddReviewForm from "./forms/AddReviewForm";
import { LanguageContext } from "../context/LanguageContext";
import CheckWithDateMobileV2 from "./CheckWithDateMobileV2";
import ViewButtonMobileV2 from "./Buttons/ViewButtonMobileV2";
import ReviewButtonMobileV2 from "./Buttons/ReviewButtonMobileV2";
import EditButtonMobileV2 from "./Buttons/EditButtonMobileV2";
import EditTreatmentPlanDesktopMobileV2 from "./forms/EditTreatmentPlanDesktopMobileV2";
import ViewTreatmentPlanMobileV2 from "./forms/ViewTreatmentPlanMobileV2";

function FormLineMediumTreatmentPlanMobileV2(props) {
  const { translate } = useContext(LanguageContext);

  const [openEditTreatment, setOpenEditTreatment] = useState(false);
  const [openViewTreatment, setOpenViewTreatment] = useState(false);
  const [openAddReviewTreatment, setOpenAddReviewTreatment] = useState(false);

  const handleOpenEditTreatment = () => setOpenEditTreatment(true);
  const handleCloseEditTreatment = () => setOpenEditTreatment(false);
  console.log("openEditTreatment: ", openEditTreatment);

  const handleOpenViewTreatment = () => setOpenViewTreatment(true);
  const handleCloseViewTreatment = () => setOpenViewTreatment(false);

  const handleOpenAddReviewTreatment = () => setOpenAddReviewTreatment(true);
  const handleCloseAddReviewTreatment = () => setOpenAddReviewTreatment(false);

  function formatDate(timestamp) {
    if (!timestamp || !timestamp.seconds) return "";

    const milliseconds =
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    const date = new Date(milliseconds);

    if (isNaN(date)) return "";

    return format(date, "d MMMM yyyy"); // "d" pour le jour, "MMMM" pour le mois en texte, "yyyy" pour l'année
  }
  return (
    <div
      style={{
        marginLeft: "0px",
        display: "flex",
        flexDirection: "row",
        height: "30px",
        borderRadius: "30px",
        border: "1px solid lightgrey",
        width: "100%",
        marginTop: "5px",
        marginBottom: "5px",
        paddingLeft: "15px",
        paddingRight: "5px",
      }}
    >
      <Modal
        open={openEditTreatment}
        onClose={handleCloseEditTreatment}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openEditTreatment}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <EditTreatmentPlanDesktopMobileV2
              {...props}
              clientId={props.clientId}
              closemodal={handleCloseEditTreatment}
            />
          </Box>
        </Slide>
      </Modal>

      <Modal
        open={openViewTreatment}
        onClose={handleCloseViewTreatment}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openViewTreatment}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ViewTreatmentPlanMobileV2
              {...props}
              clientId={props.clientId}
              closemodal={handleCloseViewTreatment}
            />
          </Box>
        </Slide>
      </Modal>

      <Modal
        open={openAddReviewTreatment}
        onClose={handleCloseAddReviewTreatment}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openAddReviewTreatment}>
          <Box
            sx={{
              position: "fixed",
              top: "5%",
              right: 0,
              width: "90%",
              height: "90%",
              bgcolor: "background.paper",
              boxShadow: 24,
              marginRight: "15px",
              pl: 0,
              pr: 0,
              borderRadius: "15px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AddReviewForm
              {...props}
              clientId={props.clientId}
              closemodal={handleCloseAddReviewTreatment}
            />
          </Box>
        </Slide>
      </Modal>

      <div
        style={{
          width: "40%",
          fontSize: "12px",
          fontWeight: "600",
          display: "flex",
          justifyContent: "flex-start",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        {props.presentingIssues} {/* Ajouter un espace après `props.title` */}
      </div>
      <div
        style={{
          width: "30%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.signed === "signed" ? (
          <CheckWithDateMobileV2
            checked="checked"
            content={formatDate(props.date)}
          />
        ) : (
          <CheckWithDateMobileV2
            checked={true}
            content={formatDate(props.date)}
          />
        )}
      </div>
      <div className="formLine2Block2bis">
        {props.reviewDone == true ? (
          <CheckWithDateMobileV2
            checked={props.reviewDone || false}
            content={formatDate(props.reviewDoneAt)}
          />
        ) : (
          <CheckWithDateMobileV2 checked="missed" />
        )}
      </div>
      <div
        style={{
          width: "30%",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
          paddingLeft: "3%",
          paddingRight: "3%",
        }}
      >
        <div style={{ marginRight: "5px" }}>
          <ViewButtonMobileV2
            {...props}
            clientId={props.clientId}
            createBy={props.createdBy}
            createdAt={props.createdAt}
            handleOpen2={handleOpenViewTreatment}
          />
        </div>

        <div style={{ marginRight: "5px" }}>
          <EditButtonMobileV2
            {...props}
            clientId={props.clientId}
            createBy={props.createdBy}
            createdAt={props.createdAt}
            handleOpen2={handleOpenEditTreatment}
          />
        </div>
        <ReviewButtonMobileV2
          {...props}
          clientId={props.clientId}
          createBy={props.createdBy}
          createdAt={props.createdAt}
          handleOpen2={handleOpenAddReviewTreatment}
        />
      </div>
    </div>
  );
}

export default FormLineMediumTreatmentPlanMobileV2;
