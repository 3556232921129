import { Avatar } from "@mui/material";
import React, { useState } from "react";
import "../css/navbar.css";
import { useAuth } from "../contexts/AuthContext";

export default function ProfileWidget(props) {
  const { currentUser } = useAuth();
  const [displayedName] = useState(currentUser ? currentUser.displayName : "");
  const [photoUrl] = useState(currentUser ? currentUser.photoURL : "");

  let roleColor = "";
  switch (props.userRole) {
    case "Super Admin":
      roleColor = "red"; // Jaune doré
      break;
    case "Admin":
      roleColor = "#1E90FF"; // Bleu ciel
      break;
    case "Therapist":
      roleColor = "#32CD32"; // Vert lime
      break;
    case "Support Staff":
      roleColor = "#FFA500"; // Orange vif
      break;
    case "Admission staff":
      roleColor = "#c729e9"; // Violet
      break;
    case "Medical Staff":
      roleColor = "#4B0082"; // Indigo
      break;
    case "Hospitality Staff": // Ajout de cette ligne
      roleColor = "#F08080"; // Vous pouvez choisir la couleur que vous préférez
      break;
    default:
      roleColor = "#808080"; // Gris foncé
      break;
  }

  return (
    <div className="widget-profile-container">
      <div className="avatar-container">
        <Avatar
          src={photoUrl}
          style={{
            width: "72px",
            height: "72px",
            border: "2px solid white",
            // marginLeft: "20px",
          }}
        />
      </div>
      <div className="profil-widget-container">
        <div className="container-chip-role-profile">
          <div
            className="profil-name"
            title={displayedName}
            style={{ cursor: "pointer" }}
          >
            <span className="displayed-name-span">{displayedName}</span>
          </div>
        </div>
        <div className="container-chip-role-profile">
          <div className="profil-role" style={{ background: roleColor }}>
            {props.userRole}
          </div>
        </div>
      </div>
    </div>
  );
}
