import React from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";

function AddButtonRound(props) {
  return (
    <IconButton
      className="add-round-button"
      onClick={props.handleOpen}
      title={props.title}
      size="small"
      style={{ backgroundColor: "#f0f0f0", margin: "5px" }} // Style the button background and margin
    >
      <AddIcon />
    </IconButton>
  );
}

export default AddButtonRound;
