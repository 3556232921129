import React, { useState } from "react";
import RoomLayoutMap from "./RoomLayoutMap";
import RoomLayoutMap2 from "./RoomLayoutMap2";
import TopTitleBannerV2 from "../TopTitleBannerV2";

export default function CasadaSwitch() {
  const [selectedCasada] = useState("Casada 1"); // État pour le switch

  return (
    <div className="main-top-title-banner-container">
      <TopTitleBannerV2 title={"Rooms layout"} />
      <div style={{ paddingTop: "20px" }}>
        {selectedCasada === "Casada 1" ? <RoomLayoutMap /> : <RoomLayoutMap2 />}
      </div>
    </div>
  );
}
