import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import DatePickerWithTimeComponent from "../DatePickerWithTimeComponent";
import { useAuth } from "../../contexts/AuthContext";
import { db } from "../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import TopTitleFormComponent from "../forms/TopTitleFormComponent";

function AddTherapyNote(props) {
  const { currentUser } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [therapyNote, setTherapyNote] = useState("");
  const [therapyNoteDateTime, setTherapyNoteDateTime] = useState(new Date());

  const handleTherapyNoteChange = (event) => {
    setTherapyNote(event.target.value);
  };

  const handleDateTimeChange = (event) => {
    setTherapyNoteDateTime(event);
  };

  const handleAddTherapyNote = async (note, clientID, effectiveDateTime) => {
    const noteData = {
      noteType: "therapyNote",
      noteContent: note,
      clientId: clientID,
      createdAt: serverTimestamp(),
      effectiveDateTime: effectiveDateTime,
      doneBy: currentUser.displayName,
      clientFirstName: props.firstName,
      clientLastName: props.lastName,
      clientProfilePicture: props.profilePicture || "",
    };

    if (therapyNote && therapyNoteDateTime) {
      setIsLoading(true);
      try {
        const docRef = await addDoc(collection(db, "notes"), noteData);
        toast.success(`Therapy note successfully added!`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsLoading(false);
        props.closemodal();
      } catch (error) {
        console.log("error adding note :", error);
        toast.error(`Problem when adding note`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      toast.error(`Some mandatory fields are empty. Please check.`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleSubmit = () => {
    handleAddTherapyNote(therapyNote, props.clientId, therapyNoteDateTime);
  };

  return (
    <>
      <div
        style={{ width: "auto", height: "100%" }}
        className="inputAndLabelModalContainerTitle2"
      >
        <div className="line1-modal-percent">
          <TopTitleFormComponent
            title="ADD 1 TO 1 THERAPY SESSION NOTE"
            closemodal={props.closemodal}
          />
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "0px",
            height: "80%",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div
            className="line2-column-modal"
            style={{
              paddingLeft: "10px",
              // backgroundColor: "pink",
            }}
          >
            <div className="label-textfield" style={{ paddingLeft: "10px" }}>
              Note content
            </div>
            <TextField
              value={therapyNote}
              onChange={handleTherapyNoteChange}
              style={{
                width: "95%",
                borderRadius: "5px",
                backgroundColor: "#f2eeef",
              }}
              placeholder="Type 1 to 1 therapy session note here"
              multiline
              rows={14}
            />
          </div>
          <div
            className="line3-modal-percent"
            style={{
              // marginLeft: "20px",
              paddingLeft: "20px",
              marginBottom: "20px",
              paddingTop: "10px",
            }}
          >
            <DatePickerWithTimeComponent
              title="Effective date and time"
              value={therapyNoteDateTime}
              onChange={handleDateTimeChange}
            />
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "white", color: "black" }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                    }
                  : {
                      backgroundColor: "#1565C0",
                      color: "white",
                      marginLeft: "15px",
                    }
              }
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? "ADDING NOTE..." : "ADD NOTE"}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddTherapyNote;
