import { Avatar } from "@mui/material";
import React from "react";

export default function SmallClientChipV2LargeMobile(props) {
  return (
    <div className="small-client-chip-container-mobile-v2" style={{}}>
      <Avatar
        style={{
          width: "40px",
          height: "40px",
          border: "1px solid lightgrey",
        }}
        src={props.clientPic}
      />
      <div
        className="small-client-chip-name-large"
        style={{ fontSize: "16px", fontWeight: "600" }}
      >
        {props.firstName} {props.lastName}
      </div>
    </div>
  );
}
