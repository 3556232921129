import React, { useEffect, useState } from "react";
import "../css/navbar.css";
import menuitems from "../api/menuitems";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import ProfileWidget from "./ProfileWidget";
import { documentId, onSnapshot, query, where } from "firebase/firestore";
import { roles } from "../firebase.collection";
import { CircularProgress } from "@mui/material";
import InternetCheck from "./Misc/InternetCheck";
import Joyride, { ACTIONS, STATUS } from "react-joyride";

export default function Navbar(props) {
  const { currentUser } = useAuth();
  const [activeLink, setActiveLink] = useState(props.linkActive);

  const [userRole, setUserRole] = useState("");
  const [userIdState] = useState(currentUser ? currentUser.uid : "");
  const [loading, setLoading] = useState(true);
  const [run, setRun] = useState(false);
  const [, setShowHelpButton] = useState(true);

  const customStyles = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50, // Ajustez cette valeur pour positionner le beacon au-dessus
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed", // Utilisez 'fixed' pour que l'overlay couvre toute la page
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Ajustez l'opacité de l'overlay
      zIndex: 1000, // Assurez-vous que l'overlay est au-dessus de tout le reste
    },
    spotlight: {
      // backgroundColor: "rgba(0, 0, 0, 0.7)",
      position: "absolute",
      borderRadius: 0,
    },
    // beacon: {
    //   placement: "top",
    // },
    beaconInner: {
      backgroundColor: "#1565c0", // Couleur du cercle intérieur
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)", // Couleur de l'animation concentrique
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const steps = [
    {
      target: "#personal-information",
      content:
        "In the 'Personal Information' section, you can update various details about the client, including their name, date of birth, and contact information. Ensure that all the information is accurate and up to date.",
    },
    {
      target: "#treatment-information",
      content:
        "The 'Treatment Information' section allows you to manage the client's treatment details, including their status, assigned therapist, and room number. Keeping this information current is essential for proper care management.",
    },
    {
      target: "#admission-and-discharge",
      content:
        "In the 'Admission & Discharge' section, you can set and update the client's admission and discharge dates. This section helps in tracking the client's stay and planning their treatment timeline.",
    },
    {
      target: "#passport-picture",
      content:
        "The 'Passport Pictures' section is where you can upload and view the client's passport photos. Ensure the images are clear and meet the required standards.",
    },
    {
      target: "#emergency-contact",
      content:
        "The 'Emergency Contact' section is vital for providing contact details in case of emergencies. Fill in the necessary information for timely communication when needed.",
    },
  ];

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  function handleLinkClick(linkId) {
    setActiveLink(linkId === activeLink ? null : linkId);
  }

  useEffect(() => {
    if (currentUser) {
      queryRole();
    } else {
      console.log("No user id");
    }
  }, [currentUser]);

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;
          const phone = doc.data().phoneNumber;

          setUserRole(role);
          setLoading(false);
        } else {
          console.log("Problem loading roles");
        }
      }
    );
  }

  const renderMenuItems = (items) => {
    return items
      .filter((item) => item.roles.includes(userRole))
      .map((item, index) => (
        <div className="sidebar-title" key={index}>
          <div className="icon-menu">{item.icon}</div>
          <Link
            className={`link-navbar ${item.id === activeLink ? "active" : ""}`}
            to={item.link}
            onClick={() => handleLinkClick(item.id)}
          >
            <div
              className={`menu-title ${item.id === activeLink ? "active" : ""}`}
            >
              {item.name}
            </div>
          </Link>
        </div>
      ));
  };

  return (
    <div className="navbar-container">
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />
      <div className="navbar-top-group">
        <div className="profile-widget-component">
          <ProfileWidget userRole={userRole} />
        </div>
        <div className="menu-items-container">
          {loading ? (
            <div className="circular-progress-container">
              <CircularProgress />
              <div className="loading-text">Loading, please wait...</div>
            </div>
          ) : (
            <>
              {renderMenuItems(
                menuitems.filter(
                  (item) =>
                    ![
                      "My Profile",
                      "Roles",
                      "Staff contacts",
                      // "Export Data",
                      // "Support Dashboard",
                    ].includes(item.name)
                )
              )}
              <div className="menu-separator"></div>
              {renderMenuItems(
                menuitems.filter((item) =>
                  [
                    "My Profile",
                    "Roles",
                    "Staff contacts",
                    // "Export Data",
                    // "Support Dashboard",
                  ].includes(item.name)
                )
              )}
            </>
          )}
        </div>
      </div>
      <div className="navbar-bottom-group-v2">
        <InternetCheck />
      </div>
    </div>
  );
}
