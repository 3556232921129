import React from "react";
import AllNotesContainer from "../containers/AllNotesContainer";

function ClientNotesMain(props) {
  return (
    <div className="tab-container-white-v2">
      <AllNotesContainer />
    </div>
  );
}

export default ClientNotesMain;
