import { TextField } from "@mui/material";
import React from "react";

export default function TextFieldComponentV2(props) {
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };
  return (
    <div
    // className="input-label-container"
    >
      <div
        style={{
          color: "#a0a0a0",
          fontWeight: "500",
          fontSize: "12px",
          textTransform: "uppercase",
        }}
      >
        {props.title}
      </div>
      <TextField
        style={{
          width: props.width ? props.width : "auto",
          background: "#f3f3f3",
          marginTop: 5,
        }}
        multiline
        rows={4}
        value={props.value}
        onChange={handleChange}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        }}
      />
    </div>
  );
}
