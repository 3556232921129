import React, { useContext, useEffect } from "react";
import SearchBar from "./SearchBar";
import ProfileWidgetV2 from "./ProfileWidgetV2";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import AddButtonRoundSmall from "./Buttons/AddButtonRoundSmall";
import {
  Modal,
  Box,
  Slide,
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  InputLabel,
  IconButton,
  MenuItem,
  FormControl,
  Select,
} from "@mui/material";
import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import gender from "../api/gender";
import addictions from "../api/addictions";
import durationOfStay from "../api/durationOfStay";
import clientSource from "../api/clientSource";
import countries from "../api/countries";
import PhoneNumberInputNoTitleV2 from "./PhoneNumberInputNoTitleV2";
import { showCustomToast } from "./Misc/CustomToast";
import { LanguageContext } from "../context/LanguageContext";
import logoAi from "../images/ai-logo.png";
import Joyride, { ACTIONS, STATUS } from "react-joyride";

export default function TopTitleBannerV2(props) {
  const { translate } = useContext(LanguageContext);

  const navigate = useNavigate();
  const [openAddClientModal, setOpenAddClientModal] = useState(false);
  const handleOpenAddClientModal = () => {
    resetForm();
    setOpenAddClientModal(true);
  };
  const handleCloseAddClientModal = () => {
    resetForm();
    setOpenAddClientModal(false);
  };
  const [activeStep, setActiveStep] = useState(0); // Set the current step to 4th (0-indexed)

  const theme = useTheme();
  const [formValues, setFormValues] = useState({
    personalInfo: {
      firstName: "",
      lastName: "",
      gender: "",
      personalEmail: "",
      phoneNumber: "",
      country: "",
    },
    addictionInfo: {
      primaryAddiction: "",
      durationOfStay: "",
      eta: null,
      nickname: "",
      clientSource: "",
    },
    additionalInfo: {
      projectedResidentialDischargeDate: null,
      projectedProgramCompletionDate: null,
      notes: "",
    },
  });

  const [formErrors, setFormErrors] = useState({
    personalInfo: {
      firstName: false,
      lastName: false,
      gender: false,
      personalEmail: false,
      phoneNumber: false,
      country: false,
    },
    addictionInfo: {
      primaryAddiction: false,
      durationOfStay: false,
      eta: false,
      nickname: false,
      clientSource: false,
    },
    additionalInfo: {
      projectedResidentialDischargeDate: false,
      projectedProgramCompletionDate: false,
      notes: false,
    },
  });

  const [run, setRun] = useState(false); // Pour démarrer ou arrêter Joyride
  const [, setShowHelpButton] = useState(true);

  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "Help" après le clic
  };

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  const customStylesJoyride = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50,
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    beaconInner: {
      backgroundColor: "#1565c0",
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)",
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const stepsJoyride = [
    {
      target: "#search-bar-class2", // Assurez-vous d'ajouter cette classe à votre SearchBar
      content: translate("SearchBar"),
    },

    {
      target: "#search-bar-class", // Assurez-vous d'ajouter cette classe à votre SearchBar
      content: translate("SearchBar"),
    },
    {
      target: "#ai-text", // Assurez-vous que la classe ai-text existe déjà sur l'élément AI
      content: translate("AIServicePrompt"),
    },
    {
      target: "#profile-widget", // Assurez-vous d'ajouter cette classe à ProfileWidgetV2
      content: translate("ProfileWidget"),
    },
  ];

  const steps = [
    translate("PersonalInfos"),
    translate("AddictionInfos"),
    translate("DischargeInfos"),
  ];

  const handleNext = async () => {
    if (validateForm()) {
      if (activeStep === steps.length - 1) {
        await saveClient();
        handleCloseAddClientModal();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      console.log("Form is not valid:", formErrors); // Add this line
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChange = (step, field) => (event) => {
    const value = event && event.target ? event.target.value : event; // handle both event.target.value and direct value from PhoneInput
    setFormValues({
      ...formValues,
      [step]: { ...formValues[step], [field]: value },
    });
  };

  const validateForm = () => {
    const newFormErrors = { ...formErrors };
    let isValid = true;

    switch (activeStep) {
      case 0:
        newFormErrors.personalInfo.firstName =
          formValues.personalInfo.firstName === "";
        newFormErrors.personalInfo.lastName =
          formValues.personalInfo.lastName === "";
        newFormErrors.personalInfo.gender =
          formValues.personalInfo.gender === "";
        newFormErrors.personalInfo.personalEmail =
          formValues.personalInfo.personalEmail === "";
        newFormErrors.personalInfo.phoneNumber =
          formValues.personalInfo.phoneNumber === "";
        newFormErrors.personalInfo.country =
          formValues.personalInfo.country === "";
        isValid =
          !newFormErrors.personalInfo.firstName &&
          !newFormErrors.personalInfo.lastName &&
          !newFormErrors.personalInfo.gender &&
          !newFormErrors.personalInfo.personalEmail &&
          !newFormErrors.personalInfo.phoneNumber &&
          !newFormErrors.personalInfo.country;
        break;
      case 1:
        newFormErrors.addictionInfo.primaryAddiction =
          formValues.addictionInfo.primaryAddiction === "";
        newFormErrors.addictionInfo.durationOfStay =
          formValues.addictionInfo.durationOfStay === "";
        newFormErrors.addictionInfo.eta = formValues.addictionInfo.eta === null;
        newFormErrors.addictionInfo.clientSource =
          formValues.addictionInfo.clientSource === "";
        isValid =
          !newFormErrors.addictionInfo.primaryAddiction &&
          !newFormErrors.addictionInfo.durationOfStay &&
          !newFormErrors.addictionInfo.eta &&
          !newFormErrors.addictionInfo.clientSource;
        break;
      case 2:
        newFormErrors.additionalInfo.projectedResidentialDischargeDate =
          formValues.additionalInfo.projectedResidentialDischargeDate === null;
        newFormErrors.additionalInfo.projectedProgramCompletionDate =
          formValues.additionalInfo.projectedProgramCompletionDate === null;
        isValid =
          !newFormErrors.additionalInfo.projectedResidentialDischargeDate &&
          !newFormErrors.additionalInfo.projectedProgramCompletionDate;
        break;
      default:
        break;
    }

    setFormErrors(newFormErrors);

    if (!isValid) {
      showCustomToast("Please fill all required fields", "error");
    }

    return isValid;
  };

  const isStepCompleted = (step) => {
    switch (step) {
      case 0:
        return (
          formValues.personalInfo.firstName !== "" &&
          formValues.personalInfo.lastName !== "" &&
          formValues.personalInfo.gender !== "" &&
          formValues.personalInfo.personalEmail !== "" &&
          formValues.personalInfo.phoneNumber !== "" &&
          formValues.personalInfo.country !== ""
        );
      case 1:
        return (
          formValues.addictionInfo.primaryAddiction !== "" &&
          formValues.addictionInfo.durationOfStay !== "" &&
          formValues.addictionInfo.eta !== null &&
          formValues.addictionInfo.clientSource !== ""
        );
      case 2:
        return (
          formValues.additionalInfo.projectedResidentialDischargeDate !==
            null &&
          formValues.additionalInfo.projectedProgramCompletionDate !== null
        );
      default:
        return false;
    }
  };

  const resetForm = () => {
    setFormValues({
      personalInfo: {
        firstName: "",
        lastName: "",
        gender: "",
        personalEmail: "",
        phoneNumber: "",
        country: "",
      },
      addictionInfo: {
        primaryAddiction: "",
        durationOfStay: "",
        eta: null,
        nickname: "",
        clientSource: "",
      },
      additionalInfo: {
        projectedResidentialDischargeDate: null,
        projectedProgramCompletionDate: null,
        notes: "",
      },
    });
    setFormErrors({
      personalInfo: {
        firstName: false,
        lastName: false,
        gender: false,
        personalEmail: false,
        phoneNumber: false,
        country: false,
      },
      addictionInfo: {
        primaryAddiction: false,
        durationOfStay: false,
        eta: false,
        nickname: false,
        clientSource: false,
      },
      additionalInfo: {
        projectedResidentialDischargeDate: false,
        projectedProgramCompletionDate: false,
        notes: false,
      },
    });
    setActiveStep(0);
  };

  const saveClient = async () => {
    showCustomToast(translate("DemoTestNoClient"), "info");
  };

  const [blinkCount, setBlinkCount] = useState(0);
  const [shouldBlink, setShouldBlink] = useState(true);

  useEffect(() => {
    if (blinkCount < 3) {
      const interval = setInterval(() => {
        setBlinkCount((prevCount) => prevCount + 1);
      }, 10000);

      return () => clearInterval(interval);
    } else {
      setShouldBlink(false);
    }
  }, [blinkCount]);

  const customStyles = {
    formControl: {
      marginTop: "16px",
      marginBottom: "8px",
      "& .MuiInputLabel-root": {
        transform: "translate(14px, 12px) scale(1)",
        transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      },
      "& .MuiInputLabel-shrink": {
        transform: "translate(14px, -6px) scale(0.75)",
      },
      "& .MuiInputBase-root": {
        "&.Mui-focused .MuiInputLabel-root": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
    },
  };

  const handleSettings = () => {
    navigate("/account");
  };

  const handleAi = () => {
    navigate("/ai");
  };
  return (
    <div className="main-top-title-banner-container-bis">
      <Joyride
        steps={stepsJoyride}
        continuous
        scrollToFirstStep
        showProgress={false} // Empêche d'afficher le numéro d'aide en cours
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStylesJoyride}
      />

      <div
        className="title-text-banner-bis"
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "10px",
        }}
      >
        <Modal
          open={openAddClientModal}
          onClose={handleCloseAddClientModal}
          aria-labelledby="add-client-modal-title"
          aria-describedby="add-client-modal-description"
          closeAfterTransition
        >
          <Slide direction="left" in={openAddClientModal}>
            <Box
              sx={{
                position: "fixed",
                top: "5%",
                right: 0,
                width: "30%",
                height: "90%",
                bgcolor: "background.paper",
                boxShadow: 24,
                marginRight: "15px",
                pl: "2",
                pr: "2",
                borderRadius: "15px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 4,
                  borderBottom: "1px solid lightgray",
                }}
              >
                <div
                  id="add-client-modal-title"
                  style={{
                    margin: 0,
                    paddingLeft: "30px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    fontSize: "22px",
                    fontWeight: "700",
                  }}
                >
                  {translate("AddNewClient")}
                </div>
                <div style={{ paddingRight: "15px" }}>
                  <IconButton onClick={handleCloseAddClientModal}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </Box>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label} completed={isStepCompleted(index)}>
                    <StepLabel
                      StepIconProps={{
                        style: {
                          color: isStepCompleted(index)
                            ? theme.palette.success.main
                            : "#d3d3d3",
                          // theme.palette.primary.main,
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>

              <Box
                id="add-client-modal-description"
                sx={{
                  flexGrow: 1,
                  overflowY: "auto",
                  mt: 2,
                  pl: "30px",
                  pr: "30px",
                }}
              >
                {activeStep === 0 && (
                  <div
                    style={{
                      height: "60%",
                      marginTop: "40px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "45%" }}>
                        <TextField
                          fullWidth
                          label={translate("FirstName")}
                          variant="outlined"
                          margin="normal"
                          value={formValues.personalInfo.firstName}
                          onChange={handleChange("personalInfo", "firstName")}
                          error={formErrors.personalInfo.firstName}
                          helperText={
                            formErrors.personalInfo.firstName
                              ? translate("FirstNameRequired")
                              : ""
                          }
                        />
                      </div>

                      <div style={{ width: "45%" }}>
                        <TextField
                          fullWidth
                          label={translate("LastName")}
                          variant="outlined"
                          margin="normal"
                          value={formValues.personalInfo.lastName}
                          onChange={handleChange("personalInfo", "lastName")}
                          error={formErrors.personalInfo.lastName}
                          helperText={
                            formErrors.personalInfo.lastName
                              ? translate("LastNameRequired")
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "45%" }}>
                        <TextField
                          fullWidth
                          label={translate("Nickname")}
                          variant="outlined"
                          margin="normal"
                          value={formValues.addictionInfo.nickname}
                          onChange={handleChange("addictionInfo", "nickname")}
                          error={formErrors.addictionInfo.nickname}
                          helperText={
                            formErrors.addictionInfo.nickname
                              ? translate("NicknameRequired ")
                              : ""
                          }
                        />
                      </div>

                      <div style={{ width: "45%" }}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Gender</InputLabel>
                          <Select
                            value={formValues.personalInfo.gender}
                            onChange={handleChange("personalInfo", "gender")}
                            error={formErrors.personalInfo.gender}
                          >
                            {gender.map((option) => (
                              <MenuItem
                                key={option.id}
                                value={option.textDropDownEn}
                              >
                                {option.textDropDownEn}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "45%" }}>
                        <TextField
                          fullWidth
                          label={translate("Email")}
                          variant="outlined"
                          margin="normal"
                          value={formValues.personalInfo.personalEmail}
                          onChange={handleChange(
                            "personalInfo",
                            "personalEmail"
                          )}
                          error={formErrors.personalInfo.personalEmail}
                          helperText={
                            formErrors.personalInfo.personalEmail
                              ? translate("EmailRequired")
                              : ""
                          }
                        />
                      </div>

                      <div style={{ width: "57%", paddingTop: "0px" }}>
                        <PhoneNumberInputNoTitleV2
                          value={formValues.personalInfo.phoneNumber}
                          onChange={handleChange("personalInfo", "phoneNumber")}
                          placeholder={translate("PhoneNumber")}
                          error={formErrors.personalInfo.phoneNumber}
                        />
                      </div>
                    </div>

                    <FormControl
                      fullWidth
                      margin="normal"
                      style={customStyles.formControl}
                    >
                      <InputLabel>{translate("Country")}</InputLabel>
                      <Select
                        value={formValues.personalInfo.country}
                        onChange={handleChange("personalInfo", "country")}
                        error={formErrors.personalInfo.country}
                      >
                        {countries.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.textDropDownEn}
                          >
                            {option.textDropDownEn}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
                {activeStep === 1 && (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "45%" }}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>
                            {translate("PrimaryAddiction")}
                          </InputLabel>
                          <Select
                            value={formValues.addictionInfo.primaryAddiction}
                            onChange={handleChange(
                              "addictionInfo",
                              "primaryAddiction"
                            )}
                            error={formErrors.addictionInfo.primaryAddiction}
                          >
                            {addictions.map((option) => (
                              <MenuItem
                                key={option.id}
                                value={option.textDropDownEn}
                              >
                                {option.textDropDownEn}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div style={{ width: "45%" }}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>{translate("DurationOfStay")}</InputLabel>
                          <Select
                            value={formValues.addictionInfo.durationOfStay}
                            onChange={handleChange(
                              "addictionInfo",
                              "durationOfStay"
                            )}
                            error={formErrors.addictionInfo.durationOfStay}
                          >
                            {durationOfStay.map((option) => (
                              <MenuItem
                                key={option.id}
                                value={option.textDropDownEn}
                              >
                                {option.textDropDownEn}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "45%" }}>
                        <TextField
                          fullWidth
                          label={translate("ETA")}
                          type="date"
                          variant="outlined"
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={formValues.addictionInfo.eta}
                          onChange={handleChange("addictionInfo", "eta")}
                          error={formErrors.addictionInfo.eta}
                          helperText={
                            formErrors.addictionInfo.eta
                              ? translate("ETARequired")
                              : ""
                          }
                        />
                      </div>

                      <div style={{ width: "45%" }}>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>{translate("ClientSource")}</InputLabel>
                          <Select
                            value={formValues.addictionInfo.clientSource}
                            onChange={handleChange(
                              "addictionInfo",
                              "clientSource"
                            )}
                            error={formErrors.addictionInfo.clientSource}
                          >
                            {clientSource.map((option) => (
                              <MenuItem
                                key={option.id}
                                value={option.textDropDownEn}
                              >
                                {option.textDropDownEn}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </>
                )}
                {activeStep === 2 && (
                  <>
                    <TextField
                      fullWidth
                      label={translate("ProjectedResidentialDischargeDate")}
                      type="date"
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        formValues.additionalInfo
                          .projectedResidentialDischargeDate
                      }
                      onChange={handleChange(
                        "additionalInfo",
                        "projectedResidentialDischargeDate"
                      )}
                      error={
                        formErrors.additionalInfo
                          .projectedResidentialDischargeDate
                      }
                      helperText={
                        formErrors.additionalInfo
                          .projectedResidentialDischargeDate
                          ? translate(
                              "ProjectedResidentialDischargeDateRequired"
                            )
                          : ""
                      }
                    />
                    <TextField
                      fullWidth
                      label={translate("ProjectedProgramCompletionDate")}
                      type="date"
                      variant="outlined"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={
                        formValues.additionalInfo.projectedProgramCompletionDate
                      }
                      onChange={handleChange(
                        "additionalInfo",
                        "projectedProgramCompletionDate"
                      )}
                      error={
                        formErrors.additionalInfo.projectedProgramCompletionDate
                      }
                      helperText={
                        formErrors.additionalInfo.projectedProgramCompletionDate
                          ? translate("ProjectedProgramCompletionDateRequired")
                          : ""
                      }
                    />
                    {/* <TextField
                      fullWidth
                      label="Additional Notes"
                      variant="outlined"
                      margin="normal"
                      value={formValues.additionalInfo.notes}
                      onChange={handleChange("additionalInfo", "notes")}
                      error={formErrors.additionalInfo.notes}
                      helperText={
                        formErrors.additionalInfo.notes
                          ? "Notes are required"
                          : ""
                      }
                    /> */}
                  </>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 2,
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  paddingBottom: "20px",
                  paddingTop: "20px",
                  borderTop: "1px solid lightgray",
                }}
              >
                <Button
                  onClick={handleBack}
                  variant="outlined"
                  color="primary"
                  disabled={activeStep === 0}
                >
                  {translate("Back")}
                </Button>
                <Button
                  onClick={handleNext}
                  variant="contained"
                  color="primary"
                >
                  {activeStep === steps.length - 1
                    ? translate("AddTheClient")
                    : translate("Next")}
                </Button>
              </Box>
            </Box>
          </Slide>
        </Modal>

        <div
          style={{
            width: "30%",
            // backgroundColor: "yellow",
            paddingTop: "5px",
            paddingBottom: "5px",
            fontSize: "26px",
            fontWeight: "600",
          }}
        >
          {translate(props.title)}
        </div>
        <div
          style={{
            width: "40%",
            // backgroundColor: "lightblue",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            alignSelf: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          <div
            id="search-bar-class"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <SearchBar />
          </div>
          <div
            style={{
              marginLeft: "0px",
              width: "100%",
            }}
          >
            <Tooltip title={translate("AddClient")} placement="bottom">
              <div style={{ width: "60px" }} onClick={handleOpenAddClientModal}>
                <AddButtonRoundSmall />
              </div>
            </Tooltip>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "350px",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            className={shouldBlink ? "blink" : ""}
            id="ai-text"
          >
            <div
              style={{
                color: "#0569bd",
                fontWeight: "700",
                fontSize: "16px",
                paddingRight: "10px",
                width: "150px",
                textAlign: "end",
                display: "none", // Hidden by default
              }}
              className="ai-text" // Add a class for styling
              onClick={handleAi}
            >
              {translate("TryOurAI")}
            </div>
            <Tooltip title={translate("TryOurAI")} arrow>
              <img
                src={logoAi}
                width={30}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={handleAi} // Move onClick here
              />
            </Tooltip>
          </div>
        </div>
        <div
          style={{
            width: "33%",
            paddingTop: "5px",
            paddingBottom: "5px",
            display: "flex",
            justifyContent: "end",
            alignContent: "center",
            alignItems: "center",
            alignSelf: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <Tooltip title={translate("ClickHereForHelp")} placement="bottom">
              <HelpOutlineIcon
                style={{
                  cursor: "pointer",
                  marginRight: "10px",
                  color: "#a9a9a9",
                }}
                onClick={handleHelpClick}
              />
            </Tooltip>
            <Tooltip title={translate("Settings")} placement="bottom">
              <SettingsIcon
                onClick={handleSettings}
                style={{
                  cursor: "pointer",
                  marginRight: "5px",
                  color: "#a9a9a9",
                }}
              />
            </Tooltip>

            <div
              style={{
                borderLeft: "2px solid #a9a9a9", // Remplacez #000 par la couleur souhaitée
                height: "24px",
                marginRight: "0px",
                marginLeft: "20px",
              }}
            ></div>
          </div>
          <div id="profile-widget">
            <ProfileWidgetV2 />
          </div>
        </div>
      </div>
    </div>
  );
}
