import React, { useContext, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";
import { LanguageContext } from "../../context/LanguageContext";
import TopTitleFormComponentMobileV2 from "./TopTitleFormComponentMobileV2";
import TextFieldComponentV2Mobile from "../TextFieldComponentV2Mobile";

function AddObjectiveFormMobileV2(props) {
  const { translate } = useContext(LanguageContext);
  // eslint-disable-next-line no-unused-vars
  const [isLoading] = useState(false);
  const [objective, setObjective] = useState("");
  const [intervention, setIntervention] = useState("");

  const handleObjectiveChange = (event) => {
    setObjective(event);
  };

  const handleInterventionChange = (event) => {
    setIntervention(event);
  };

  return (
    <>
      <div
        // className="inputAndLabelModalContainerTitle2"
        style={{ width: "auto", height: "100%" }}
      >
        <div
          className="line1-modal-percent"
          style={{ borderBottom: "1px solid lightgrey" }}
        >
          <TopTitleFormComponentMobileV2
            title={translate("ObjectiveIntervention")}
            closemodal={props.closemodal}
          />
        </div>

        {/* <br /> */}

        <div
          style={{
            width: "100%",
            marginTop: "4px",
            height: "80%",
            overflowY: "scroll",
            // overflowX: "hidden",
            paddingLeft: "35px",
            paddingRight: "25px",
          }}
        >
          <div className="tab-form-container">
            <TextFieldComponentV2Mobile
              title={translate("Objective")}
              width="100%"
              value={objective}
              onChange={handleObjectiveChange}
            />
            <div style={{ paddingTop: "20px", width: "100%" }}>
              <TextFieldComponentV2Mobile
                title={translate("Intervention")}
                width="100%"
                value={intervention}
                onChange={handleInterventionChange}
              />
            </div>
          </div>
        </div>
        <div className="line4-modal-percent-bis">
          <>
            <Button
              variant="contained"
              style={{
                backgroundColor: "white",
                color: "black",
                fontSize: "12px",
              }}
              // disabled={!(pic1Url && pic2Url && pic3Url && pic4Url && pic5Url)}
              onClick={props.closemodal}
            >
              {translate("Cancel")}
            </Button>
            <Button
              variant="contained"
              style={
                isLoading
                  ? {
                      backgroundColor: "red",
                      color: "white",
                      marginLeft: "15px",
                      fontSize: "12px",
                    }
                  : {
                      backgroundColor:
                        objective && intervention ? "#1565C0" : "grey", // Grise le bouton si les champs sont vides
                      color: "white",
                      marginLeft: "15px",
                      fontSize: "12px",
                    }
              }
              disabled={isLoading || !objective || !intervention} // Désactive le bouton si les champs sont vides
              onClick={() => {
                props.addObjective(objective, intervention);
                props.closemodal();
              }}
            >
              {isLoading ? translate("Adding") : translate("AddObjective")}
            </Button>
          </>
        </div>
      </div>
    </>
  );
}

export default AddObjectiveFormMobileV2;
