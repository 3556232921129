import React, { useContext, useEffect, useState } from "react";
import "../css/navbar.css";
import menuitems from "../api/menuitems";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import { documentId, onSnapshot, query, where } from "firebase/firestore";
import { roles } from "../firebase.collection";
import Joyride, { ACTIONS, STATUS } from "react-joyride";
import logoRehapp from "../images/rehap-logo.png";
import { Skeleton, Tooltip } from "@mui/material";
import { MenuItem, Select, FormControl, InputLabel, Box } from "@mui/material";
import ThailandFlag from "../images/flags/thai-flag.png";
import USFlag from "../images/flags/uk-flag-icon.png";
import FranceFlag from "../images/flags/french-flag-icon.png";
import { LanguageContext } from "../context/LanguageContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function NavbarV2(props) {
  const { translate, language, setLanguage } = useContext(LanguageContext);
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const { currentUser } = useAuth();
  const [activeLink, setActiveLink] = useState(props.linkActive);

  const [userRole, setUserRole] = useState("");
  const [userIdState] = useState(currentUser ? currentUser.uid : "");

  const [loading, setLoading] = useState(true);

  const [run, setRun] = useState(false);
  const [, setShowHelpButton] = useState(true);

  const handleHelpClick = () => {
    setRun(true);
    setShowHelpButton(false); // Cache le bouton "HELP" après le clic
  };

  const customStyles = {
    options: {
      zIndex: 10000,
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "#000",
      animation: "fadeIn 0.3s ease-in-out",
    },
    tooltipContent: {
      textAlign: "justify",
      marginTop: "10px",
    },
    beacon: {
      offsetY: -50,
    },
    buttonNext: {
      backgroundColor: "#1565c0",
      color: "#fff",
    },
    buttonBack: {
      color: "blue",
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 1000,
    },
    beaconInner: {
      backgroundColor: "#1565c0",
      borderColor: "#1565c0",
    },
    beaconOuter: {
      backgroundColor: "rgba(21, 101, 192, 0.5)",
      borderColor: "rgba(21, 101, 192, 0.5)",
    },
  };

  const steps = [
    {
      target: "#metrics-inhouse2", // Assurez-vous d'ajouter cette classe au widget In-House
      content:
        "This widget displays the total number of clients currently residing in the facility. Click on it to view the detailed list of all in-house clients.",
    },
    {
      target: "#menu-dashboard",
      content: translate("DashboardHelp"),
    },
    {
      target: "#menu-clients",
      content: translate("ClientsHelp"),
    },
    {
      target: "#menu-notes",
      content: translate("NotesHelp"),
    },
    {
      target: "#menu-roomavailability",
      content: translate("RoomAvailabilityHelp"),
    },
    {
      target: "#menu-roombooking",
      content: translate("RoomBookingHelp"),
    },
    {
      target: "#menu-crm",
      content: translate("CRMHelp"),
    },
    {
      target: "#menu-metrics",
      content: translate("MetricsHelp"),
    },
    {
      target: "#menu-exportdata",
      content: translate("ExportDataHelp"),
    },
    {
      target: "#menu-documents",
      content: translate("DocumentsHelp"),
    },
    {
      target: "#menu-support",
      content: translate("SupportHelp"),
    },
    // Ajoutez d'autres étapes si nécessaire...
  ];

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
      setShowHelpButton(true);
    } else if ([ACTIONS.CLOSE].includes(action)) {
      setRun(false);
      setShowHelpButton(true);
    }
  };

  function handleLinkClick(linkId) {
    setActiveLink(linkId === activeLink ? null : linkId);
  }

  useEffect(() => {
    if (currentUser) {
      queryRole();
    } else {
      console.log("No user id");
    }
  }, [currentUser]);

  function queryRole() {
    onSnapshot(
      query(roles, where(documentId(), "==", userIdState)),
      (querySnapshot) => {
        const doc = querySnapshot.docs[0];
        if (doc && doc.exists) {
          const role = doc.data().role;
          const phone = doc.data().phoneNumber;

          setUserRole(role);
          setLoading(false);
        } else {
          console.log("Problem loading roles");
        }
      }
    );
  }

  const renderMenuItems = (items) => {
    return items
      .filter((item) => item.roles.includes(userRole))
      .map((item, index) => (
        // <div className="sidebar-title" key={index}>
        <div
          className={`sidebar-title-v2 ${
            item.id === activeLink ? "container-active" : ""
          }`}
          key={index}
          id={`menu-${item.name.replace(/\s+/g, "-").toLowerCase()}`}
        >
          <div className="icon-menu">{item.icon}</div>
          <Link
            className={`link-navbar-v2 ${
              item.id === activeLink ? "active" : ""
            }`}
            to={item.link}
            onClick={() => handleLinkClick(item.id)}
          >
            <div
              className={`menu-title ${item.id === activeLink ? "active" : ""}`}
            >
              {translate(item.name)}
            </div>
          </Link>
        </div>
      ));
  };

  return (
    <div className="navbar-container-v2 navbar-scrollable">
      <Joyride
        steps={steps}
        continuous
        scrollToFirstStep
        showProgress
        showSkipButton
        run={run}
        callback={handleJoyrideCallback}
        disableScrolling={true}
        disableBeacon={true}
        styles={customStyles}
      />

      <div className="navbar-top-group">
        <div
          // className="profile-widget-component"
          style={{
            display: "flex",
            paddingLeft: "30px",
            paddingTop: "15px",
            paddingBottom: "15px",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <img
            src={logoRehapp}
            alt=""
            style={{ width: "110px", height: "28px" }}
          />
          <div
            style={{
              width: "auto",
              paddingLeft: "0px",
              paddingTop: "20px",
              height: "10%",
              display: "flex",
              alignItems: "flex-end",
              paddingRight: "20px",
            }}
          >
            <FormControl
              variant="outlined"
              size="small"
              style={{
                minWidth: 50,
                border: "none",
                boxShadow: "none", // Remove any shadow
              }}
            >
              <Select
                labelId="language-select-label"
                id="language-select"
                value={language}
                onChange={handleLanguageChange}
                displayEmpty
                style={{
                  fontSize: 10,
                  height: 30,
                  padding: 0,
                  minWidth: 50,
                  border: "none", // Remove the border
                  boxShadow: "none", // Remove shadow
                  paddingRight: 0, // Remove padding for the triangle
                  backgroundColor: "transparent", // Ensure no background color
                }}
                renderValue={(selected) => (
                  <Box display="flex" alignItems="center">
                    {selected === "en" && (
                      <img
                        src={USFlag}
                        alt="US Flag"
                        style={{ width: 20, marginRight: 0 }} // No margin when closed
                      />
                    )}
                    {selected === "fr" && (
                      <img
                        src={FranceFlag}
                        alt="France Flag"
                        style={{ width: 20, marginRight: 0 }}
                      />
                    )}
                    {selected === "th" && (
                      <img
                        src={ThailandFlag}
                        alt="Thailand Flag"
                        style={{ width: 20, marginRight: 0 }}
                      />
                    )}
                  </Box>
                )}
                IconComponent={() => null} // Remove the triangle
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: 5, // Optional: Adjust the position of the dropdown menu
                    },
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove the border completely
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // No border when focused
                  },
                  "& .MuiSelect-select": {
                    paddingRight: 0, // Ensure no padding on the right
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                <MenuItem value="en">
                  <Box display="flex" alignItems="center">
                    <img
                      src={USFlag}
                      alt="US Flag"
                      style={{ width: 20, marginRight: 10 }}
                    />
                    <span style={{ fontSize: 10 }}>English</span>
                  </Box>
                </MenuItem>
                <MenuItem value="fr">
                  <Box display="flex" alignItems="center">
                    <img
                      src={FranceFlag}
                      alt="France Flag"
                      style={{ width: 20, marginRight: 10 }}
                    />
                    <span style={{ fontSize: 10 }}>Français</span>
                  </Box>
                </MenuItem>
                <MenuItem value="th">
                  <Box display="flex" alignItems="center">
                    <img
                      src={ThailandFlag}
                      alt="Thailand Flag"
                      style={{ width: 20, marginRight: 10 }}
                    />
                    <span style={{ fontSize: 10 }}>ภาษาไทย</span>
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
            <div style={{ paddingRight: "10px" }}>
              <Tooltip title={translate("ClickHereForHelp")} placement="bottom">
                <HelpOutlineIcon
                  style={{
                    cursor: "pointer",
                    marginRight: "10px",
                    color: "#a9a9a9",
                  }}
                  onClick={handleHelpClick}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="menu-items-container">
          {loading ? (
            <div className="skeleton-container">
              <Skeleton variant="rectangular" width={200} height={40} />
              <Skeleton
                variant="rectangular"
                width={180}
                height={20}
                style={{ marginTop: 10 }}
              />
              <Skeleton
                variant="rectangular"
                width={150}
                height={30}
                style={{ marginTop: 10 }}
              />
              <Skeleton
                variant="rectangular"
                width={150}
                height={30}
                style={{ marginTop: 10 }}
              />
              <Skeleton
                variant="rectangular"
                width={150}
                height={30}
                style={{ marginTop: 10 }}
              />
              <Skeleton
                variant="rectangular"
                width={150}
                height={30}
                style={{ marginTop: 10 }}
              />
              <Skeleton
                variant="rectangular"
                width={150}
                height={30}
                style={{ marginTop: 10 }}
              />
              <Skeleton
                variant="rectangular"
                width={150}
                height={30}
                style={{ marginTop: 10 }}
              />
              <Skeleton
                variant="rectangular"
                width={150}
                height={30}
                style={{ marginTop: 10 }}
              />
              <Skeleton
                variant="rectangular"
                width={150}
                height={30}
                style={{ marginTop: 10 }}
              />
              <Skeleton
                variant="rectangular"
                width={150}
                height={30}
                style={{ marginTop: 10 }}
              />
              <Skeleton
                variant="rectangular"
                width={150}
                height={30}
                style={{ marginTop: 10 }}
              />
            </div>
          ) : (
            <>
              {renderMenuItems(
                menuitems.filter(
                  (item) =>
                    ![
                      "Clients",
                      "Documents",
                      "Notes",
                      "MyProfile",
                      "Roles",
                      "Staff contacts",
                      "ExportData",
                      "Metrics",
                      "RoomBooking",
                      "RoomAvailability",
                      "Support",
                      "CRM",
                      "Medication",
                      "Invoicing",
                      "Layout",
                    ].includes(item.name)
                )
              )}

              <div
                style={{
                  color: "#a7a7ab",
                  paddingBottom: "0px",
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >
                {translate("ClientC")}
              </div>
              {renderMenuItems(
                menuitems.filter(
                  (item) =>
                    ![
                      "Dashboard",
                      "MyProfile",
                      "Roles",
                      "Staff contacts",
                      "Metrics",
                      "ExportData",
                      "Documents",
                      "Support",
                      "Invoicing",
                      "Medication",
                    ].includes(item.name)
                )
              )}

              <div
                style={{
                  color: "#a7a7ab",
                  paddingBottom: "0px",
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >
                {translate("Admin")}
              </div>

              {renderMenuItems(
                menuitems.filter((item) =>
                  [
                    "Roles",
                    "Staff contacts",
                    "Metrics",
                    "ExportData",
                    "Documents",
                    // "Invoicing",
                  ].includes(item.name)
                )
              )}
            </>
          )}
        </div>
        <div className="menu-separator"></div>
        <div style={{ paddingLeft: "30px" }}>
          {renderMenuItems(
            menuitems.filter((item) =>
              [
                "Support",
                "MyProfile",

                // "Export Data",
                // "Support Dashboard",
              ].includes(item.name)
            )
          )}
        </div>
      </div>
      {/* <div
        style={{ height: "10%", display: "flex", justifyContent: "center" }}
      ></div> */}
    </div>
  );
}
